import { ApplicationRef, Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { UserService } from '../../service/user.service';
import { BehaviorSubject } from 'rxjs';
import { ChangePasswordComponent } from '../../osadmin/user/change-password/change-password.component';
import { ModalService } from '../../service/modal.service';
import { Router } from '@angular/router';
import { User } from '../../domain/user';
import { MessageFeedComponent } from '../../message-feed/message-feed.component';
import { Theme } from '../../domain/theme';

@Component({
    selector: 'app-mini-me',
    templateUrl: './mini-me.component.html',
    styleUrls: ['./mini-me.component.scss'],
})
export class MiniMeComponent implements OnInit {

    public me: BehaviorSubject<any>;
    modal;

    @ViewChild("messageFeed", { static: false })
    messageFeed: MessageFeedComponent;


    constructor(
        private readonly userService: UserService,
        private readonly modalService: ModalService,
        private readonly router: Router,
        private cdr: ChangeDetectorRef
    ) {
    }

    ngOnInit() {
        this.me = this.userService.userObservable;
    }

    getMessageCount() {
        if (this.messageFeed)
            return this.messageFeed.getMessageCount();
        return 0;
    }

    hasMessages() {
        return this.getMessageCount() > 0;
    }

    changePassword() {
        if (this.modal) {
            this.modal.instance.show();
        } else {
            this.modal = this.modalService.openModal(ChangePasswordComponent);
        }
    }


    get userOffice() {
        if (this.me.value !== null)
            if (this.me.value.functionInOffices.length > 0)
                return this.me.value.functionInOffices[0].office.title;
        return "";
    }

    get userFunction() {
        if (this.me.value !== null)
            if (this.me.value.functionInOffices.length > 0 && this.me.value.functionInOffices[0].skill)
                return this.me.value.functionInOffices[0].skill.translationKey;
        return "functionInOffice.working";

    }
}
