import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import { ToastrService } from 'ngx-toastr';
import { environment } from '../../../../environments/environment';
import { GroupService } from '../../../service/group.service';
import { UserService } from '../../../service/user.service';
import { Utils } from '../../../shared/utils';
import { TopicFileListComponent } from '../topic-file-list/topic-file-list.component';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
    selector: 'app-topic-full',
    templateUrl: './topic-full.component.html',
    styleUrls: ['./topic-full.component.scss']
})
export class TopicFullComponent implements OnInit {

    topic;
    comments;
    group;
    user;
    @ViewChild(TopicFileListComponent, { static: false })
    fileList: TopicFileListComponent;
    page: number = 1;
    favorites: any[];

    usersToShow: any[];
    showUsers: string = "all";

    private state$: Observable<object>;
    @ViewChild('scroller', { static: false }) private myScrollContainer: ElementRef;
    isShowComment = false;

    constructor(private groupService: GroupService,
        private route: ActivatedRoute,
        private userService: UserService,
        private router: Router) {
    }

    ngOnInit() {
        this.route.params.subscribe(p => {
            if ('id' in p) {
                this.groupService.loadTopic(p['id']).subscribe(topic => {
                    this.topic = topic;
                    if (this.isShowComment) {
                        setTimeout(() => this.myScrollContainer.nativeElement.scrollTop = this.myScrollContainer.nativeElement.scrollHeight, 500);
                    }

                    this.groupService.loadGroup((<any>topic).groupId).subscribe(g => {
                        this.group = g;
                        this._reloadShowUsers();
                    });

                    this.userService.updateReadStatus(p['id']).subscribe(s => {
                    });
                }, e => {
                    this.router.navigate(["/"]);
                });
            }
        });
        this.userService.userObservable.subscribe(u => this.user = u);

        this.userService.onSocketEvent.subscribe(data => {
            if (data.messageType == 'comment' && data.topicId == this.topic.id) {
                this.onComment(data);
            }
        });

        this.favorites = this.userService.favs;
        this.userService.favoritesObservable.subscribe(f => {
            this.favorites = f;
        });

        this.state$ = this.route.paramMap
            .pipe(map(() => window.history.state));


        this.state$.subscribe(
            (state: any) => {
                if (state.showComment)
                    this.isShowComment = state.showComment;
            }
        );
    }

    onComment(comment) {
        if (_.filter(this.topic.comments, (e: any) => e.id == comment.id).length == 0) {
            this.topic.comments.push(comment);
        }
    }

    showAllUsers() {
        this.showUsers = "all";
        this._reloadShowUsers();
    }

    getRouterLink() {
        if (this.group)
            return ['/social/' + this.group.groupType.toLowerCase() + '/view/' + this.group.id];        
    }

    get isShowAll() {
        return this.showUsers === "all";
    }

    showFavoritesUsers() {
        this.showUsers = "favs"
        this._reloadShowUsers();
    }

    getStyleAvatar(i) {
        const left = 40 * i;
        return {
            'z-index': 100 - i,
            'left': left + 'px',
            'position': 'absolute'
        }
    }

    get isShowFavorites() {
        return this.showUsers === "favs";
    }

    get favoritesCount() {
        if (this.group)
            return this.group.members.filter(id => this.favorites.find(fav => fav['id'] === id)).length;
        else
            return "";
    }

    private _reloadShowUsers() {
        if (this.isShowAll) {
            this.usersToShow = this.group.members;
        }
        else
            this.usersToShow = this.group.members.filter(id => this.favorites.find(fav => fav['id'] === id));
    }


    orderEntities(entities) {
        return _.reverse(_.sortBy(entities, ['updated']));
    }

    isEven(index) {
        return (index == 0 || index % 2 == 0);
    }

    isMember() {
        return this.group && this.group.joined;
    }

    isAdmin() {
        return _.find(<any>(_.get(this, 'userService.u.roles') || []), r => (r == 'ADMIN' || r == 'MODERATOR'));
    }

    isSelf() {
        return _.get(this.userService, 'u.id') == _.get(this.group, 'creator');
    }

    onFileSelected(fileList: FileList) {
        if (fileList.length > 0 && Utils.isImage(fileList.item(0))) {
            this.groupService.uploadGroupImage(this.group.id, fileList.item(0)).subscribe((g: any) => {
                this.group.picture = g.picture;
            });
        }
    }

    get envApi() {
        return environment.api;
    }

}
