import { ITokenProvider } from './token-provider.interface';
import { InjectionToken, Type } from '@angular/core';
import { IUserLocator } from './user-locator.interface';

export const SERVICE_URL = new InjectionToken('ChatServiceURL');
export const CHAT_SERVICE_CONFIG = new InjectionToken('ChatServiceConfig');

export interface IChatServiceConfiguration {
    provider: Type<ITokenProvider>;
    userLocator: Type<IUserLocator>;
    url: string;
    staticAssetsBase: string;
    messageAttachmentBase: string;
}
