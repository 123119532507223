import { Routes } from '@angular/router';
import { NotFoundComponent } from './errors/not-found/not-found.component';
import { FeedComponent } from './feed/feed.component';
import { ForumFullComponent } from './forum/forum-full/forum-full.component';
import { ForumComponent } from './forum/forum.component';
import { AuthGuard } from './guards/auth.guard';
import { MainLoggedComponent } from './layout/main-logged/main-logged.component';
import { MainUnloggedComponent } from './layout/main-unlogged/main-unlogged.component';
import { LinkGroupsComponent } from './link-groups/link-groups.component';
import { LoginComponent } from './login.component';
import { CalendarFormComponent } from './osadmin/calendar/calendar-form/calendar-form.component';
import { CalendarFullComponent } from './osadmin/calendar/calendar-full/calendar-full.component';
import { CalendarComponent } from './osadmin/calendar/calendar.component';
import { DashboardComponent } from './osadmin/dashboard/dashboard.component';
import { DocumentComponent } from './osadmin/document/document.component';
import { DocumentFullComponent } from './osadmin/document/document-full/document-full.component';
import { FileComponent } from './osadmin/file/file.component';
import { GroupFormComponent } from './osadmin/group/group-form/group-form.component';
import { GroupFullComponent } from './osadmin/group/group-full/group-full.component';
import { GroupComponent } from './osadmin/group/group.component';
import { TopicFullComponent } from './osadmin/group/topic-full/topic-full.component';
import { LanguageFullComponent } from './osadmin/language/language-full/language-full.component';
import { LanguageComponent } from './osadmin/language/language.component';
import { NewsComponent } from './osadmin/news/news.component';
import { DelegationFormComponent } from './osadmin/office/delegation-form/delegation-form.component';
import { OfficeFormComponent } from './osadmin/office/office-form/office-form.component';
import { OfficeFullComponent } from './osadmin/office/office-full/office-full.component';
import { OfficeComponent } from './osadmin/office/office.component';
import { SettingsComponent } from './osadmin/settings/settings.component';
import { SkillComponent } from './osadmin/skill/skill.component';
import { ThemeFullComponent } from './osadmin/theming/theme-full/theme-full.component';
import { ThemingComponent } from './osadmin/theming/theming.component';
import { UserFormComponent } from './osadmin/user/user-form/user-form.component';
import { UserFullComponent } from './osadmin/user/user-full/user-full.component';
import { UserComponent } from './osadmin/user/user.component';
import { ChangeEmailComponent } from './registration/change-email/change-email.component';
import { ForgotPasswordComponent } from './registration/forgot-password/forgot-password.component';
import { InvalidDomainComponent } from './registration/invalid-domain/invalid-domain.component';
import { RegistrationComponent } from './registration/registration.component';
import { SuccessComponent } from './registration/success/success.component';
import { ForbiddenComponent } from './shared/forbidden/forbidden.component';


export const appRoutes: Routes = [
    {
        path: 'registration',
        component: MainUnloggedComponent,
        pathMatch: 'prefix',
        children: [
            { path: 'invalid-domain', component: InvalidDomainComponent},
            { path: 'success', component: SuccessComponent},
            { path: '4', component: RegistrationComponent, canActivate: [AuthGuard]},
            { path: '5', component: RegistrationComponent, canActivate: [AuthGuard]},
            { path: ':step', component: RegistrationComponent},

        ]
    },
    {
        path: 'forgot-password',
        pathMatch: 'prefix',
        component: MainUnloggedComponent,
        children: [
            { path: '', component: ForgotPasswordComponent},
            { path: ':token', component: ForgotPasswordComponent},
        ]
    },
    {
        path: 'login',
        pathMatch: 'prefix',
        component: MainUnloggedComponent,
        children: [
            { path: '', component: LoginComponent}
        ]
    },
    {
        path: 'change-email',
        pathMatch: 'prefix',
        component: MainUnloggedComponent,
        children: [
            { path: ':token', component: ChangeEmailComponent}
        ]
    },
    {
        path: '',
        pathMatch: 'prefix',
        component: MainLoggedComponent,
        canActivate: [AuthGuard],
        children: [
            { path: '', component: FeedComponent },
            { path: 'osadmin', component: DashboardComponent },
            { path: 'osadmin/secure/news', component: NewsComponent },
            { path: 'osadmin/secure/calendar', component: CalendarComponent },
            { path: 'osadmin/secure/calendar/new', component: CalendarFormComponent },
            { path: 'osadmin/secure/calendar/edit/:id', component: CalendarFormComponent },
            { path: 'osadmin/secure/document', component: DocumentComponent },
            { path: 'osadmin/secure/file', component: FileComponent },
            { path: 'osadmin/secure/office', component: OfficeComponent },
            { path: 'osadmin/secure/office/new', component: OfficeFormComponent },
            { path: 'osadmin/secure/office/view/:id', component: OfficeFullComponent },
            { path: 'osadmin/secure/office/edit/:id', component: OfficeFormComponent },
            { path: 'osadmin/secure/office/delegation/:officeId/new', component: DelegationFormComponent },
            { path: 'osadmin/secure/office/delegation/:officeId/edit/:delegationId', component: DelegationFormComponent },
            { path: 'osadmin/secure/user', component: UserComponent },
            { path: 'osadmin/secure/user/new', component: UserFormComponent },
            { path: 'osadmin/secure/user/edit/:id', component: UserFormComponent },
            { path: 'osadmin/secure/group', component: GroupComponent },
            { path: 'osadmin/secure/group/new', component: GroupFormComponent },
            { path: 'osadmin/secure/group/edit/:id', component: GroupFormComponent },
            { path: 'osadmin/secure/settings', component: SettingsComponent },
            { path: 'osadmin/secure/language', component: LanguageComponent },
            { path: 'osadmin/secure/language/view/:id', component: LanguageFullComponent },
            // { path: 'osadmin/secure/theming', component: ThemingComponent },
            // { path: 'osadmin/secure/theming/view/:id', component: ThemeFullComponent },
            { path: 'osadmin/secure/skills', component: SkillComponent },
            { path: 'social/directory', component: UserComponent },
            { path: 'social/event', component: CalendarComponent },
            { path: 'social/event/:id', component: CalendarFullComponent },
            { path: 'social/forum', component: ForumComponent },
            { path: 'social/forum/:id', component: ForumFullComponent },
            { path: 'social/directory/:id', component: UserFullComponent },
            { path: 'social/link-groups', component: LinkGroupsComponent },
            { path: 'social/forbidden', component: ForbiddenComponent },
            { path: 'social/group/new', component: GroupFormComponent },
            { path: 'social/:groupType/view/:id', component: GroupFullComponent },
            { path: 'social/group/edit/:id', component: GroupFormComponent },
            { path: 'social/:groupType', component: GroupComponent },
            { path: 'social/documents/:documentType', component: DocumentComponent },
            { path: 'social/documents/:documentType/:id', component: DocumentFullComponent },
            { path: 'social/topic/:id', component: TopicFullComponent },
            { path: 'social/:groupType/topic/:id', component: TopicFullComponent },
            { path: 'error/not-found', component: NotFoundComponent },
        ]
    },
];
