import { Component, OnInit } from '@angular/core';
import { AbstractModal } from '../../shared/abstract-modal';
import { FormGroup } from '@angular/forms';
import { Link } from '../../domain/link';
import { LinkGroupService } from '../../service/link-group.service';

@Component({
    selector: 'app-link-form',
    templateUrl: './link-form.component.html',
    styleUrls: ['./link-form.component.scss']
})
export class LinkFormComponent extends AbstractModal implements OnInit {

    form: FormGroup = Link.form();

    constructor(private linkGroupService: LinkGroupService) {
        super();
    }

    ngOnInit() {
    }

    save() {
        this.linkGroupService.saveLink(this.form.value)
            .subscribe(link => {
                this.close(link);
            });
    }

}
