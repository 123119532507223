import { Component, OnInit } from '@angular/core';
import { GroupService } from '../service/group.service';
import * as _ from 'lodash';
import { UserService } from '../service/user.service';
import { ForumService } from '../service/forum.service';
import { EventService } from '../service/event.service';

@Component({
    selector: 'app-message-feed',
    templateUrl: './message-feed.component.html',
    styleUrls: ['./message-feed.component.scss']
})
export class MessageFeedComponent implements OnInit {

    topicFeed;
    forumFeed;
    eventFeed;

    constructor(
        private groupService: GroupService,
        private userService: UserService,
        private forumService: ForumService,
        private eventService: EventService) {
    }

    ngOnInit() {

        this.loadFeed();
        this.loadForumFeed();
        this.loadEventFeed();

        this.userService.onReadStatus.subscribe((r: any) => {
            this.loadFeed();
        });

        this.userService.onReadStatusForum.subscribe((r: any) => {
            this.loadForumFeed();
        });


        this.userService.onReadStatusEvent.subscribe((r: any) => {
            this.loadEventFeed();
        });

        this.userService.onSocketEvent.subscribe(e => {
            if (e.messageType == "comment")
                this.loadFeed();
            if (e.messageType == "forumComment")
                this.loadForumFeed();
            if (e.messageType == "eventComment")
                this.loadEventFeed();
        });
    }

    loadFeed() {
        this.groupService.loadTopicFeed().subscribe(feed => {
            this.topicFeed = feed;
        });
    }

    loadForumFeed() {
        this.forumService.loadForumFeed().subscribe(feed => {
            this.forumFeed = feed;
        });
    }

    loadEventFeed() {
        this.eventService.loadEventFeed().subscribe(feed => {
            this.eventFeed = feed;
        })
    }

    getMessageCount() {
        let count = _.reduce(this.topicFeed, (i: any, v: any) => {
            i += v.messageCount;
            return i;
        }, 0);

        count += _.reduce(this.forumFeed, (i: any, v: any) => {
            i += v.messageCount;
            return i;
        }, 0);

        count += _.reduce(this.eventFeed, (i: any, v: any) => {
            i += v.messageCount;
            return i;
        }, 0);

        return count;
    }

}
