import { Injectable } from '@angular/core';
import { Settings } from '../app.settings';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class ThemeService {

    constructor(private http: HttpClient) {}

    loadThemes() {
        return this.http.get(Settings.THEME);
    }

    activate(id:any) {
        return this.http.put(Settings.THEME + "/activate/" +id, {})
    }

    save(value:any) {
        let verb = value.id ? 'put' : 'post';
        let suffix = value.id ? '/'+value.id : '';
        return this.http[verb](Settings.THEME + suffix, value);
    }

    loadTheme(id:any) {
        return this.http.put(Settings.THEME + "/" +id, {});
    }

    saveVariable(themeRef, value) {
        let verb = value.id ? 'put' : 'post';
        let suffix = value.id ? `/variable/${themeRef}/${value.id}` : `/variable/${themeRef}`;
        return this.http[verb](Settings.THEME + suffix, value);
    }

    deleteVariable(themeRef:any, id:any) {
        return this.http.delete(Settings.THEME + `/variable/${themeRef}/${id}`);
    }

    previewTheme(id:any) {
        return this.http.put(Settings.THEME + `/preview/${id}`, {});

    }

    loadDefaultVariables(): Observable<any> {
        return this.http.get(Settings.THEME + "/default-variables");
    }
}
