import { Injectable } from '@angular/core';
import { Settings } from '../app.settings';
import * as _ from 'lodash';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Pageable } from '../shared/model/pageable';
import { toHttpParams } from '../shared/utils';

@Injectable()
export class EventService {

    constructor(private http: HttpClient) { }

    getEvents(value: any): Observable<Pageable<any>> {

        let params = toHttpParams(value);

        return this.http.get<Pageable<any>>(Settings.EVENT, {
            params
        });
    }

    getEventsBetween(start, end, value: any): Observable<any[]> {
        let params = toHttpParams(value);
        return this.http.get<any[]>(Settings.EVENT + "/between/" + start + "-" + end, {
            params
        });
    }

    getEventsForGroup(groupId, value: any): Observable<any> {
        let params = toHttpParams(value);
        return this.http.get<any[]>(Settings.EVENT + "/group/" + groupId, {
            params
        });
    }

    loadEvent(eventId: any) {
        return this.http.get(Settings.EVENT + "/" + eventId);
    }

    loadEventFeed() {
        return this.http.get(Settings.EVENT + "/feed");
    }

    deleteEvent(eventId: any) {
        return this.http.delete(Settings.EVENT + "/" + eventId);
    }

    saveEvent(value: any) {
        value = _.omitBy(value, (item) => _.isNull(item) || _.isUndefined(item));

        let suffix = value.id ? "/" + value.id : "";
        const data = new FormData();

        console.log(value);

        Object.keys(value).forEach(key => {
            if (key !== 'attachments') {
                data.append(key, value[key]);
            }
        });

        return this.http.post(Settings.EVENT + suffix, data);
    }

    subscribe(eventId: any) {
        return this.http.put(Settings.EVENT + "/participation/" + eventId, {});
    }

    unSubscribe(eventId: any) {
        return this.http.delete(Settings.EVENT + "/participation/" + eventId);
    }

    getUpcomingEvents() {
        return this.http.get(Settings.EVENT + "/upcoming");
    }

    loadMembers(id: any, page: number) {
        return this.http.get(Settings.EVENT + "/members/" + id + "?page=" + page);
    }

    getEmailForUsersInGroup(id: any) {
        return this.http.get(Settings.EVENT + "/members/emails/" + id);
    }

    like(eventId) {
        return this.http.put(Settings.EVENT + "/like/" + eventId, {});
    }

    unlike(eventId) {
        return this.http.put(Settings.EVENT + "/unlike/" + eventId, {});
    }

    saveComment(comment) {
        return this.http.post(Settings.EVENT + "/comment", comment);
    }

    deleteComment(id: any) {
        return this.http.delete(Settings.EVENT + "/comment/" + id);
    }
}
