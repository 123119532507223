import { Component, OnInit } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { LanguageService } from '../../../service/language.service';
import { AbstractModal } from '../../../shared/abstract-modal';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { Language } from '../../../domain/language';

@Component({
  selector: 'app-language-form',
  templateUrl: './language-form.component.html',
  styleUrls: ['./language-form.component.scss']
})
export class LanguageFormComponent extends AbstractModal implements OnInit {

  form: FormGroup = Language.form();

  constructor(
    private languageService: LanguageService,
    private ts: TranslateService,
    private toaster: ToastrService) {

    super();
  }

  ngOnInit() {
  }


  save() {
    this.languageService.save(this.form.value).subscribe(l => {
      this.onClose.emit(l);
      this.ts.get('toasts.successfullTranslationEdition').subscribe(ts => {
        this.toaster.success(ts);
        this.modal.hide();
      });
    });
  }

}
