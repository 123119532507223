import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { GroupService } from '../../../service/group.service';
import { UserService } from '../../../service/user.service';
import { FormGroup } from '@angular/forms';
import { Topic } from '../../../domain/topic';
import { ToastrService } from 'ngx-toastr';
import * as _ from 'lodash';
import * as $ from 'jquery';
import { SelectComponent } from 'ng2-select';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-topic-form',
    templateUrl: './topic-form.component.html',
    styleUrls: ['./topic-form.component.scss']
})
export class TopicFormComponent implements OnInit {

    form: FormGroup = Topic.form();
    groups;
    @Input('groupId')
    groupId;
    @Input('disabled')
    disabled;
    @Output()
    onTopic: EventEmitter<any> = new EventEmitter<any>();
    @ViewChild(SelectComponent, {static: false})
    select: SelectComponent;
    selectedGroup;
    private entities: any = [];

    isShowContent = true;


    constructor(private groupService: GroupService,
                private userService: UserService,
                private ts: TranslateService,
                private toaster: ToastrService) {
    }

    ngOnInit() {
        this.groupService.getGroups(null, 0).subscribe(groups => {
            this.groups = [];
            (<Array<any>>groups["entities"]).forEach(item => {
                this.groups.push({
                    id: item.id,
                    text: `<span class="${item.privacy} ${item.groupType}">${item.title}</span>`
                });
                this.entities.push(item);
            });
        });
        if(this.groupId){
            this.form.patchValue({ groupId: this.groupId });
        }

        this.onShowContent();
    }

    onGroupSelected(group){
        this.form.patchValue({ groupId: group.id });
        this.selectedGroup = _.find(this.entities, g => (<any>g).id == group.id);
        $(this.select.element.nativeElement)
            .find('.ui-select-container')
            .attr('data-privacy', this.selectedGroup.privacy)
            .attr('data-type', this.selectedGroup.groupType);
    }

    createTopic(){
        if (!this.form.valid) {
            return;
        }
        this.groupService.createTopic(this.form.value)
            .subscribe(topic => {
                this.form = Topic.form();
                this.form.patchValue({ groupId: this.groupId });
                this.ts.get('toasts.success.topicCreation').subscribe(ts => this.toaster.success(ts));
                this.onTopic.emit(topic);
            }, err => {
                this.ts.get('toasts.error.topicCreation', { reason: err }).subscribe(ts => this.toaster.error(ts));
            });
    }

    onType(search){
        this.groupService.searchGroup(search).subscribe(groups => {
            _.each((<any>groups).entities, g => {
                if(!_.find(this.groups, e => (<any>e).id == (<any>g).id)){
                    this.groups.push({
                        id: g.id,
                        text: `<span class="${g.privacy} ${g.groupType}">${g.title}</span>`
                    });
                    this.entities.push(g);
                    this.select.items = this.groups;
                    this.select.ngOnInit();
                }
            });
        });
    }

    onShowContent() {
        if (window.innerWidth < 960)
          this.isShowContent = !this.isShowContent;
      }
}
