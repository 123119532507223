import { Component, OnInit, ChangeDetectionStrategy, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ForumService } from '../../service/forum.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-forum-full',
  templateUrl: './forum-full.component.html',
  styleUrls: ['./forum-full.component.scss']
})
export class ForumFullComponent implements OnInit {

  post: any = null;

  private state$: Observable<object>;
  @ViewChild('scroller', { static: false }) private myScrollContainer: ElementRef;
  isShowComment = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private forumService: ForumService
  ) { }

  ngOnInit() {
    this.route.params.subscribe(({ id }) => {
      this.forumService.getPost(id)
        .subscribe(post => {
          this.post = post;
          if (this.isShowComment) {
            setTimeout(() => this.myScrollContainer.nativeElement.scrollTop = this.myScrollContainer.nativeElement.scrollHeight, 500);
          }
        });
    });

    this.state$ = this.route.paramMap
      .pipe(map(() => window.history.state));


    this.state$.subscribe(
      (state: any) => {
        if (state.showComment)
          this.isShowComment = state.showComment;
      }
    );
  }

  onDelete() {
    this.forumService.deletePost(this.post.id).subscribe(
      () => this.router.navigate(['/social/forum'])
    )
  }

}
