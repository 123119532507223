import { Injectable } from '@angular/core';
import { Settings } from '../app.settings';
import * as _ from 'lodash';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Pageable } from '../shared/model/pageable';
import { toHttpParams } from '../shared/utils';

@Injectable()
export class GroupService {

    constructor(private http: HttpClient) { }

    getGroups(groupType: any, page: number) {

        let suffix = groupType != null ? `?groupType=${groupType.toUpperCase()}&page=${page}` : '';

        return this.http.get(Settings.GROUPS + suffix);
    }

    getGroupsForSelector(pager): Observable<any> {
        let params = toHttpParams(pager);

        return this.http.get<any>(Settings.GROUPS, { params });
    }

    save(value: any) {
        let verb = value.id ? 'put' : 'post';
        let suffix = value.id ? '/' + value.id : '';
        return this.http[verb](Settings.GROUPS + suffix, value);
    }

    createTopic(value: any) {
        return this.http.post(Settings.TOPIC + "/" + value.groupId, value);
    }

    loadTopic(topicId: any) {
        return this.http.get(Settings.TOPIC + "/" + topicId);
    }

    createComment(topicId: any, comment: any) {
        let data = comment;
        let endPoint = "/comment/";
        if (_.get(comment, 'file')) {
            data = new FormData();
            data.append('file', comment.file);
            data.append('content', comment.content);
            endPoint += "upload/"
        }
        return this.http.post(Settings.TOPIC + endPoint + topicId, data);
    }

    getComments(topicId: any, page: number = 1): Observable<Pageable<any>> {
        return this.http.get<Pageable<any>>(Settings.TOPIC + "/comment/" + topicId + "?page=" + page);
    }

    getTopics(groupId: any, page: number): Observable<Pageable<any>> {
        return this.http.get<Pageable<any>>(Settings.TOPIC + "/group/" + groupId + "?page=" + page + "&itemsPerPage=10");
    }

    loadGroup(groupId: any) {
        return this.http.get(Settings.GROUPS + "/" + groupId);
    }

    addToGroup(groupId: any) {
        return this.http.put(Settings.GROUPS + "/join/" + groupId, {});
    }

    removeFromGroup(groupId: any) {
        return this.http.delete(Settings.GROUPS + "/join/" + groupId, {});
    }

    addUserToGroup(groupId: any, userId) {
        return this.http.put(Settings.GROUPS + "/join/" + groupId + "/user/" + userId, {});
    }

    removeUserGroup(groupId: any, userId) {
        return this.http.delete(Settings.GROUPS + "/join/" + groupId + "/user/" + userId);
    }

    loadMembers(groupId: any, page: number = 1) {
        return this.http.get(Settings.GROUPS + "/members/" + groupId + "?page=" + page);
    }

    searchGroup(search: any) {
        return this.http.get(Settings.GROUPS + "/search?q=" + search);
    }

    loadGroupsForUser(userId: any, page: number = 1) {
        return this.http.get(Settings.GROUPS + "/groups/" + userId + "?page=" + page);
    }

    uploadGroupImage(groupId: any, file: File) {
        let formData = new FormData();
        formData.append("file", file);
        return this.http.post(Settings.UPLOAD + "/groups/" + groupId, formData);
    }

    addFile(groupId: any, file: File) {
        let formData = new FormData();
        formData.append("file", file);
        return this.http.post(Settings.GROUPS + "/files/add/" + groupId, formData);
    }

    removeDoc(key: string): any {
        return this.http.delete(Settings.GROUPS + "/files/" + key);
    }

    updateTopic(topic: any) {
        return this.http.put(Settings.TOPIC + "/" + topic.id, topic);
    }

    deleteTopic(topicId: any) {
        return this.http.delete(Settings.TOPIC + "/" + topicId);
    }

    updateComment(comment: any) {

        let data = comment;
        let endPoint = "/comment/";
        let verb = 'put';
        if (_.get(comment, 'file')) {
            data = new FormData();
            data.append('id', comment.id);
            data.append('file', comment.file);
            data.append('content', comment.content);
            endPoint += "modify/upload/";
            verb = 'post';
        }

        return this.http[verb](Settings.TOPIC + endPoint + comment.id, data);
    }

    deleteComment(commentId: any) {
        return this.http.delete(Settings.TOPIC + "/comment/" + commentId);
    }

    loadTopicFeed() {
        return this.http.get(Settings.TOPIC + "/topics/feed");
    }

    deleteGroup(id: any) {
        return this.http.delete(Settings.GROUPS + "/" + id);
    }

    getEmailForUsersInGroup(id: any) {
        return this.http.get(Settings.GROUPS + "/members/all/" + id);
    }

    loadFilesForTopic(id: any, page: number = 1): Observable<Pageable<any>> {
        return this.http.get<Pageable<any>>(Settings.TOPIC + "/files/list/" + id + "?page=" + page);
    }

    loadFilesForGroup(groupId: any, page: number = 1): Observable<Pageable<any>> {
        return this.http.get<Pageable<any>>(Settings.GROUPS + "/files/list/" + groupId + "?page=" + page);
    }

    loadAllPublicTopics(): Observable<any[]> {
        return this.http.get<any[]>(Settings.TOPIC + "/topics/public/all");
    }

    likeTopic(topic: any) {
        return this.http.get(Settings.TOPIC + "/" + topic.id + "/like");
    }

    unlikeTopic(topic: any) {
        return this.http.get(Settings.TOPIC + "/" + topic.id + "/unlike");
    }
}
