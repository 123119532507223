import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { SkillService } from '../../service/skill.service';
import * as _ from 'lodash';
import { SelectComponent } from 'ng2-select';
import { TranslateService } from '@ngx-translate/core';
import { NgSelectComponent } from '@ng-select/ng-select';

@Component({
  selector: 'app-skill-selector',
  templateUrl: './skill-selector.component.html',
  styleUrls: ['./skill-selector.component.scss']
})
export class SkillSelectorComponent implements OnInit {

    @Input('form')
    form: FormGroup;

    @Input('multiple')
    multiple: boolean = false;

    @Input('placeholder')
    placeholder;

    @Input('allowClear')
    allowClear: boolean = false;

    @Input('showOnly')
    showOnly;

    @Input('exclude')
    exclude = [];

    skills = [];

    @ViewChild(NgSelectComponent, {static: false})
    select: NgSelectComponent;

    private settableValue;

    constructor(private skillService: SkillService, private ts: TranslateService) {
    }

    ngOnInit() {
        this.skills = [
            { id: "FUNCTION", text: "FUNCTION", children: [] },
            { id: "SKILL", text: "SKILL", children: [] },
            { id: "OTHER_SKILL", text: "OTHER_SKILL", children: [] }];

        this.skills.forEach(s => {
            this.ts.get(s.id).subscribe(ts => {
                s.text = ts;
            });
        });

        if(this.showOnly){
            this.skills = _.filter(this.skills, s => {
                return s.id == this.showOnly;
            });
        }

        if(this.exclude){
            _.remove(this.skills, s => {
                return this.exclude.includes(s.id);
            });
        }

        this.skillService.getSkills().subscribe(skills => {
            (<any>skills).forEach(item => {
                this.loadTranslation(item);
                this.skills.forEach(p => {
                    if(p.id == (<any>item).skillType){
                        p.children.push(item);
                    }
                });
            });

            if(this.form.value.skill){
                this.setValue(this.form.value.skill.id);
            }

            if(this.form.value.skillId){
                this.setValue(this.form.value.skillId);
            }

            if(this.form.value.skillIds){
                this.setValue(this.form.value.skillIds);
            }

            this.skills.forEach(p => {
                p.children = _.orderBy(p.children, [item => item.translationKey]);  
            });
            this.skills = _.clone(this.skills);

            if(this.settableValue){
                this.setValue(this.settableValue);
            }
        });
    }    

    refreshValue(value){
        if (value) {
            if(this.form){
                if(this.multiple){
                    this.form.patchValue({ skillIds: _.reduce(value, (res, i) => {
                        res.push((<any>i).id);
                        return res;
                    }, []) });
                }else{
                    this.form.patchValue({ skillId: value.id });
                }
            }
        }
        else {
            if (this.form)
                this.form.patchValue({ skillId: null });
        }
    }

    loadTranslation(item){
        this.ts.get(item.translationKey).subscribe(ts => {
            item.text = ts;
        });
    }

    clear() {
        this.select.writeValue(undefined);
    }

    setValue(value:any) {
        if(!value){
            this.clear();
            return;
        }

        if(_.isEmpty(this.skills)){
            this.settableValue = value;
            return;
        }

        if(!this.multiple){
            let data = _.find(_.flatten(_.map(this.skills, s => s.children)), o => {                
                return (<any>o).id == value;
            });
            
            if(data){
                this.select.writeValue(data.id);
            }
        }else{
            if(_.isArray(value)){
                let data = _.filter(_.flatten(_.map(this.skills, s => s.children)), o => {
                    return value.indexOf((<any>o).id) != -1;
                });                
                this.select.writeValue(_.map(data, d => d.id ));
            }else{
                this.clear();
            }
        }
    }
}
