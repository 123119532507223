import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NewsService } from '../../../service/news.service';
import { ModalService } from '../../../service/modal.service';
import { environment } from '../../../../environments/environment';

@Component({
    selector: 'app-news-item',
    templateUrl: './news-item.component.html',
    styleUrls: ['./news-item.component.scss']
})
export class NewsItemComponent implements OnInit {

    @Input()
    news;
    @Input()
    ts;
    @Output()
    onEdit: EventEmitter<any> = new EventEmitter<any>();
    @Output()
    onDelete: EventEmitter<any> = new EventEmitter<any>();

    constructor(private modalService: ModalService,
                private newsService: NewsService) {
    }

    ngOnInit() {
    }

    editNews() {
        this.onEdit.emit(this.news);
    }

    deleteNews() {
        this.modalService
            .confirm('news.deletion', 'news.deletionMessage')
            .subscribe(() => {
                this.newsService.deleteNews(this.news.id)
                    .subscribe(() => this.onDelete.emit(this.news.id));
            });
    }

    get envApi() {
        return environment.api;
    }

}
