import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NavBarService {
  
  isOpen: boolean;
  isOpenChange: Subject<boolean> = new Subject<boolean>();

  constructor() {    
    this.isOpen = false;

    this.isOpenChange.subscribe((value) => {
      this.isOpen = value;
    })
  }


  toggleNav(isOpen: boolean) {
    this.isOpenChange.next(isOpen);
  }
}
