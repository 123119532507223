import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { ChatReducer } from '../../store/reducers';
import { Observable } from 'rxjs';
import { Conversation } from '../../model/conversation';
import { ChatService } from '../../services/chat.service';
import { openConversation } from '../../store/actions';

@Component({
    selector: 'app-chat-list',
    templateUrl: './chat-list.component.html',
    styleUrls: ['./chat-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChatListComponent {
    
    public readonly conversations: Observable<Conversation[]>;
    
    constructor(
        private readonly store: Store<ChatReducer>,
        private readonly chatService: ChatService,
    ) {
        this.conversations = this.store.select('chat', 'conversations', 'entities');
    }
    
    public loadHistory() {
        this.chatService.loadConversations();
    }
    
    public openConversation(conversation: Conversation) {
        this.store.dispatch(openConversation(conversation));
    }
    
    public trackById(index, conversation: Conversation) {
        return conversation.id;
    }
}
