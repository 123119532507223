import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-topic-feed-item',
    templateUrl: './topic-feed-item.component.html',
    styleUrls: ['./topic-feed-item.component.scss']
})
export class TopicFeedItemComponent implements OnInit {

    @Input()
    item;
    @Input('ngClass')
    ngClass;

    constructor() {
    }

    ngOnInit() {
    }

}
