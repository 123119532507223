import { Component, Input, OnInit } from '@angular/core';
import { UserService } from '../../service/user.service';
import { Router } from '@angular/router';
import { FormGroup } from '@angular/forms';
import { SkillService } from '../../service/skill.service';
import * as _ from 'lodash';

@Component({
    selector: 'app-step-five',
    templateUrl: './step-five.component.html',
    styleUrls: ['./step-five.component.scss']
})
export class StepFiveComponent implements OnInit {

    @Input('form')
    form: FormGroup;
    @Input('loading')
    loading;
    private step = 5;
    public user;
    skills;

    constructor(private userService: UserService,
                private router: Router,
                private skillService: SkillService) {
    }

    ngOnInit() {
        this.userService.userObservable.subscribe(u => {
            this.user = u;
        });

        this.skillService.getSkills().subscribe((skills) => {
            this.skills = skills;
        });
    }

    skillsOfType(type){
        return _.filter(this.skills, f => (<any>f).skillType == type);
    }

    hasUserSkill(skill){
        return _.filter(this.user.skillIds, s => s == skill.id).length > 0;
    }

    manageSkill(skill){
        if(this.hasUserSkill(skill)){
            _.remove(this.user.skillIds, s => s == skill.id);
        }else{
            this.user.skillIds.push(skill.id);
        }
    }

    addSkill(skill){
        this.skills.push(skill);
        this.user.skillIds.push(skill.id);
    }
}
