import { Component, Input, OnInit } from '@angular/core';
import { UserService } from '../../service/user.service';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ConfigService } from '../../service/config.service';
import * as $ from 'jquery';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-step-three',
    templateUrl: './step-three.component.html',
    styleUrls: ['./step-three.component.scss']
})
export class StepThreeComponent implements OnInit {

    @Input('form')
    form: FormGroup;
    @Input('loading')
    loading;
    private step;

    constructor(private route: ActivatedRoute,
        private configService: ConfigService,
        private userService: UserService,
        private router: Router,
        private toaster: ToastrService,
        private ts: TranslateService) {
    }

    ngOnInit() {
        this.route.params.subscribe(p => {
            this.step = parseInt(p["step"]);

            if (this.step != 3) {
                return;
            }

            this.form.patchValue(p);
            this.route.queryParams.subscribe(qp => {
                if ('token' in qp) {
                    this.checkToken(qp['token']).subscribe(res => {
                        this.form.patchValue({
                            token: qp['token'],
                            firstname: res.firstname,
                            lastname: res.lastname,
                            acceptConditions: true
                        });
                    }, err => {
                        this.ts.get('toasts.noSuchToken').subscribe(ts => {
                            this.toaster.error(ts);
                            this.router.navigate(['/login']);
                        });
                    });
                } else {
                    this.router.navigate(['/login']);
                }
            });
        });
    }

    private checkToken(token: any) {
        return this.userService.checkToken(token);
    }

    onFileSelected($event) {
        let fileList: FileList = $event.target.files;
        if (fileList.length > 0) {
            if (this.checkIsImage(fileList.item(0))) {
                this.form.patchValue({ image: fileList.item(0) });
                this.loadPreview(fileList.item(0));
            }
        } else {
            this.form.patchValue({ image: undefined });
            $('#preview').attr('src', '');
            $('#preview').parent().removeClass("dropped");
        }
    }

    loadPreview(file) {
        var reader = new FileReader();

        reader.onload = (e) => {
            $('#preview').parent().addClass("dropped");
            $('#preview').attr('src', e.target["result"]);
        };

        reader.readAsDataURL(file);
    }

    checkIsImage(file) {
        var validImageTypes = ["image/gif", "image/jpeg", "image/png"];
        return ($.inArray(file["type"], validImageTypes) != -1);
    }
}
