import { Component, OnInit } from '@angular/core';
import { GroupService } from '../../service/group.service';
import { UserService } from '../../service/user.service';
import { ActivatedRoute, Router } from '@angular/router';
import * as _ from 'lodash';

@Component({
    selector: 'app-group',
    templateUrl: './group.component.html',
    styleUrls: ['./group.component.scss']
})
export class GroupComponent implements OnInit {

    groups;
    user;
    groupType;
    private page = 1;
    loading = false;

    constructor(private groupService: GroupService, private userService: UserService, private route: ActivatedRoute, router: Router) {
        router.events.subscribe(v => {
            this.page = 1;
        });

        this.user = this.userService.userObservable;
    }

    ngOnInit() {
        this.route.params.subscribe(p => {
            this.page = 1;
            if ('groupType' in p) {
                this.loading = true;
                this.groupType = p['groupType'];
                this.groupService.getGroups(p['groupType'], this.page).subscribe(g => {
                    this.groups = g;
                    this.loading = false;
                });

            }
        });
    }

    isAdmin() {
        return this.user.value && _.find(this.user.value.roles, r => r == 'ADMIN');
    }

    isAdminModo() {
        try {
            return _.find(<Array<any>>_.get(this, 'user.value.roles'), r => (r == 'ADMIN' || r == 'SUB_ADMIN' || r == 'MODERATOR'));
        } catch (err) {
            return false;
        }
    }



    onGroupEvent(group) {
        this.groups.entities.unshift(group);
    }

    get isShowGutter() {
        return (this.isAdminModo() && this.groupType.toUpperCase() == 'GROUP') || this.groupType.toUpperCase() == 'DISCUSSION';
    }

    onScroll() {
        if (this.loading) {
            return;
        }
        this.loading = true;
        this.groupService.getGroups(this.groupType, ++this.page).subscribe((g: any) => {
            this.loading = false;
            this.groups.entities = this.groups.entities.concat(g.entities);
        });
    }

    onScrollUp() { }
}
