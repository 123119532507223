import { Component } from "@angular/core";
import { AbstractModal } from "../../../shared/abstract-modal";
import { EventComment } from "../../../domain/event-comment";
import { FormGroup } from "@angular/forms";
import { EventService } from "../../../service/event.service";
import { TranslateService } from "@ngx-translate/core";
import { ToastrService } from "ngx-toastr";
import { UserService } from "../../../service/user.service";
import * as _ from 'lodash';

@Component({
  selector: 'app-event-comment-form',
  templateUrl: './event-comment-form.component.html',
  styleUrls: ['./event-comment-form.component.scss']
})
export class EventCommentFormComponent extends AbstractModal {

  form: FormGroup = EventComment.form();
  user: any;

  constructor(
    private eventService: EventService,
    private ts: TranslateService,
    private toaster: ToastrService,
    private userService: UserService
  ) {
    super();
    this.user = this.userService.userObservable;
  }

  ngOnInit() {
  }

  save() {
    this.eventService.saveComment(this.form.value).subscribe(post => {
      this.ts.get(`toasts.successfullForumPostEdition`).subscribe(ts => this.toaster.success(ts));
      this.close(post);
    });
  }

  isAdmin() {
    return this.user && _.find(this.user.roles, r => r == 'ADMIN');
  }

}
