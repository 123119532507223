import { environment } from '../environments/environment';
import { OwlDateTimeIntl } from 'ng-pick-datetime';

export class Settings {
    static LOGIN = "/api/auth/login";
    static SESSION = "/api/session/me";
    static LOGOUT = "/api/auth/logout";
    static OFFICES = "/api/office";
    static DELEGATION = "/api/office/delegation";
    static UPLOAD = "/api/upload";
    static USERS = "/api/user";
    static PUBLIC_GENDER = "/api/public/gender";
    static PUBLIC_GROUP_TYPES = "/api/public/group-types";
    static PUBLIC_PRIVACY_TYPES = "/api/public/privacy-types";
    static MESSAGES = "/api/i18n/";
    static MESSAGE_KEYS = "/api/i18n/keys";
    static REQUEST_PASSWORD = "/api/password";
    static EVENT = "/api/event";
    static FAVORITES = "/api/favorites";
    static GROUPS = "/api/group";
    static TOPIC = "/api/topic";
    static MESSAGING = "/messaging/";
    static DOCUMENT = "/api/document";
    static SKILL = "/api/skill";
    static REGISTRATION = "/api/registration";
    static CONFIG_PUBLIC = "/api/public/config";
    static THEME = "/api/theme";
    static FEED ="/api/feed";
    static SEARCH = "/api/search";
    static CURRENT_WEBSITE = "/api/website/current";
    static PUBLIC_SOCIAL_NETWORK_TYPES = "/api/public/social-network-types";
    static PUBLIC_SKILL_TYPES = "/api/public/skill-types";
    static PUBLIC_ROLES = "/api/public/roles";
    static LINK_GROUPS: string = "/api/link-groups";
    static NEWS: string = "/api/news";
    static NEWSLETTER: string = "/api/newsletter";
    static FORUM_POST: string = "/api/forum/post";
    static FORUM_COMMENT: string = "/api/forum/comment";
    static SEND_EMAIL: string = "/api/send-email";

    static get MESSAGING_URL(){
        return `${environment.websocketUrl}${Settings.MESSAGING}`;
    }
}

// here is the default text string
export class DefaultIntl extends OwlDateTimeIntl {

    /** A label for the cancel button */
    cancelBtnLabel = 'Annuler';

    /** A label for the set button */
    setBtnLabel = 'Choisir';
}

export const MY_MOMENT_FORMATS = {
    parseInput: 'l LT',
    fullPickerInput: 'l LT',
    datePickerInput: 'l',
    timePickerInput: 'LT',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
};

