import { Directive, ElementRef, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { fromEvent } from 'rxjs';
import { debounceTime, tap } from 'rxjs/operators';

@Directive({
    selector: '[appExpandable]'
})
export class ExpandableDirective implements OnInit {
    
    @Input()
    form: HTMLFormElement;
    @Output()
    submit = new EventEmitter<void>();
    
    constructor(
        private readonly elementRef: ElementRef
    ) {
    }
    
    public autoExpand(field: HTMLTextAreaElement): void {
        field.style.height = 'inherit';
        
        
        const computed = window.getComputedStyle(field);
        const height = parseInt(computed.getPropertyValue('border-top-width'), 10)
            + field.scrollHeight
            + parseInt(computed.getPropertyValue('border-bottom-width'), 10);
        
        field.style.height = (this.shoudBeExpanded(height) ? height : 30) + 'px';
        
    }
    
    public ngOnInit(): void {
        fromEvent(this.elementRef.nativeElement, 'keyup')
            .pipe(
                debounceTime(100),
                tap((e: any) => this.checkSubmit(e))
            ).subscribe((e: Event) => this.autoExpand(e.target as HTMLTextAreaElement));
        
        if (this.form) {
            fromEvent(this.form, 'submit')
                .pipe(
                    debounceTime(800)
                )
                .subscribe(_ => this.autoExpand(this.elementRef.nativeElement));
        }
        this.submit.pipe(
            debounceTime(800)
        ).subscribe(_ => this.autoExpand(this.elementRef.nativeElement));
    }
    
    private shoudBeExpanded(height: number): boolean {
        return /[\n\r]/.test(this.elementRef.nativeElement.value)
            || !this.isSingleLine();
    }
    
    private isSingleLine(): boolean {
        const {nativeElement} = this.elementRef;
        nativeElement.classList.add('nowrap');
        const single = nativeElement.scrollWidth <= nativeElement.offsetWidth;
        nativeElement.classList.remove('nowrap');
        return single;
    }
    
    private checkSubmit(e: any) {
        if (!this.form) {
            return;
        }
        if (e.key.toLowerCase() === 'enter' && e.ctrlKey) {
            this.submit.emit();
        }
    }
}
