import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { OfficeService } from '../../../service/office.service';
import * as _ from 'lodash';
import { ModalService } from '../../../service/modal.service';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'delegation-list',
    templateUrl: './delegation-list.component.html',
    styleUrls: ['./delegation-list.component.scss']
})
export class DelegationListComponent implements OnInit {

    @Input("delegations")
    delegations: any;
    @Input("officeId")
    officeId: string;
    @Output()
    onDelegation: EventEmitter<any> = new EventEmitter();

    constructor(private officeService: OfficeService,
                private modalService: ModalService,
                private ts: TranslateService, private toaster: ToastrService) {
    }

    ngOnInit() {

    }

    delete(delegation){
        this.modalService.confirm('delegation.deletion.title', 'delegation.deletion.message')
            .subscribe(() => {
                this.officeService.deleteDelegation(this.officeId, delegation).subscribe(d => {
                    _.remove(this.delegations, (del: any) => del.id == delegation.id);
                    this.ts.get('toasts.successfullDelegationDeletion').subscribe(ts => {
                        this.toaster.success(ts);
                    });
                });
            });
    }

    editDelegation(delegation){
        this.onDelegation.emit(delegation);
    }

}
