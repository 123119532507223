import { Component } from '@angular/core';
import { UserService } from '../../service/user.service';
import { Router } from '@angular/router';
import { NavBarService } from '../../service/nav-bar.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {

  constructor(
    private userService: UserService,
    private router: Router,
    private navigationService: NavBarService
  ) { }
  
  openNav() {
    this.navigationService.toggleNav(true);
  }

  navigateToHome() {
    this.router.navigate(['/']);
  }

  onLogout() {    
    this.userService.logout();    
  }
}
