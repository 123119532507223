import { Component, Input, OnInit } from '@angular/core';
import { GroupService } from '../../../service/group.service';

@Component({
    selector: 'app-topic-file-list',
    templateUrl: './topic-file-list.component.html',
    styleUrls: ['./topic-file-list.component.scss']
})
export class TopicFileListComponent implements OnInit {

    @Input()
    topic: any;
    public pageable: any | any;
    private page: number = 1;

    constructor(private groupService: GroupService) {
    }

    ngOnInit() {
        this.groupService
            .loadFilesForTopic(this.topic.id, this.page)
            .subscribe(pageable => this.pageable = pageable);
    }

    push(files: Array<any>) {
        if(this.pageable) {
            this.pageable.count += files.length;
            this.pageable.entities = files.concat(this.pageable.entities);
        }
    }

    loadFiles() {
        this.groupService
            .loadFilesForTopic(this.topic.id, ++this.page)
            .subscribe(pageable => {
                this.pageable.entities = this.pageable.entities.concat(pageable.entities);
            });
    }

}
