import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { MoreValidators } from '../validation/more-validators';

export class User {
    static form(){
        return new FormGroup({
            id: new FormControl(),
            username: new FormControl(),
            email: new FormControl(),
            firstname: new FormControl(),
            lastname: new FormControl(),
            gender: new FormControl(),
            phone: new FormControl(),
            secondaryPhone: new FormControl(),
            birthdate: new FormControl(),
            location: new FormControl(),
            city: new FormControl(),
            functionInOffices: new FormArray([]),
            socialNetworkLinks: new FormArray([]),
            roles: new FormControl(),
            skillIds: new FormControl()
        });
    }

    static changePasswordForm() {
        return new FormGroup({
            userId: new FormControl(null, []),
            oldPassword: new FormControl(null, [Validators.required]),
            newPassword: new FormControl(null, [Validators.required, MoreValidators.strongPassword]),
            passwordAgain: new FormControl(null, [Validators.required, MoreValidators.strongPassword]),
        }, MoreValidators.areEqual('newPassword', 'passwordAgain'));
    }
}
