import { Component, OnInit } from '@angular/core';
import { EventService } from '../../../service/event.service';
import * as moment from 'moment';
import { NewsService } from '../../../service/news.service';

@Component({
    selector: 'app-calendar-right-gutter',
    templateUrl: './calendar-right-gutter.component.html',
    styleUrls: ['./calendar-right-gutter.component.scss']
})
export class CalendarRightGutterComponent implements OnInit {

    public events;

    constructor(private eventService: EventService) {
    }

    ngOnInit() {
        this.eventService.getUpcomingEvents().subscribe(e => {
           this.events = e;
        });
    }

    isSameDay(event){
        return !event.end || moment(event.start).diff(moment(event.end), 'days') == 0;
    }

}
