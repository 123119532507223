import { Component, OnInit, ViewContainerRef } from '@angular/core';
import { ModalService } from '../service/modal.service';
import { LinkGroupFormComponent } from './link-group-form/link-group-form.component';
import { LinkGroupService } from '../service/link-group.service';
import { UserService } from '../service/user.service';
import * as _ from 'lodash';

@Component({
    selector: 'app-link-groups',
    templateUrl: './link-groups.component.html',
    styleUrls: ['./link-groups.component.scss']
})
export class LinkGroupsComponent implements OnInit {

    groups: any | any;
    user: any;

    constructor(private modal: ModalService,
                private vcr: ViewContainerRef,
                private linkGroupService: LinkGroupService,
                private userService: UserService) {
    }

    ngOnInit() {
        this.user = this.userService.u;
        this.userService.userObservable.subscribe(u => this.user = u);
        this.linkGroupService.loadLinkGroups().subscribe(groups => {
            this.groups = groups;
        });
    }

    addOrEditLinkGroup(entity: any) {
        const modal = this.modal.openModal(LinkGroupFormComponent, this.vcr);

        if(entity) {
            modal.instance.form.patchValue(entity);
        }

        modal.instance.onClose.subscribe(group => {
            modal.destroy();
            if(_.find(this.groups, (g:any) => g.id === group.id)) {
                this.groups = this.groups
                    .map(g => {
                        if (g.id === group.id) {
                            return _.extend(g, group);
                        }
                        return g;
                    })
                    .sort((a, b) => a.position < b.position ? -1 : 1);
            } else {
                this.groups = [group].concat(this.groups).sort((a, b) => a.position < b.position ? -1 : 1);
            }
        });

        modal.instance.onDiscard.subscribe(() => {
            modal.destroy();
        });
    }

    onGroupDelete(id) {
        _.remove(this.groups, (g:any) => g.id === id);
    }

    isAdmin(){
        return _.find(<Array<any>>_.get(this, 'user.roles'), r => (r == 'ADMIN' || r == 'SUB_ADMIN'));
    }
}
