import { Component, OnInit, ViewContainerRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { OfficeService } from '../../../service/office.service';
import { ModalService } from '../../../service/modal.service';
import { DelegationFormComponent } from '../delegation-form/delegation-form.component';
import * as _ from 'lodash';
import { environment } from '../../../../environments/environment';

@Component({
    selector: 'app-office-full',
    templateUrl: './office-full.component.html',
    styleUrls: ['./office-full.component.scss']
})
export class OfficeFullComponent implements OnInit {

    office;

    constructor(
        private route:ActivatedRoute,
        private officeService:OfficeService,
        private modalService: ModalService,
        private vcr: ViewContainerRef) {
    }

    ngOnInit() {
        this.route.params.subscribe(r => {
            if ('id' in r) {
                this.officeService.getOffice(r['id']).subscribe(o => this.office = o);
            }
        });
    }

    editDelegation(delegation){
        var modal = this.modalService.openModal(DelegationFormComponent, this.vcr);
        modal.instance.office = this.office;
        modal.instance.onClose.subscribe(delegation => {
            var delegations = _.filter(_.get(this, 'office.delegations'), (d:any) => d.id == delegation.id)
                .map((d:any) => _.extend(d, delegation));
            if(delegations.length == 0){
                if(!_.isArray(this.office.delegations)){
                    this.office.delegations = [delegation];
                }else{
                    this.office.delegations.push(delegation);
                }
            }
        });

        modal.instance.onHide.subscribe(() =>{
            modal.destroy();
        });

        if(delegation){
            modal.instance.form.patchValue(delegation);
        }
    }

    get envApi() {
        return environment.api;
    }

}
