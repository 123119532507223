import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'nl2Br'
})
export class Nl2BrPipe implements PipeTransform {

    transform(value: any, args?: any): any {
        if (value) {
            return (value + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1<br />$2')
        }
        return '';
    }

}
