import { Component, Input, OnInit } from '@angular/core';
import { environment } from '../../../../environments/environment';

@Component({
    selector: 'app-media-feed-item',
    templateUrl: './media-feed-item.component.html',
    styleUrls: ['./media-feed-item.component.scss']
})
export class MediaFeedItemComponent implements OnInit {

    @Input()
    item;
    @Input('ngClass')
    ngClass;

    constructor() {
    }

    ngOnInit() {
    }

    get envApi() {
        return environment.api;
    }

}
