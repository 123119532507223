import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { OccupantForm } from '../../forms/occupant.form';

@Component({
    selector: 'app-occupant-form-item',
    templateUrl: './occupant-form-item.component.html',
    styleUrls: ['./occupant-form-item.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class OccupantFormItemComponent {
    @Input('formGroup')
    public occupant: OccupantForm;
    @Output()
    public remove = new EventEmitter();
}
