import { FormControl, FormGroup, Validators } from '@angular/forms';

export class LinkGroup {
    static form(){
        return new FormGroup({
            id: new FormControl(),
            name: new FormControl(null, [Validators.required]),
            position: new FormControl(0)
        });
    }
}
