import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ThemeService } from '../../../service/theme.service';
import * as _ from 'lodash';
import * as $ from 'jquery';

@Component({
    selector: 'app-variable-item',
    templateUrl: './variable-item.component.html',
    styleUrls: ['./variable-item.component.scss']
})
export class VariableItemComponent implements OnInit {

    @Input()
    variable;
    @Input()
    variables;
    @Input()
    theme;
    @Output()
    onEdit: EventEmitter<any> = new EventEmitter<any>();

    constructor(private themeService: ThemeService) {
    }

    ngOnInit() {
    }

    getStyle(){
        if(this.isColor()){
            return {
                color: this.variable.value
            }
        }
    }

    private isColor() {
        return this.variable.value.match(new RegExp("/\#/", "ig"));
    }

    importStatement(){
        if(this.variable.value.match(/http/)){
            return `url('${this.variable.value}')`;
        }
        return `'${this.variable.value}'`;
    }

    edit(){
        this.onEdit.emit(_.clone(this.variable));
    }

    delete(){
        this.themeService.deleteVariable(this.theme.id, this.variable.id)
            .subscribe( res => {
                _.remove(this.variables, v => (<any>v).id == this.variable.id);
                this.updateCssTimestamp();
            })
    }

    private updateCssTimestamp() {
        let ts = new Date();
        $(document).find('link').each((l,e) => {
            if($(e).attr('href') == "/api/css"){
                $(e).attr('data-href', $(e).attr('href'));
            }
            if($(e).attr('data-href') == "/api/css") {
                $(e).attr('href', $(e).attr('data-href') +"?ts="+ts.getMilliseconds());
            }
        });
    }
}
