import { Component, OnInit } from '@angular/core';
import { Delegation } from '../../../domain/delegation';
import { OfficeService } from '../../../service/office.service';
import { AbstractModal } from '../../../shared/abstract-modal';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-delegation-form',
    templateUrl: './delegation-form.component.html',
    styleUrls: ['./delegation-form.component.scss']
})
export class DelegationFormComponent extends AbstractModal implements OnInit {

    form = Delegation.form();
    office;

    constructor(private officeService: OfficeService,
                private ts: TranslateService,
                private toaster:ToastrService) {
        super();
    }

    ngOnInit() {
    }

    save(){
        this.officeService.saveDelegation(this.office.id, this.form.value).subscribe(d => {
            this.close(d);
        });
    }

}
