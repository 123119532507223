import { FormControl, FormGroup, Validators } from '@angular/forms';

export class Topic {
    static form(){
        return new FormGroup({
            id: new FormControl(),
            subject: new FormControl('', [Validators.required]),
            content: new FormControl('', [Validators.required]),
            groupId: new FormControl('', [Validators.required]),
            active: new FormControl(true)
        });
    }
}
