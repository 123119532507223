import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { ThemeService } from '../../../service/theme.service';
import { FormGroup } from '@angular/forms';
import { Theme } from '../../../domain/theme';
import * as _ from 'lodash';

@Component({
    selector: 'app-theme-form',
    templateUrl: './theme-form.component.html',
    styleUrls: ['./theme-form.component.scss']
})
export class ThemeFormComponent implements OnInit, OnChanges {

    form: FormGroup = Theme.form();
    @Input('theme')
    theme;
    @Output()
    onTheme: EventEmitter<any> = new EventEmitter<any>();

    constructor(private themeService: ThemeService) {
    }

    ngOnInit() {
        if(this.theme){
            this.form.patchValue(this.theme);
        }
    }


    ngOnChanges(changes:SimpleChanges):void {
        if(!_.get(changes, 'theme.currentValue')){
            this.form = Theme.form();
        }else{
            this.form.patchValue(_.get(changes, 'theme.currentValue'));
        }
    }

    save(){
        this.themeService.save(this.form.value).subscribe(theme => {
            this.onTheme.emit(theme);
        });
    }

}
