import { Component, OnInit, ViewChild } from '@angular/core';
import { ThemeService } from '../../service/theme.service';
import { ModalDirective } from 'ngx-bootstrap';
import { ToastrService } from 'ngx-toastr';
import * as _ from 'lodash';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-theming',
    templateUrl: './theming.component.html',
    styleUrls: ['./theming.component.scss']
})
export class ThemingComponent implements OnInit {

    themes;
    @ViewChild('formModal', {static: false})
    public formModal:ModalDirective;
    currentTheme;

    constructor(private themeService: ThemeService,
    private ts: TranslateService,
    private toaster: ToastrService) {
    }

    ngOnInit() {
        this.themeService.loadThemes().subscribe(themes => {
            this.themes = themes;
        });
    }

    openThemeFormModal(){
        this.formModal.show();
    }

    closeThemeFormModal(){
        this.currentTheme = undefined;
        this.formModal.hide();
    }

    activateTheme(theme){
        this.themeService.activate(theme.id)
            .subscribe(activation => {
                _.forEach(this.themes, t => {
                    if(t.id == theme.id){
                        t.active = true;
                    }else{
                        t.active = false;
                    }
                });
                this.ts.get("toasts.successfullThemeActivation").subscribe(ts => this.toaster.success(ts));
            });
    }

    onTheme(theme){
        this.closeThemeFormModal();
        if(_.find(this.themes, t => (<any>t).id == theme.id)){
            _.forEach(this.themes, t => {
                if((<any>t).id == theme.id){
                    t = _.extend(t, theme);
                }
            });
        }else{
            this.themes.push(theme);
        }
    }

    editTheme(theme){
        this.currentTheme = theme;
        this.openThemeFormModal();
    }

}
