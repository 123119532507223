import { Injectable } from '@angular/core';
import { Settings } from '../app.settings';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class LanguageService {

    constructor(private http: HttpClient) {}

    getLanguages() {
        return this.http.get(Settings.MESSAGES);
    }

    save(value:any) {
        let verb = value.id ? 'put' : 'post';
        let suffix = value.id ? '/'+value.id : '';
        return this.http[verb](Settings.MESSAGES + suffix, value);
    }

    getLanguage(languageId:any) {
        return this.http.get(Settings.MESSAGES + "/" + languageId);
    }

    loadKeysFor(languageId:any): Observable<any[]> {
        return this.http.get<any[]>(Settings.MESSAGE_KEYS + "/" + languageId);
    }

    saveKey(languageId:any, value:any) {
        let verb = value.id ? 'put' : 'post';
        let suffix = value.id ? '/'+value.id : '';
        return this.http[verb](Settings.MESSAGE_KEYS + "/" + languageId + suffix, value);
    }

    deleteKey(languageId:any, keyId:any) {
        return this.http.delete(Settings.MESSAGE_KEYS + "/" + languageId + "/" + keyId);
    }
}
