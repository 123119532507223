import { Component, OnInit, ViewChild, ViewContainerRef, ElementRef } from '@angular/core';
import { EventService } from '../../../service/event.service';
import { ActivatedRoute } from '@angular/router';
import { GroupMemberListComponent } from '../../group/group-member-list/group-member-list.component';
import { UserService } from '../../../service/user.service';
import * as _ from 'lodash';
import * as $ from 'jquery';
import { Location } from '../../../domain/Location';
import { GeocodeService } from '../../../service/geocode.service';
import { environment } from '../../../../environments/environment';
import { EventComment } from '../../../domain/event-comment';
import { ModalService } from '../../../service/modal.service';
import { EventCommentFormComponent } from '../event-comment-form/event-comment-form.component';
import { Observable } from 'rxjs';
import { window } from 'ngx-bootstrap';
import { map } from 'rxjs/operators';

@Component({
    selector: 'app-calendar-full',
    templateUrl: './calendar-full.component.html',
    styleUrls: ['./calendar-full.component.scss']
})
export class CalendarFullComponent implements OnInit {

    event: any;
    @ViewChild(GroupMemberListComponent, { static: false })
    groupMemberList: GroupMemberListComponent;

    location: Location;

    formAddComment = EventComment.form();

    users = new Map<string, any>();

    private state$: Observable<object>;
    @ViewChild('scroller', { static: false }) private myScrollContainer: ElementRef;
    isShowComments = false;

    me: any;

    constructor(private eventService: EventService,
        private route: ActivatedRoute,
        private userService: UserService,
        private modalService: ModalService,
        private vcr: ViewContainerRef,
        private geocodeService: GeocodeService) {

        this.me = this.userService.userObservable;
    }

    ngOnInit() {
        this.route.params.subscribe(({ id }) => {
            this.eventService.loadEvent(id)
                .subscribe(event => {
                    this.event = event;
                    this.loadUsers();
                    this.geocodeService.codeAddress(this.event.location).subscribe(
                        (loc) => this.location = loc
                    );

                    this.userService.updateReadStatusEvent(this.event.id).subscribe(s => {
                    });

                    if (this.isShowComments) {
                        setTimeout(() => this.myScrollContainer.nativeElement.scrollTop = this.myScrollContainer.nativeElement.scrollHeight, 500);
                      }
                });
        });



        this.userService.onSocketEvent.subscribe(data => {
            if (data.messageType == 'eventComment' && data.eventId == this.event.id) {
                this.onComment(data);
            }
        });

        this.state$ = this.route.paramMap
            .pipe(map(() => window.history.state));


        this.state$.subscribe(
            (state: any) => {
                if (state.showComment)
                    this.isShowComments = state.showComment;
            }
        );
    }

    onComment(comment) {
        if (_.filter(this.event.comments, (e: any) => e.id == comment.id).length == 0) {
            this.event.comments.push(comment);
        }
    }

    loadUsers() {
        this.event.participations.forEach(p => {
            if (this.users.get(p.userId) === null || this.users.get(p.userId) === undefined) {
                this.userService.loadUser(p.userId).subscribe(
                    (u) => this.users.set(p.userId, u)
                );
            }
        });
    }

    joinEvent() {
        if (!this.event.participating) {
            this.eventService
                .subscribe(this.event.id)
                .subscribe((subscription: any) => {
                    this.event.participating = true;
                    this.event.participations = subscription.participations;
                });
        } else {
            this.eventService
                .unSubscribe(this.event.id)
                .subscribe((subscription: any) => {
                    this.event.participating = false;
                    this.event.participations = subscription.participations;
                });
        }
        this.loadUsers();
    }

    checkIsImage(att) {
        var validImageTypes = ["image/gif", "image/jpeg", "image/png"];
        return ($.inArray(att.contentType, validImageTypes) != -1);
    }

    getUser(p) {
        return this.users.get(p.userId);
    }

    get envApi() {
        return environment.api;
    }

    public toggleLike() {
        let obs;
        if (!this.event.liking)
            obs = this.eventService.like(this.event.id);
        else
            obs = this.eventService.unlike(this.event.id);

        obs.subscribe(
            (event) => {
                this.event.liked = event.liked;
                this.event.liking = event.liking;
            }
        );

    }

    onDeleteComment(comment) {
        this.modalService
            .confirm('forumcomment.deletion.title', 'forumcomment.deletion.message')
            .subscribe(() => {
                this.eventService.deleteComment(comment.id).subscribe(
                    () => _.remove(this.event.comments, (c: any) => c.id === comment.id)
                );
            });
    }

    onEditComment(comment) {
        const modal = this.modalService.openModal(EventCommentFormComponent, this.vcr);

        modal.instance.form.patchValue(comment);

        modal.instance.onClose.subscribe((result) => {
            let c = this.event.comments.find(co => co.id === result.id);
            let i = this.event.comments.indexOf(c);
            this.event.comments[i] = result;
            modal.destroy();
        });

        modal.instance.onDiscard.subscribe(() => modal.destroy());
    }
    isAdmin() {
        return _.find(<Array<any>>_.get(this, 'userService.u.roles'), r => (r === 'ADMIN' || r === 'SUB_ADMIN'));
    }

    isCreatorPost() {
        return _.get(this, 'userService.u.id') == _.get(this.event, 'creator');
    }

    isCreatorComment(comment) {
        return _.get(this, 'userService.u.id') == _.get(comment, 'creator');
    }

    onAddComment() {
        this.formAddComment.get('eventId').setValue(this.event.id);

        this.eventService.saveComment(this.formAddComment.value).subscribe(
            (comment) => {
                this.onComment(comment);
                // this.event.comments.push(comment);
                this.formAddComment = EventComment.form();
            }
        );
    }
}
