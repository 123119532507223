import { Component, Input, OnInit, ViewContainerRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { forkJoin } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { DocumentService } from '../../../service/document.service';
import { GroupService } from '../../../service/group.service';
import { ModalService } from '../../../service/modal.service';
import { DocumentFormComponent } from '../document-form/document-form.component';
import * as _ from 'lodash';

@Component({
  selector: 'app-group-document-list',
  templateUrl: './group-document-list.component.html',
  styleUrls: ['./group-document-list.component.scss']
})
export class GroupDocumentListComponent implements OnInit {

  @Input()
  title;

  @Input()
  isEdit;

  private groupId;
  pageable;
  page: number = 1;
  public loading = false;

  private mapChecked = new Map<string, boolean>();

  constructor(
    private documentService: DocumentService,
    private groupService: GroupService,
    private route: ActivatedRoute,
    private modalService: ModalService,
    private vcr: ViewContainerRef) {
  }

  ngOnInit() {
    this.route.params.subscribe(p => {
      if ('id' in p) {
        this.groupId = p["id"];
        this.loadDocuments();
      }
    });
  }

  loadDocuments() {
    this.groupService.loadFilesForGroup(this.groupId, this.page).subscribe(
      (d: any) => {
        if (!this.pageable) {
          this.pageable = d;
        } else {
          this.pageable.entities = this.pageable.entities.concat(d.entities);
        }

        this.mapChecked = new Map<string, boolean>();
        this.pageable.entities.forEach(t => this.mapChecked.set(t.id, false));
      });
  }

  public forceReload() {
    this.pageable = null;
    this.page = 1;
    this.loadDocuments()
  }

  get envApi() {
    return environment.api;
  }

  onScroll() {
    if (_.get(this.pageable, "entities.length") != _.get(this.pageable, "count")) {
      this.page++;
      this.loadDocuments();
    }
  }

  onFileSelected(e) {
    if (_.get(e, 'target.files.length') > 0) {
      this.groupService.addFile(this.groupId, e.target.files[0]).subscribe(
        () => this.forceReload()
      );
    }
  }

  onAdd() {
    const modal = this.modalService.openModal(DocumentFormComponent, this.vcr);

    modal.instance.form.get('groupId').setValue(this.groupId);

    modal.instance.onClose.subscribe((result) => {
      this.page = 1;
      this.pageable = null;
      this.loadDocuments();
      modal.destroy();
    });

    modal.instance.onDiscard.subscribe(() => modal.destroy());
  }

  onChecked(doc) {
    this.mapChecked.set(doc.id, !this.mapChecked.get(doc.id));
  }

  onDelete() {
    this.modalService
      .confirm('groupdoc.deletion.title', 'groupdoc.deletion.message')
      .subscribe(() => {

        let ids = [];
        let obs = [];
        this.mapChecked.forEach((value, key) => {
          if (value) {
            ids.push(key);
            obs.push(this.groupService.removeDoc(key));
          }
        });

        if (ids.length > 0) {

          forkJoin(obs).subscribe(
            () => {
              this.page = 1;
              this.pageable = null;
              this.loadDocuments();
            }
          )
        }
      });

  }
}
