import { EventEmitter, Injectable } from '@angular/core';
import { Settings } from '../app.settings';
import { StorageService } from './storage.service';
import * as _ from 'lodash';
import { Title } from '@angular/platform-browser';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, share, tap } from 'rxjs/operators';

@Injectable()
export class ConfigService {

    configObs = new Observable(cfg => this.configSubscriber = cfg).pipe(
        share()
    );
    configSubscriber;
    config;
    onPictureChanged: EventEmitter<any> = new EventEmitter<any>();

    constructor(private http: HttpClient,
                private storage: StorageService,
                private title: Title) {
        this.loadConfig();
    }

    loadConfig(){
        this.configObs.subscribe((cfg: any) => {
            this.config = cfg;
            this.title.setTitle(cfg.siteName);
        });
        return this.http.get(Settings.CONFIG_PUBLIC)
            .pipe(
                map(res => {
                    this.setConfig(res);
                    return res;
                })
            );
    }

    private setConfig(data:any|{}) {
        this.configSubscriber.next(data);
    }

    save(value:any) {
        return this.http.post(Settings.CONFIG_PUBLIC, value)
            .pipe(
                tap(c => this.config = c)
            );
    }

    uploadPicture(file, data: any){
        let formData = new FormData();
        formData.append("file", file);

        let headers = new Headers({
            "Authorization": _.get(this.storage.getToken(), 'token')
        });

        for(let key in data){
            formData.append(key, data[key]);
        }

        return this.http.post(Settings.UPLOAD, formData)
            .pipe(
                map(res => {
                    this.onPictureChanged.emit(res);
                    return res;
                })
            );
    }
}
