import { Component, OnInit, ViewContainerRef } from '@angular/core';
import { SkillService } from '../../service/skill.service';
import { ModalService } from '../../service/modal.service';
import { SkillModalComponent } from './skill-modal/skill-modal.component';
import * as _ from 'lodash';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';

@Component({
    selector: 'app-skill',
    templateUrl: './skill.component.html',
    styleUrls: ['./skill.component.scss']
})
export class SkillComponent implements OnInit {

    public skills;
    public showSkills;

    searchForm: FormGroup = new FormGroup({
        search: new FormControl(null, [Validators.required])
    });


    constructor(private skillService: SkillService,
                private modalService: ModalService,
                private vcr: ViewContainerRef,
                private ts: TranslateService,
                private toaster: ToastrService) {
    }

    ngOnInit() {
        this.skillService.getSkills().subscribe(skills => {
            this.skills = skills;
            this.showSkills = skills;
        });

        this.searchForm.valueChanges.subscribe(
            () => this._findSkills()
        );
    }

    private _findSkills() {
        this.showSkills = this.skills.filter(s => 
            s.translationKey.toUpperCase().includes(this.searchForm.get('search').value.toUpperCase())
            || this.ts.instant(s.translationKey).toUpperCase().includes(this.searchForm.get('search').value.toUpperCase()));
    }

    editSkill(skill?: any) {
        var modal = this.modalService.openModal(SkillModalComponent, this.vcr);
        if(skill){
            modal.instance.setSkill(skill);
        }
        modal.instance.onClose.subscribe(skill => {
            modal.destroy();
            this.addOrExtendSkill(skill);
        });
        modal.instance.onDiscard.subscribe(() => {
            modal.destroy();
        });
    }

    removeSkill(skill:any){
        this.modalService.confirm("skill.removal.title", "skill.removal.description").subscribe(() => {
            this.skillService.remove(skill.id).subscribe(() => {
                this.ts.get('toasts.successfullSkillRemoval').subscribe(ts => {
                    this.toaster.success(ts);
                    _.remove(this.skills, (s:any) => s.id == skill.id);
                });
            });
        });
    }

    private addOrExtendSkill(skill:any) {
        var skills = _.filter(this.skills, s => (<any>s).id == skill.id);
        if(skills.length == 0){
            this.skills.unshift(skill);
        }else{
            skills.forEach(s => _.extend(s, skill));
        }
    }
}
