import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  selector: 'app-forum-feed-item',
  templateUrl: './forum-feed-item.component.html',
  styleUrls: ['./forum-feed-item.component.scss']
})
export class ForumFeedItemComponent implements OnInit {

  @Input()
  item;

  constructor() { }

  ngOnInit() {
  }

}
