import { Component, OnInit } from '@angular/core';
import { TranslationKey } from '../../../domain/translation-key';
import { FormGroup } from '@angular/forms';
import { LanguageService } from '../../../service/language.service';
import { AbstractModal } from '../../../shared/abstract-modal';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-translation-key-form',
    templateUrl: './translation-key-form.component.html',
    styleUrls: ['./translation-key-form.component.scss']
})
export class TranslationKeyFormComponent extends AbstractModal implements OnInit {

    form: FormGroup = TranslationKey.form();
    language;

    constructor(private languageService: LanguageService,
                private ts: TranslateService,
                private toaster: ToastrService) {
        super();
    }

    ngOnInit() {
    }

    save(){
        this.languageService.saveKey(this.language.id, this.form.value).subscribe(k => {
            this.onClose.emit(k);
            this.ts.get('toasts.successfullTranslationEdition').subscribe(ts => {
                this.toaster.success(ts);
                this.modal.hide();
            });
        });
    }

}
