import { Injectable } from '@angular/core';
import { Settings } from '../app.settings';
import { HttpClient, HttpParams } from '@angular/common/http';

@Injectable()
export class SearchService {

    constructor(private http: HttpClient) {}

    search(value:any) {
        let params: HttpParams = new HttpParams();

        for(let key in value){
            params = params.append(key, value[key]);
        }

        return this.http.get(Settings.SEARCH, { params });
    }
}
