import { Component, OnInit } from '@angular/core';
import { AbstractModal } from '../../../shared/abstract-modal';
import { SkillService } from '../../../service/skill.service';
import { FormGroup } from '@angular/forms';
import { Skill } from '../../../domain/skill';
import { PublicDataService } from '../../../service/public-data.service';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-skill-modal',
    templateUrl: './skill-modal.component.html',
    styleUrls: ['./skill-modal.component.scss']
})
export class SkillModalComponent extends AbstractModal implements OnInit {

    form: FormGroup = Skill.adminForm();
    action: string = "creation";
    skillTypes;

    constructor(private skillService: SkillService,
                private publicDataService: PublicDataService,
                private ts: TranslateService,
                private toaster: ToastrService) {
        super();
    }

    ngOnInit() {
        this.publicDataService.getSkillTypes().subscribe(skillTypes => this.skillTypes = skillTypes);
    }

    setSkill(skill: any){
        this.action = "edition";
        this.form.patchValue(skill);
    }

    saveSkill(){
        this.skillService.save(this.form.value).subscribe(skill => {
            this.onClose.emit(skill);
            this.ts.get(`toasts.skill.${this.action}.success`).subscribe(ts => {
                this.toaster.success(ts);
            });
        });
    }

}
