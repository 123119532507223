import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-comment-forum-feed-item',
  templateUrl: './comment-forum-feed-item.component.html',
  styleUrls: ['./comment-forum-feed-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CommentForumFeedItemComponent implements OnInit {


  @Input()
  item;
  
  constructor(
    private router: Router
  ) { }

  ngOnInit() {
  }

  manageRedirect() {
    this.router.navigate(['/social/forum', this.item.data.forumcomment.postId, { state: { showComment: true } }]);
  }

}
