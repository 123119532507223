import { EventEmitter, ViewChild } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap';

export class AbstractModal {

    @ViewChild(ModalDirective, {static: false})
    modal: ModalDirective;

    onDiscard: EventEmitter<any> = new EventEmitter();
    onClose: EventEmitter<any> = new EventEmitter();
    onHide: EventEmitter<any> = new EventEmitter();

    hideModal(){
        this.onHide.emit(null);
        this.modal.hide();
    }

    close(value){
        this.onClose.emit(value);
        this.modal.hide();
    }

    onHidden(){
        this.onHide.emit();
    }
}
