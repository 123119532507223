import { Injectable } from '@angular/core';
import { ITokenProvider } from '../modules/chat/interfaces/token-provider.interface';

@Injectable()
export class StorageService implements ITokenProvider {

    getToken() {
        if(this.hasToken()){
            return JSON.parse(localStorage.getItem("token"));
        }
        return null;
    }

    hasToken() {
        return localStorage.getItem("token") != undefined;
    }

    storeToken(token:any|{}) {
        localStorage.setItem("token", JSON.stringify(token));
    }

    removeToken(){
        localStorage.removeItem("token");
    }

    saveRegistration(step, value) {
        localStorage.setItem("registration", JSON.stringify(value));
        if(!localStorage.getItem("registration-step") || localStorage.getItem("registration-step") < step){
            localStorage.setItem("registration-step", step);
        }
    }

    getRegistration(){
        let data = {};
        try {
            data = JSON.parse(localStorage.getItem("registration")) || {};
        }catch (e){
            console.log(e);
        }
        return {
            data: data,
            step: parseInt(localStorage.getItem("registration-step")) || 1
        };
    }

    getCookieConsent(){
        return localStorage.getItem("cookieConsent");
    }

    setCookieConsent(){
        localStorage.setItem("cookieConsent", "y");
    }
    
    getTokenString(): string {
        return this.getToken().token;
    }
}
