import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Injectable } from '@angular/core';
import { UserService } from '../service/user.service';

@Injectable()
export class MoreValidators {

    constructor(private userService: UserService){}

    static areEqual(field1, field2){
        return (group: FormGroup) => {
            let value = group.value;
            if(value[field1] && value[field1] != value[field2]){
                return { "unequalField": true };
            }
            return null;
        };
    }

    static strongPassword(control: FormControl){
        let re = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,}/;
        let result = re.test(control.value);
        if(result){
            return null;
        }
        return {
            "weak password": true
        };
    }

    static mimes(valid: string, nullable: boolean = true) {
        return (control: FormControl): { [key: string]: any } | null => {
            let forbidden = true;
            if (control.value) {
                const mime = control.value.name.split('.').pop();
                
                valid.split(',').forEach(extension => {
                    if (extension.trim() == mime) {
                        forbidden = false;
                    }
                });
            }

            if (! control.value && nullable) {
                forbidden = false;
            }

            return forbidden ? { 'inValid': true } : null;
        };
    }

    static size(valid: string, nullable: boolean = true) {
        return (control: FormControl): { [key: string]: any } | null => {
            let forbidden = true;
            if (control.value) {
                const mime = control.value.size;
                
                if (valid >= mime) {
                    forbidden = false;
                }
            }

            if (! control.value && nullable) {
                forbidden = false;
            }

            return forbidden ? { 'inValid': true } : null;
        };
    }

    isEmailUsed(){
        return (control: FormControl) => {
            return new Promise(resolve => {
                if(Validators.email(control) == null){
                    this.userService.checkEmail(control.value).subscribe(
                        success => {
                            resolve(null);
                        },
                        error => {
                            resolve({ conflict: true });
                        }
                    );
                }else {
                    resolve({ error: true })
                }
            });
        };
    }
}
