import { Component, OnInit, ViewChild } from '@angular/core';
import { ThemeService } from '../../../service/theme.service';
import { ActivatedRoute } from '@angular/router';
import { ModalDirective } from 'ngx-bootstrap';
import * as _ from 'lodash';
import { UserService } from '../../../service/user.service';
import * as $ from 'jquery';

@Component({
    selector: 'app-theme-full',
    templateUrl: './theme-full.component.html',
    styleUrls: ['./theme-full.component.scss']
})
export class ThemeFullComponent implements OnInit {

    theme;
    currentVariable;
    @ViewChild('formModal', {static: false})
    public formModal:ModalDirective;

    constructor(private themeService: ThemeService,
                private route: ActivatedRoute,
                private userService: UserService
    ) {
    }

    ngOnInit() {
        this.route.params.subscribe(p => {
            if('id' in p){
                this.themeService.loadTheme(p['id']).subscribe(theme => {
                    this.theme = theme
                });
            }
        });

        this.userService.onPreviewRequest.subscribe(req => {
            let url = `${window.location.protocol}//preview.${window.location.hostname.replace('www.', '')}`;
            var win = window.open(url, '_blank');
            win.focus();
        });
    }


    openVariableFormModal(){
        this.formModal.show();
    }

    closeVariableFormModal(){
        this.currentVariable = undefined;
        this.formModal.hide();
    }

    editVariable(variable){
        this.currentVariable = variable;
        this.openVariableFormModal();
    }

    onVariable(variable){
        this.closeVariableFormModal();
        if(_.find(this.theme.variables, t => (<any>t).id == variable.id)){
            _.forEach(this.theme.variables, t => {
                if((<any>t).id == variable.id){
                    t = _.extend(t, variable);
                }
            });
        }else{
            this.theme.variables.push(variable);
        }
        this.updateCssTimestamp();
    }

    activateTheme(){
        this.themeService.activate(this.theme.id).subscribe(res => {
            this.theme.primaryTheme = true;
        });
    }

    previewTheme(){
        this.themeService.previewTheme(this.theme.id).subscribe(res => {

        });
    }

    private updateCssTimestamp() {
        let ts = new Date();
        $(document).find('link').each((l,e) => {
            if($(e).attr('href') == "/api/css"){
                $(e).attr('data-href', $(e).attr('href'));
            }
            if($(e).attr('data-href') == "/api/css") {
                $(e).attr('href', $(e).attr('data-href') +"?ts="+ts.getMilliseconds());
            }
        });
    }
}
