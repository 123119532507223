import { Component, OnInit, ViewContainerRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LanguageService } from '../../../service/language.service';
import * as _ from 'lodash';
import { TranslationKeyFormComponent } from '../translation-key-form/translation-key-form.component';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ModalService } from '../../../service/modal.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-language-full',
    templateUrl: './language-full.component.html',
    styleUrls: ['./language-full.component.scss']
})
export class LanguageFullComponent implements OnInit {

    language;
    translationKeys: Array<any>;
    translationKeysResult: Array<any>;
    searchForm: FormGroup = new FormGroup({
        search: new FormControl(null, [Validators.required])
    });

    constructor(private router: Router,
                private route: ActivatedRoute,
                private languageService: LanguageService,
                private vcr: ViewContainerRef,
                private modalService: ModalService,
                private ts: TranslateService) {
    }

    ngOnInit() {
        this.route.params.subscribe(p => {
             if('id' in p){
                 this.languageService.getLanguage(p['id']).subscribe((l:any) => {
                     this.language = l;
                     this.languageService.loadKeysFor(l.id).subscribe(k => {
                         this.translationKeys = k;
                         this.translationKeysResult = k;
                     });
                 });
             }
        });

        this.searchForm.valueChanges.subscribe(v => {
            this.search();
        });
    }

    onKey(key){
        let reference = _.find(this.translationKeys, k => k.id == key.id);
        if(!_.isUndefined(reference)){
            _.extend(reference, key);
        }else {
            this.translationKeys.push(key);
        }
        this.ts.reloadLang(this.ts.currentLang);
    }

    onEdit(key){

        var modal = this.modalService.openModal(TranslationKeyFormComponent, this.vcr);

        if(key){
            modal.instance.form.patchValue(key);
        }

        modal.instance["language"] = this.language;
        modal.instance.onClose.subscribe(key => {
            this.onKey(key);
            modal.destroy();
        });

        modal.instance.onDiscard.subscribe(() => {
            modal.destroy();
        });
    }

    search(){
        this.translationKeysResult = _.filter(this.translationKeys, (k:any) => {
            var regex = new RegExp(this.searchForm.value.search, 'ig');
            return k.key.match(regex) || k.value.match(regex);
        });
    }
}
