import { FormControl, FormGroup, Validators } from '@angular/forms';

export class Office {

    public static form(){
        return new FormGroup({
            id: new FormControl(),
            title: new FormControl('', [Validators.required]),
            description: new FormControl(''),
            allowedDomains: new FormControl(),
            adminEmail: new FormControl('', [Validators.required]),
            active: new FormControl(),
            logo: new FormControl(),
            image: new FormControl()
        });
    }
}
