import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { OfficeService } from '../../service/office.service';
import { FormGroup } from '@angular/forms';
import { SelectComponent } from 'ng2-select/index';
import { MoreValidators } from '../../validation/more-validators';
import { getPasswordStrength } from '../../shared/utils';

@Component({
    selector: 'app-step-one',
    templateUrl: './step-one.component.html',
    styleUrls: ['./step-one.component.scss']
})
export class StepOneComponent implements OnInit {

    offices = [];
    items = [];

    @Input('form')
    form: FormGroup;

    @ViewChild(SelectComponent, { static: false })
    select: SelectComponent;

    @Input('loading')
    loading;

    currentStrength = 0;

    constructor(private officeService: OfficeService,
        private moreValidators: MoreValidators) {
    }

    ngOnInit() {
        this.officeService.getOffices().subscribe(offices => {
            this.offices = <Array<any>>offices.entities;
            this.offices.forEach(item => {
                item.text = item.title;
                if (this.form.value.officeId && this.form.value.officeId == item.id) {
                    this.select.active = [item];
                }
            });
        });

        this.form.get('password').valueChanges.subscribe(
            () => this.currentStrength = getPasswordStrength(this.form.get('password').value)
        );
        
    }

    get classes() {
        return "strength-" + this.currentStrength;
    }

    typed(search) { }

    selected(value) {
        this.form.patchValue({ officeId: value.id });
    }

    refreshValue(value) {
    }

}
