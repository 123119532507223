import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Registration } from '../domain/registration';
import * as _ from 'lodash';
import { UserService } from '../service/user.service';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-registration',
    templateUrl: './registration.component.html',
    styleUrls: ['./registration.component.scss']
})
export class RegistrationComponent implements OnInit {

    step;
    totalSteps: number = 5;
    form = Registration.form();
    loading = false;

    constructor(private route: ActivatedRoute,
        private router: Router,
        private ts: TranslateService,
        private toaster: ToastrService,
        private userService: UserService) {

    }

    ngOnInit() {
        this.route.params.subscribe(p => {
            this.step = parseInt(p["step"]);
            if (!this.step) {
                this.route.url.subscribe(r => {                    
                    this.step = parseInt(r[0].path);
                });
            }
        });

        this.route.data.subscribe(d => {
        });
    }

    save() {
        this.loading = true;
        switch (parseInt(this.step)) {
            case 1:
                this.handleStep1();
                break;
            case 2:
                this.handleStep2();
                break;
            case 3:
                this.handleStep3();
                break;
            case 4:
                this.handleStep4();
                break;
            case 5:
                this.handleStep5();
                break;
            default:
                break;
        }
    }

    range(start, end) {
        return _.range(start, end);
    }

    private handleStep1() {
        this.userService.createRegistration(this.form.value.stepOne)
            .subscribe(success => {
                this.loading = false;
                this.router.navigate(['/registration/success']);
            }, error => {
                switch (parseInt(error.status)) {
                    case 400:
                        this.ts.get('toasts.usernameInUser').subscribe(ts => this.toaster.error(ts));
                        break;
                    case 409:
                        this.router.navigate(['/registration/invalid-domain']);
                        break;
                    default:
                        break;
                }
                this.loading = false;
            });
    }

    private handleStep2() {
        this.router.navigate(['/registration', ++this.step], { queryParams: this.form.value.stepTwo });
        this.loading = false;
    }

    private handleStep3() {
        this.userService.finishRegistration(this.form.value.stepThree).subscribe(token => {
            this.router.navigate(['/registration', ++this.step]);
        });
    }

    private handleStep4() {
        this.userService.save(_.extend(this.userService.u, this.form.value.stepFour)).subscribe(u => {
            this.router.navigate(['/registration', ++this.step]);
        });
    }

    private handleStep5() {
        this.userService.save(this.userService.u).subscribe(u => {
            this.router.navigate(['/']);
        });
    }
}
