import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild, ChangeDetectorRef } from '@angular/core';
import { DocumentService } from '../../service/document.service';
import { FormGroup, FormControl } from '@angular/forms';
import * as _ from 'lodash';
import { HostBinding } from '@angular/core';
import { NgSelectComponent } from '@ng-select/ng-select';
import { finalize } from 'rxjs/operators';

@Component({
    selector: 'app-document-selector',
    templateUrl: './document-selector.component.html',
    styleUrls: ['./document-selector.component.scss']
})
export class DocumentSelectorComponent implements OnInit, OnChanges {
    documentsPageable: any;

    @Input('form')
    form: FormGroup;

    @Input('multiple')
    multiple: boolean = false;

    @HostBinding('class.ng-select') default = true;

    @ViewChild(NgSelectComponent, { static: true })
    select: NgSelectComponent;

    @Output()
    onReady: EventEmitter<any> = new EventEmitter<any>();

    @Output()
    onDocumentsSelected: EventEmitter<any> = new EventEmitter();

    @Input()
    customClass: string;

    @Input()
    filteredUser = false;

    _isLoading: boolean = false;

    formPager: FormGroup = new FormGroup({
        page: new FormControl(1),
        q: new FormControl(""),
        sortOrder: new FormControl("{title: 1}"),
        isRoleFiltered: new FormControl(false),
        filteringByParent: new FormControl(false)
    });

    constructor(
        private documentService: DocumentService,
        private cdr: ChangeDetectorRef) {
    }

    get isLoading() {
        return this._isLoading;
    }


    ngOnInit() {
        this.load(true);
    }

    private load(isFirst = false, setValue = null) {
        this._isLoading = true;
        this.formPager.get('isRoleFiltered').setValue(this.filteredUser);
        this.documentService.loadDocuments('document', this.formPager.value)
            .pipe(
                finalize(() => {
                    this._isLoading = false;
                    this.cdr.detectChanges();
                } )
            ).subscribe((documents: any) => {
                if (this.formPager.get('page').value > 1) {
                    this.documentsPageable.entities = this.documentsPageable.entities.concat(documents.entities);
                } else {
                    this.documentsPageable = documents;
                }
                if (isFirst)
                    this.onReady.emit({});

                if (setValue) {
                    this.setValue(setValue);
                }
            });
    }

    onAdd() {
        if (this.formPager.get('q').value !== "")
            this.search("");
    }


    refreshValue(value) {
        if (value) {
            if (this.form) {
                if (this.multiple) {
                    this.form.patchValue({
                        documentIds: _.transform(value, (res, i) => {
                            res.push((<any>i).id);
                            return true;
                        }, [])
                    });
                } else {
                    this.form.patchValue({ documentId: value.id });
                }
            }
            if (this.multiple) {
                this.onDocumentsSelected.emit(this.documentsPageable.entities.filter(o => value.includes(o.id)));
            }
            else
                this.onDocumentsSelected.emit(this.documentsPageable.entities.find(o => o.id === value.id));

        }
        else {
            this.form.patchValue({ documentId: null });
            if (!this.form)
                this.onDocumentsSelected.emit(null);
        }
    }

    search(search) {
        this.formPager.patchValue({ page: 1 });
        this.formPager.patchValue({ q: search.term });
        this.load();
    }

    searchFn(term, item) {
        return true;
    }

    onScrollEnd() {
        if (!this.isLastPage()) {
            this.formPager.patchValue({ page: ++this.formPager.value.page });
            this.load();
        }
    }

    private isLastPage(): boolean {
        return (_.get(this.documentsPageable, "entities.length") == _.get(this.documentsPageable, "count"));
    }

    ngOnChanges(changes: SimpleChanges): void {
        if ('form' in changes) {
            this.clear();
        }
    }

    get customClasses() {
        return this.customClass + " ng-select";
    }

    clear() {
        this.select.writeValue(undefined);
    }

    private loadAndSetValue(value: any) {
        this.formPager.patchValue({ page: ++this.formPager.value.page });
        this.load(false, value);
    }

    setValue(value: any) {
        if (!value) {
            this.clear();
            return;
        }

        if (!this.multiple) {
            let data = _.find(this.documentsPageable.entities, o => (<any>o).id == value);

            if (!data && !this.isLastPage())
                return this.loadAndSetValue(value);
            else {
                if (data) {
                    if (this.form) {
                        this.form.patchValue({ documentId: data.id });
                    }
                    this.select.writeValue(data.id);
                    this.onDocumentsSelected.emit(data);
                }
                else {
                    this.select.writeValue(null);
                }
            }

        } else {
            if (!this.isLastPage())
                return this.loadAndSetValue(value);

            if (_.isArray(value)) {
                let data = _.filter(this.documentsPageable.entities, o => {
                    return value.indexOf((<any>o).id) != -1;
                });

                this.select.writeValue(_.map(data, d => d.id));
                this.onDocumentsSelected.emit(data);
            } else {
                this.clear();
            }
        }
    }
}
