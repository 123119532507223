import { ApplicationRef, ComponentFactoryResolver, Injectable, Type, ViewContainerRef } from '@angular/core';
import * as alertify from 'alertifyjs';
import { TranslateService } from '@ngx-translate/core';
import { Observable, throwError, zip } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { AppComponent } from '../app.component';

@Injectable()
export class ModalService {

    constructor(
        private componentFactoryResolver: ComponentFactoryResolver,
        private ts: TranslateService,
        private readonly appRef: ApplicationRef
    ) {
    }

    openModal<T>(component:Type<T>, viewContainerRef?: ViewContainerRef) {
        if (!viewContainerRef) {
            viewContainerRef = (this.appRef.components[0].instance as AppComponent).vcr;
        }
        const componentFactory = this.componentFactoryResolver.resolveComponentFactory(component);
        const componentRef = viewContainerRef.createComponent(componentFactory);
        return componentRef;
    }

    confirm(title: string, message: string, titleArgs?: any, messageArgs?: any){

        var subscription = zip(
            this.ts.get(title, titleArgs),
            this.ts.get(message, messageArgs)
        ).pipe(
            switchMap(([t, m]) => {
                return Observable.create((sub) => {
                    var alert = alertify.confirm(t, m, () => {
                        sub.next();
                    }, () => {
                        throwError("test");
                    }).set('movable', false);
        
                    this.getLabels().subscribe((labels) => alert.set('labels', labels).show());
        
                });
            })
        );

        return subscription;
    }

    private getLabels(){
        return zip(this.ts.get('confirm.ok'), this.ts.get('confirm.cancel'))
            .pipe(
                map(([ok, cancel]) => {
                    return {
                        ok: ok,
                        cancel: cancel
                    };
                })
            );
    }
}
