import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { PublicDataService } from '../../service/public-data.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-privacy-type-selector',
  templateUrl: './privacy-type-selector.component.html',
  styleUrls: ['./privacy-type-selector.component.scss']
})
export class PrivacyTypeSelectorComponent implements OnInit, OnChanges {

    items = [];
    @Input('privacy')
    privacy;
    @Input('groupType')
    groupType;
    @Input()
    switch: boolean;
    @Output()
    onItem: EventEmitter<any> = new EventEmitter<any>();

    constructor(private publicDataService: PublicDataService, private ts: TranslateService) {
    }

    ngOnInit() {
        this.publicDataService.getPrivacyTypes().subscribe(g => {
            this.items = g;
            if(this.switch){
                this.items.reverse();
            }
        });
    }

    ngOnChanges(changes:SimpleChanges):void {

    }

    onItemSelected(){
        this.onItem.emit({id: this.privacy, text: this.privacy});
    }
}
