import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { UserService } from '../service/user.service';
import { ForumService } from '../service/forum.service';
import { FormControl, FormGroup } from '@angular/forms';
import { ForumPost } from '../domain/forum-post';
import * as _ from 'lodash';

@Component({
  selector: 'app-forum',
  templateUrl: './forum.component.html',
  styleUrls: ['./forum.component.scss']
})
export class ForumComponent implements OnInit {

  posts: any;

  form = ForumPost.form();
  searchSubscription

  isShowContent = true;

  formPager: FormGroup = new FormGroup({
    page: new FormControl(1),
    sortOrder: new FormControl("{ created: -1 }")
  });
  
  constructor(
    private userService: UserService,
    private forumService: ForumService
  ) { }

  ngOnInit() {
    this.formPager.valueChanges.subscribe(v => {
      this.load()
    });

    this.load();
  }

  private load() {
    this.forumService.getPosts(this.formPager.value).subscribe(
      (posts) => {
        if (this.formPager.get('page').value > 1) {
          this.posts.entities = this.posts.entities.concat(posts.entities);
        } else {
          this.posts = posts;
        }
      }
    );
  }

  onAdd() {
      if (!this.form.valid) {
          return;
      }
    let data = this.form.value;
    this.forumService.savePost(data).subscribe(
      () => {
        this.load();
        this.form = ForumPost.form();
      }
    );
  }

  onScroll() {
    if (_.get(this.posts, "entities.length") != _.get(this.posts, "count"))
      this.formPager.patchValue({ page: ++this.formPager.value.page });
  }

  onDelete(post) {
    this.forumService.deletePost(post.id).subscribe(
      () => {
        _.remove(this.posts.entities, (p: any) => p.id === post.id);
        this.posts.count--;
      }
    );
  }
}
