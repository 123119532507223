import { Component, OnInit, ChangeDetectionStrategy, Input, Output, EventEmitter, HostBinding } from '@angular/core';

@Component({
  selector: 'app-edit-button',
  templateUrl: './edit-button.component.html',
  styleUrls: ['./edit-button.component.scss']
})
export class EditButtonComponent implements OnInit {
  
  @Input()
  height;

  @Input()
  width;

  @Input()
  colorClass: string;

  @Input()
  text: string = "";

  @Output()
  edit = new EventEmitter<void>();

  @HostBinding('class.text-button')
  isTextButton: boolean = false;

  constructor() { }

  ngOnInit() {
    this.isTextButton = (this.text !== "");
  }

  buildStyles() {
    return {
      'height.px': this.height,
      'width.px': this.width
    };
  }

  onEdit() {
    this.edit.emit();
  }

}
