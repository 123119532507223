import { Component, OnInit } from '@angular/core';
import { UserService } from '../service/user.service';

@Component({
  selector: 'admin-bar',
  templateUrl: './admin-bar.component.html',
  styleUrls: ['./admin-bar.component.scss']
})
export class AdminBarComponent implements OnInit {

    user;

    constructor(private userService: UserService) { }

    userHasRole(role){
        return this.user && this.user.roles.indexOf(role) > -1;
    }

    logout(){
        this.userService.logout();
    }

    ngOnInit() {
        this.userService.userObservable.subscribe(u => {
            this.user = u;
        });
    }
}
