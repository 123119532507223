import { ChangeDetectionStrategy, Component, Inject, Input } from '@angular/core';
import { Attachment } from '../../model/attachment';
import { CHAT_SERVICE_CONFIG, IChatServiceConfiguration } from '../../interfaces/chat-service-configuration.interface';

const IMAGE_CONTENT_TYPES = ['image/jpg', 'image/jpeg', 'image/png', 'image/gif', 'image/webp'];

@Component({
    selector: 'app-chat-message-attachment',
    templateUrl: './chat-message-attachment.component.html',
    styleUrls: ['./chat-message-attachment.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChatMessageAttachmentComponent {
    
    @Input()
    public attachment: Attachment;
    
    constructor(
        @Inject(CHAT_SERVICE_CONFIG) private readonly chatServiceConfig: IChatServiceConfiguration
    ) {}
    
    public ngOnInit() {
    }
    
    public isImage() {
        return IMAGE_CONTENT_TYPES.includes(this.attachment.contentType);
    }
    
    public resolveUrl() {
        return `${this.chatServiceConfig.messageAttachmentBase}/${this.attachment.id}`;
    }
}
