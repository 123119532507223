import { Component, Input, OnInit } from '@angular/core';
import { UserService } from '../../service/user.service';
import * as _ from 'lodash';

@Component({
    selector: '[acl]',
    templateUrl: './acl.component.html',
    styleUrls: ['./acl.component.scss']
})
export class AclComponent implements OnInit {

    @Input('acl')
    role;

    @Input('passThrough')
    passThrough;
    private me;

    constructor(private userService: UserService) {

    }

    ngOnInit():void {
        this.me = this.userService.u;
        this.userService.userObservable.subscribe(u => {
            this.me = u;
        });
    }

    isAllowed(){

        if(this.passThrough){
            return this.passThrough;
        }

        return _.reduce(this.role, (result: boolean, v: any) => {
            if(_.isArray(_.get(this, 'me.roles'))){
                if((<Array<any>>_.get(this, 'me.roles')).includes(v)){
                    result = true;
                }
            }
            return result;
        }, false);
    }

}
