import { Component, Input } from '@angular/core';
import { FeedItemComponent } from '../feed-item.component';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../../../environments/environment';

@Component({
    selector: 'app-group-feed-item',
    templateUrl: './group-feed-item.component.html',
    styleUrls: ['./group-feed-item.component.scss']
})
export class GroupFeedItemComponent extends FeedItemComponent {
    
    @Input('ngClass')
    ngClass;

    constructor(private ts: TranslateService) {
        super();
    }

    ngOnInit() {
    }

    isJoinEvent(){
        return this.item.eventTranslationKey.match(/join/);
    }

    get envApi() {
        return environment.api;
    }
}
