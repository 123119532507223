import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Settings } from '../app.settings';

@Injectable({
  providedIn: 'root'
})
export class SendEmailService {
  constructor(
    private http: HttpClient
  ) { }

  sendEmail(value: any) {
    let formData = new FormData();

    for (let key in value) {
        formData.append(key, value[key]);
    }

    return this.http.post(Settings.SEND_EMAIL, formData);
  }
}
