import { Component, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DocumentService } from '../../service/document.service';
import * as _ from 'lodash';
import { FormControl, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { SkillSelectorComponent } from '../../shared/skill-selector/skill-selector.component';
import { ModalService } from '../../service/modal.service';
import { UserService } from '../../service/user.service';
import { DocumentFormComponent } from './document-form/document-form.component';
import { FolderCreateOrEditModalComponent } from './folder-create-or-edit-modal/folder-create-or-edit-modal.component';

@Component({
    selector: 'app-document',
    templateUrl: './document.component.html',
    styleUrls: ['./document.component.scss']
})
export class DocumentComponent implements OnInit {
    documentType;

    documents;

    @ViewChild(SkillSelectorComponent, {static: true})
    skillSelector:SkillSelectorComponent;

    form: FormGroup = new FormGroup({
        page: new FormControl(1),
        q: new FormControl(),
        skillId: new FormControl(),
        property: new FormControl("title"),
        order: new FormControl("1"),
        creator: new FormControl('')
    });

    me;

    public contributors: any[] = [];

    constructor(
        private route: ActivatedRoute,
        private userService: UserService,
        private documentService: DocumentService,
        private modalService: ModalService,
        private vcr: ViewContainerRef,
        private ts: TranslateService,
        private toaster: ToastrService
    ) {
    }

    ngOnInit() {
        this.me = this.userService.u;
        this.userService.userObservable.subscribe(u => this.me = u);

        this.documentService.loadContributors().subscribe((contributors: any[]) => this.contributors = contributors);
        this.route.params.subscribe(p => {
            if ('documentType' in p) {
                this.documentType = p['documentType'];
                this.loadDocuments();

                this.form.get('q').valueChanges.subscribe((v:any) => {
                    this.documents = undefined;
                    this.form.get('page').setValue(1, { emitEvent: false });
                    this.loadDocuments();
                });

                this.form.get('page').valueChanges.subscribe(v => {
                    this.loadDocuments();
                });

                this.form.get('creator').valueChanges.subscribe(v => {
                    this.documents = undefined;
                    this.form.get('page').setValue(1, { emitEvent: false });
                    this.loadDocuments();
                });

                this.form.get('property').valueChanges.subscribe(v => {
                    this.documents = undefined;
                    this.form.get('page').setValue(1, { emitEvent: false });
                    if (v == 'title') {
                        this.form.get('order').setValue(1, { emitEvent: false });
                    } else {
                        this.form.get('order').setValue(-1, { emitEvent: false });
                    }
                    this.loadDocuments();
                });
            }
        });
    }

    onMedia(media) {
        if (_.find(this.documents.entities, e => (<any>e).id == media.id)) {
            _.forEach(this.documents.entities, e => {
                if(e.id == media.id){
                    e = _.extend(e, media);
                }
            });
        } else {
            this.documents.entities.unshift(media);
        }
    }

    createOrEdit(document) {
        const modal = this.modalService.openModal(DocumentFormComponent, this.vcr);

        if (document) {
            modal.instance.form.patchValue(document);
        }

        modal.instance.onClose.subscribe((result) => {
            if (document) {
                this.documents.entities
                    .filter(item => item.id == result.id)
                    .map(item => _.extend(item, result));
            } else {
                this.documents.entities.unshift(result);
                ++this.documents.count;

                this.documentService
                    .loadDocuments(this.documentType, this.form.value)
                    .subscribe(d => {
                        this.documents = d;
                    });
            }

            modal.destroy();
        });

        modal.instance.onDiscard.subscribe(() => modal.destroy());
    }

    createOrEditFolder(folder) {
        const modal = this.modalService.openModal(FolderCreateOrEditModalComponent, this.vcr);

        if (folder) {
            modal.instance.form.patchValue(folder);
        }

        modal.instance.onClose.subscribe((result) => {
            if (folder) {
                this.documents.entities
                    .filter(item => item.id == result.id)
                    .map(item => _.extend(item, result));
            } else {
                this.documents.entities.unshift(result);
                ++this.documents.count;
            }

            modal.destroy();
        });

        modal.instance.onDiscard.subscribe(() => modal.destroy());
    }

    removeAll() {
        this.modalService.confirm('document.remove.all.title', 'document.remove.all.message')
            .subscribe(() => {
                this.documentService.removeAll(this.form.value)
                    .subscribe(() => {
                        this.documentService
                            .loadDocuments(this.documentType, this.form.value)
                            .subscribe(d => {
                                this.documents = d;
                            });
            
                        this.ts.get(`toasts.successfullSelfUpdate`).subscribe(ts => this.toaster.success(ts));
                    });
            });
    }

    onScroll() {
        this.form.patchValue({ page: ++this.form.value.page });
    }

    private loadDocuments() {
        this.documentService.loadDocuments(this.documentType, this.form.value)
            .subscribe(d => {
                if (! this.documents) {
                    this.documents = d;
                } else {
                    this.documents.entities = this.documents.entities.concat((<any>d).entities);
                }
            });
    }

    onSort(props) { 
        _.extend(props, {page: 1});
        
        this.form.patchValue(props, {emitEvent: false});
        this.documents = undefined;

        this.loadDocuments();
    }

    onSkillSelected(skill) {
        this.skillSelector.setValue(skill);
        this.form.patchValue({skillId: skill.id});
    }

    onAdd() {
        this.documents = undefined;
        this.loadDocuments();
    }

    isAdmin() {
        return this.me && _.find(this.me.roles, r => r == 'ADMIN');
    }

    canCreateFolder() {
        return this.me && _.find(this.me.roles, r => (r == 'ADMIN' || r == 'SUB_ADMIN'));
    }

    canEdit() {
        return (this.me && _.find(this.me.roles, r => (r == 'ADMIN' || r == 'SUB_ADMIN')) || this.isSelf());
    }

    isSelf() {
        return _.get(this, 'me.id') == _.get(this, 'document.creator');
    }
}
