import { Component, Input, OnInit } from '@angular/core';
import { GroupService } from '../../../service/group.service';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'app-user-group-list',
    templateUrl: './user-group-list.component.html',
    styleUrls: ['./user-group-list.component.scss']
})
export class UserGroupListComponent implements OnInit {

    @Input()
    title;
    private page: number = 1;
    pageable;
    private userId;

    constructor(private groupService: GroupService, private route: ActivatedRoute) {}

    ngOnInit() {
        this.route.params.subscribe(p => {
            if('id' in p){
                this.userId = p["id"];
                this.loadGroups();
            }
        });
    }

    joinGroup(group){
        if(!group.joined){
            this.groupService.addToGroup(group.id).subscribe(subscription => {
                group.joined = true;
            });
        }else {
            this.groupService.removeFromGroup(group.id).subscribe(subscription => {
                group.joined = false;
            });
        }
    }

    private loadGroups() {
        this.groupService.loadGroupsForUser(this.userId, this.page).subscribe(g => {
            this.pageable = g;
        });
    }
}
