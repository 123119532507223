import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ThemeService } from '../../../service/theme.service';

@Component({
    selector: 'app-theme-item',
    templateUrl: './theme-item.component.html',
    styleUrls: ['./theme-item.component.scss']
})
export class ThemeItemComponent implements OnInit {

    @Input()
    theme;
    @Input()
    themes;
    @Output()
    onEdit:EventEmitter<any> = new EventEmitter<any>();

    constructor(private themeService: ThemeService) {
    }

    ngOnInit() {
    }

    edit(){
        this.onEdit.emit(this.theme);
    }

}
