import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { GroupService } from '../../../service/group.service';
import { FormGroup } from '@angular/forms';
import { Group } from '../../../domain/group';
import { ToastrService } from 'ngx-toastr';
import { UserService } from '../../../service/user.service';
import * as _ from 'lodash';
import { UserSelectorComponent } from '../../../shared/user-selector/user-selector.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-group-form',
    templateUrl: './group-form.component.html',
    styleUrls: ['./group-form.component.scss']
})
export class GroupFormComponent implements OnInit, OnChanges {

    form: FormGroup;
    @Input('groupType')
    groupType: string;
    private user;
    @Output()
    onGroupEvent: EventEmitter<any> = new EventEmitter<any>();
    @ViewChild(UserSelectorComponent, { static: false })
    userSelector: UserSelectorComponent;

    isShowContent = true;

    constructor(private groupService: GroupService,
        private userService: UserService,
        private ts: TranslateService,
        private toaster: ToastrService) {
    }

    ngOnChanges(changes: SimpleChanges): void {
        if ('groupType' in changes) {
            this.groupType = changes['groupType'].currentValue;
            this.form = Group.form(this.groupType.toUpperCase());
        }
    }

    ngOnInit() {
        if (this.groupType) {
            this.form = Group.form(this.groupType.toUpperCase());
        }
        this.user = this.userService.u;
        this.userService.userObservable.subscribe(u => {
            this.user = u;
        });

        this.onShowContent();
    }

    save() {
        this.groupService.save(this.form.value).subscribe(f => {
            this.ts.get(this.form.value.id ? `toasts.${f.groupType.toLocaleLowerCase()}.successfullUpdate` : `toasts.${f.groupType.toLocaleLowerCase()}.successfullCreation`).subscribe(ts => {
                this.toaster.success(ts);
                this.onGroupEvent.emit(f);
                this.form = Group.form(this.groupType.toUpperCase());
                if (this.userSelector) {
                    this.userSelector.clear();
                }
            });
        });
    }

    isAdmin() {
        return this.user && _.find(this.user.roles, r => r == 'ADMIN');
    }

    patchMembers(members) {        
        this.form.patchValue({ members: members });
    }

    onShowContent() {
        if (window.innerWidth < 960)
            this.isShowContent = !this.isShowContent;
    }

    isModerator(): boolean {
        return (_.find(this.userService.u.roles, r => r == 'MODERATOR') !== undefined);
    }
}
