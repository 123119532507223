import { FormControl, FormGroup, Validators } from '@angular/forms';

export class Group {

    static form(groupType: string) {
        return new FormGroup({
            id: new FormControl(),
            groupType: new FormControl(groupType, [Validators.required]),
            privacy: new FormControl(groupType == 'GROUP' ? 'PRIVATE' : 'PUBLIC', [Validators.required]),
            title: new FormControl('', [Validators.required]),
            publicationDate: new FormControl(),
            description: new FormControl(),
            primary: new FormControl(),
            officeIds: new FormControl(),
            members: new FormControl(),
            active: new FormControl(true)
        });
    }
}
