import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { GroupService } from '../../../service/group.service';
import * as _ from 'lodash';
import { FormGroup } from '@angular/forms';
import { Group } from '../../../domain/group';
import { Utils } from '../../../shared/utils';
import { ModalService } from '../../../service/modal.service';
import { environment } from '../../../../environments/environment';
import { Router } from '@angular/router';

@Component({
    selector: 'app-group-item',
    templateUrl: './group-item.component.html',
    styleUrls: ['./group-item.component.scss']
})
export class GroupItemComponent implements OnInit {

    @Input()
    group;
    @Input()
    user;
    
    @Output()
    onGroupDeleted: EventEmitter<any> = new EventEmitter();
    timestamp;


    constructor(
        private groupService: GroupService, 
        private modalService: ModalService,
        private router: Router) {
    }

    ngOnInit() {
    }

    getCreatorLabel(){
        return this.group.groupType == "GROUP" ? 'group.createdBy' : 'discussion.createdBy';
    }

    isMember() {
        return this.group && this.group.joined;
    }

    joinGroup(groupId){
        if(!this.isMember()){
            this.groupService.addToGroup(groupId).subscribe(subscription => {
                this.group.joined = true;
            });
        }else {
            this.groupService.removeFromGroup(groupId).subscribe(subscription => {
                this.group.joined = false;
            });
        }
    }

    isAdmin(){
        try {
            return _.find(<any>(_.get(this, 'user.roles') || []), r => (r == 'ADMIN' || r == 'MODERATOR'));
        } catch (err) {
            return false;
        }
    }

    isCreator(){
        return _.get(this, 'group.creator') == _.get(this, 'user.id');
    }

    get envApi() {
        return environment.api;
    }

    enableDisableGroup(){
        // if(!this.edition){
            let g = _.clone(this.group);
            g.active = !g.active;
            this.groupService.save(g).subscribe(r => {
                this.group.active = g.active;
            });
        // }else{
        //     this.form.patchValue({ active: !this.form.value.active });
        // }
    }

    // editGroup(){
    //     if(!this.edition){
    //         this.form.patchValue(this.group);
    //         this.edition = true;
    //     }else{
    //         this.edition = false;
    //     }
    // }

    // save(){
    //     if(!this.edition){
    //         return;
    //     }
    //     this.groupService.save(this.form.value).subscribe(group =>{
    //         _.extend(this.group, group);
    //         this.edition = false;
    //     });
    // }

    // onFileSelectedEvent(e:any){
    //     this.onFileSelected(e.target.files);
    // }

    // onFileSelected(fileList: any){
    //     if(fileList.length > 0 && Utils.isImage(fileList.item(0))){
    //         this.groupService.uploadGroupImage(this.group.id, fileList.item(0)).subscribe((g : any) => {
    //             this.group.picture = g.picture;
    //             this.timestamp = new Date().getTime();
    //         });
    //     }
    // }

    getRouterLink() {
        return ['/social/' + this.group.groupType.toLowerCase() + '/view/' + this.group.id];
    }

    navigateEdit() {        
            this.router.navigateByUrl('/social/' + this.group.groupType.toLowerCase() + '/view/' + this.group.id, { state: { edit: true } });        
    }

    delete(){
        this.modalService.confirm(this.group.groupType.toLowerCase() + ".deletion.title", this.group.groupType.toLowerCase() + ".deletion.message")
            .subscribe(() => {
                this.groupService.deleteGroup(this.group.id)
                    .subscribe(() => {
                        this.onGroupDeleted.emit(this.group);
                    });
            });
    }

    // patchMembers(members){
    //     this.form.patchValue({ members: members });
    // }
}
