import { FormControl, FormGroup, Validators } from '@angular/forms';

export class Theme {

    static form(){
        return new FormGroup({
            id: new FormControl(),
            name: new FormControl('', [Validators.required])
        });
    }
}
