import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { UserService } from '../../../../service/user.service';


@Component({
  selector: 'app-chat-form-search-user',
  templateUrl: './chat-form-search-user.component.html',
  styleUrls: ['./chat-form-search-user.component.scss']
})
export class ChatFormSearchUserComponent implements OnInit {

  loading = false;
  focus = false;
  forceOpen = false;

  form: FormGroup = new FormGroup({
    q: new FormControl('', Validators.required),
    page: new FormControl(1)
  });

  @Input()
  currentUsers: any[] = [];

  @Output()
  selectUser = new EventEmitter<any>();

  searchResult;

  constructor(
    private userService: UserService,
    private cdr: ChangeDetectorRef
  ) { }

  ngOnInit() {

    this.form.valueChanges.subscribe(
      () => this.loadUsers()
    );
  }

  private loadUsers() {
    this.loading = true;
    this.userService.search(this.form.get('q').value).subscribe(
      (users) => {
        this.searchResult = users;
        this.loading = false;
        this.cdr.detectChanges();
      }
    )
  }

  onSelectUser(user) {
    this.searchResult = null;
    this.form.get('q').setValue("", {emitEvent: false});
    this.cdr.detectChanges();
    this.selectUser.emit(user);
  }

  onFocus() {
    this.focus = true;
  }

  onBlur() {
    setTimeout(() => {
      this.focus = false;
      if (this.form.get('q').value === "")
        this.searchResult = null;
        
      this.cdr.detectChanges();
    }, 400);
  }

  get hasResults() {
    return (this.searchResult !== undefined && this.searchResult !== null);
  }

  get hasSearchResults() {
    return (this.searchResult && this.searchResult.length > 0);
  }

}
