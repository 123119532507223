import { Pipe, PipeTransform } from '@angular/core';
import * as linkifyStr from 'linkifyjs/string';

@Pipe({name: 'linkify'})
export class LinkifyPipe implements PipeTransform {
    transform(str:string):string {
        return str ? this.networkShortCode(linkifyStr(
            str,
            {
                target: '_blank'
            }
            )) : str;
    }

    private networkShortCode(linkifyStr2) {
        const myRegexp = /\[([^\]]*)\]/g;
        let match = myRegexp.exec(linkifyStr2);

        while(match != null) {
            linkifyStr2 = linkifyStr2.replace(match[0], `<a href="${match[1]}" target="_blank">${match[1]}</a>`)
            match = myRegexp.exec(linkifyStr2);
        }

        return linkifyStr2;
    }
}
