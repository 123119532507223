import { Component, OnInit, ViewContainerRef } from '@angular/core';
import { LanguageService } from '../../service/language.service';
import { ModalService } from '../../service/modal.service';
import { LanguageFormComponent } from './language-form/language-form.component';

@Component({
    selector: 'app-language',
    templateUrl: './language.component.html',
    styleUrls: ['./language.component.scss']
})
export class LanguageComponent implements OnInit {

    languages;

    constructor(
        private languageService: LanguageService,
        private modalService: ModalService,
        private vcr: ViewContainerRef
        ) {
    }

    ngOnInit() {
        this.languageService.getLanguages().subscribe(l => this.languages = l);
    }

    onCreate() {
        var modal = this.modalService.openModal(LanguageFormComponent, this.vcr);        
        
        modal.instance.onClose.subscribe(l => {  
            this.onLanguage(l);            
            modal.destroy();
        });

        modal.instance.onDiscard.subscribe(() => {
            modal.destroy();
        });       
    }

    onLanguage(l) {        
        const i = this.languages.findIndex(lg => lg.id === l.id);
        if (i > -1)
            this.languages[i] = l;
        else
            this.languages.push(l);

    }

}
