import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import * as _ from 'lodash';
import { environment } from '../../../../environments/environment';
import { Comment } from '../../../domain/comment';
import { Topic } from '../../../domain/topic';
import { GroupService } from '../../../service/group.service';
import { ModalService } from '../../../service/modal.service';
import { UserService } from '../../../service/user.service';

const IMAGE_CONTENT_TYPES = ['image/jpg', 'image/jpeg', 'image/png', 'image/gif', 'image/webp'];

@Component({
    selector: 'app-topic-item',
    templateUrl: './topic-item.component.html',
    styleUrls: ['./topic-item.component.scss']
})
export class TopicItemComponent implements OnInit {

    @Input('topic')
    topic;

    @Input('group')
    group;

    @Input()
    showCommentDefault = false;

    form: FormGroup = Comment.form();
    commentForm: FormGroup = Comment.form();
    topicForm: FormGroup = Topic.form();

    me: any;

    _isEdition = false;
    _isEditionComment = false;

    isShowComments = false;


    @Output()
    onTopicDeleted: EventEmitter<any> = new EventEmitter();

    @Output()
    onTopicCommentWithFile: EventEmitter<void> = new EventEmitter();

    constructor(private groupService: GroupService,
        private userService: UserService,
        private modalService: ModalService) {
    }

    ngOnInit() {
        this.me = this.userService.userObservable;
        this.userService.updateReadStatus(this.topic.id).subscribe(s => {
        });

        this.userService.onSocketEvent.subscribe(data => {
            if (data.messageType == 'comment' && data.topicId == this.topic.id) {
                this.onComment(data);
            }
        });

        this.isShowComments = this.showCommentDefault;
    }

    onComment(comment) {
        if (_.filter(this.topic.comments, (e: any) => e.id == comment.id).length == 0) {
            this.topic.comments.push(comment);
            if (comment.files.length > 0)
                this.onTopicCommentWithFile.emit();
        }
    }

    joinGroup(groupId) {
        if (!this.isMember()) {
            this.groupService.addToGroup(groupId).subscribe(subscription => {
                this.group.joined = true;
            });
        } else {
            this.groupService.removeFromGroup(groupId).subscribe(subscription => {
                this.group.joined = false;
            });
        }
    }

    isMember() {
        return this.group && this.group.joined;
    }

    editTopic() {
        this.topicForm.patchValue(this.topic);
        this._isEdition = true;
    }

    editComment(comment) {
        this.commentForm.patchValue(comment);
        this._isEditionComment = true;
    }

    get isEdition() {
        return this._isEdition;
    }

    isEditionComment(comment) {
        return (this._isEditionComment && (comment.id === this.commentForm.value.id));
    }

    hasFiles(comment) {
        return comment.files.length > 0;
    }
    
    public isImage(attachment) {
        return IMAGE_CONTENT_TYPES.includes(attachment.contentType);
    }

    isAdmin() {
        return _.find(<any>(_.get(this, 'userService.u.roles') || []), r => (r == 'ADMIN' || r == 'MODERATOR'));
    }

    isSelf() {
        return _.get(this, 'userService.u.id') == _.get(this.topic, 'creator');
    }

    saveTopic() {
        this.groupService.updateTopic(this.topicForm.value).subscribe((t: any) => {
            this.topic.subject = t.subject;
            this.topic.content = t.content;
            this._isEdition = false;
        });
    }

    saveComment(comment) {
        this.groupService.updateComment(this.commentForm.value).subscribe((c: any) => {
            comment.content = c.content;
            this._isEditionComment = false;
        });
    }

    delete() {
        this.modalService.confirm('topic.deletion.confirm.title', 'topic.deletion.confirm.message')
            .subscribe(() => {
                this.groupService.deleteTopic(this.topic.id).subscribe(() => {
                    this.onTopicDeleted.emit(this.topic);
                });
            });
    }

    deleteComment(comment) {
        this.modalService.confirm('topic.deletion.comment.confirm.title', 'topic.deletion.comment.confirm.message')
            .subscribe(() => {
                this.groupService.deleteComment(comment.id).subscribe(() => {
                    _.remove(this.topic.comments, (c: any) => c.id === comment.id)
                });
            });
    }

    onAddComment() {
        if (!this.form.valid) {
            return;
        }
        this.groupService.createComment(this.topic.id, this.form.value).subscribe(
            (c) => {
                this.onComment(c);
                this.form.patchValue({ content: '', file: undefined });
            }
        );
    }

    isCreatorComment(comment) {
        return _.get(this, 'userService.u.id') == _.get(comment, 'creator');

    }

    removeAttachment() {
        this.commentForm.patchValue({ file: undefined });
    }

    toggleLike() {
        let obs;
        if (this.topic.liking)
            obs = this.groupService.unlikeTopic(this.topic)
        else
            obs = this.groupService.likeTopic(this.topic);

        obs.subscribe(
            (topic) => {
                this.topic.liking = topic.liking;
                this.topic.liked = topic.liked;
            }
        );
    }

    onFileSelected(e) {
        const files = _.get(e, 'target.files');
        if (!_.isUndefined(files) && files.length > 0) {
            this.form.patchValue({ file: files[0] });
        } else {
            this.form.patchValue({ file: undefined });
        }
    }

    get fileLabel() {
        if (this.form.value.file === undefined || this.form.value.file === null)
            return "";
        else
            return this.form.value.file.name;
    }

    get envApi() {
        return environment.api;
    }
}


