import { Component, OnInit, HostBinding, HostListener, ElementRef } from '@angular/core';
import { UserService } from '../../service/user.service';
import { LinkGroupService } from '../../service/link-group.service';
import * as _ from 'lodash';
import { Router } from '@angular/router';
import { NavBarService } from '../../service/nav-bar.service';

@Component({
    selector: 'app-nav',
    templateUrl: './nav.component.html',
    styleUrls: ['./nav.component.scss']
})
export class NavComponent implements OnInit {

    @HostBinding('class.is-open')
    isOpen: boolean;

    @HostListener('document:click', ['$event.target'])
    public onClick(targetElement) {
        const clickedInside = this.elementRef.nativeElement.contains(targetElement);
        if (!clickedInside && this.isOpen && !this.isOpening)
          this.navbarService.toggleNav(false);
    }

    isOpening = false;
    
    constructor(
        private readonly userService: UserService,
        private readonly linkGroupService: LinkGroupService,
        private readonly router: Router,
        private navbarService: NavBarService,
        private elementRef : ElementRef,
    ) {
    }

    ngOnInit() {
        this.navbarService.isOpenChange.subscribe(
            (isOpen) => {
              this.isOpen = isOpen;        
              if (this.isOpen) {
                this.isOpening = true;
                setTimeout(() => this.isOpening = false, 500);
              }
            }
          );
    }

    isAdmin(){
        return _.find(<Array<any>>_.get(this, 'user.roles'), r => (r == 'ADMIN' || r == 'SUB_ADMIN'));
    }

    hasLinkGroups() {
        return this.linkGroupService.groups.length > 0;
    }
    
    loadMyProfile() {
        this.router.navigateByUrl(`/social/directory/${this.userService.u.id}`);
    }

    close(){
        this.navbarService.toggleNav(false);
    }

    onLogout() {
        this.userService.logout();        
    }
}
