import { FormGroup, FormControl, Validators } from "@angular/forms";

export class NewsletterParams {
    static form(){
        return new FormGroup({
            id: new FormControl(null, Validators.required),
            firstExecutiondayOfWeek: new FormControl('', [Validators.required]),
            firstExecutiontime: new FormControl('', [Validators.required]),
            secondExecutiondayOfWeek: new FormControl('', [Validators.required]),
            secondExecutiontime: new FormControl('', [Validators.required])           
        });
    }
}
