import { FormGroup, FormControl, Validators } from "@angular/forms";

export class ForumComment {

    static form() {
        return new FormGroup({      
            id: new FormControl(null),               
            content: new FormControl(),
            postId: new FormControl(),
            active: new FormControl(true),
            file: new FormControl()
        });
    }
}