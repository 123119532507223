import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Variable } from '../../../domain/variable';
import { ThemeService } from '../../../service/theme.service';
import * as _ from 'lodash';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-variable-form',
    templateUrl: './variable-form.component.html',
    styleUrls: ['./variable-form.component.scss']
})
export class VariableFormComponent implements OnInit, OnChanges {

    form: FormGroup = Variable.form();
    @Input()
    variable;
    @Input()
    themeId;
    @Input()
    variables;
    @Output()
    onVariable: EventEmitter<any> = new EventEmitter<any>();
    defaultVariables = [];

    constructor(private themeService: ThemeService, private ts: TranslateService) {
    }

    ngOnInit() {
        this.themeService.loadDefaultVariables().subscribe(v => {
            this.defaultVariables = v;
            this.defaultVariables.forEach(variable => {
                this.ts.get(variable.description).subscribe(ts => variable.description = ts);
            });
        });
    }

    ngOnChanges(changes:SimpleChanges):void {
        let variable: any = _.get(changes, 'variable.currentValue');
        if(!variable){
            this.form = Variable.form();
            this.form.patchValue({ position: this.variables.length });
        }else{
            this.form.patchValue(variable);
            if(variable.mandatory){
                this.form.get('name').disable({onlySelf: true});
            }
            if(variable.unMutable){
                this.form.get('value').disable({onlySelf: true});
            }
        }
    }

    save(){
        this.themeService.saveVariable(this.themeId, this.form.getRawValue())
            .subscribe(v => this.onVariable.emit(v));
    }

    variableListFormatter(data: any){
        return data.description;
    }

    onVariableSelected(data){
        this.form.patchValue({description: data.description, value: data.value, name: data.name});
    }

    variableValueFormatter(data){
        return data.name;
    }
}
