import { Component, Input, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import * as _ from 'lodash';
import { OfficeSelectorComponent } from '../office-selector/office-selector.component';

@Component({
    selector: 'app-function-in-office-selector',
    templateUrl: './function-in-office-selector.component.html',
    styleUrls: ['./function-in-office-selector.component.scss']
})
export class FunctionInOfficeSelectorComponent implements OnInit, AfterViewInit {

    @Input()
    form: FormGroup;

    @Input()
    skills;

    @Input()
    index;

    selectedOfficeId = null;

    @ViewChild(OfficeSelectorComponent, { static: false })
    officeSelector: OfficeSelectorComponent;

    constructor() {
    }

    ngOnInit() {
        this.form.get("officeId").valueChanges.subscribe(v => {            
            if (this.selectedOfficeId !== this.form.get("officeId").value) {                
                this.form.get('delegationId').setValue(null);
                this.selectedOfficeId = this.form.get("officeId").value;
            }
        });
    }

    ngAfterViewInit() {
        this.officeSelector.onReady.subscribe(() => {
            this.selectedOfficeId = this.form.get("officeId").value;
            this.officeSelector.setValue(this.form.get("officeId").value);
        })
    }

    deleteRow() {
        (<FormArray>this.form.parent).removeAt(this.index);
    }

    deletable() {
        return (<FormArray>this.form.parent).length > 1;
    }

}
