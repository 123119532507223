import { Component, Input, OnInit } from '@angular/core';
import * as _ from 'lodash';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-group-list',
    templateUrl: './group-list.component.html',
    styleUrls: ['./group-list.component.scss']
})
export class GroupListComponent implements OnInit {

    @Input("groups")
    groups;
    @Input("user")
    user;

    constructor(private ts: TranslateService, private toaster: ToastrService) {
    }

    ngOnInit() {
    }

    onGroupDeleted(group:any){
        let items = _.remove(this.groups.entities, (g:any) => g.id == group.id);
        if(items.length > 0){
            this.groups.count -= items.length;
            this.ts.get("toast."+ group.groupType.toLowerCase() +".successfullDeletion").subscribe(ts => this.toaster.success(ts));
        }
    }

}
