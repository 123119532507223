import { Component, OnInit } from '@angular/core';
import { StorageService } from '../../service/storage.service';
import * as _ from 'lodash';
import { UserService } from '../../service/user.service';

@Component({
    selector: 'app-cookie-consent',
    templateUrl: './cookie-consent.component.html',
    styleUrls: ['./cookie-consent.component.scss']
})
export class CookieConsentComponent implements OnInit {

    me;

    constructor(private storageService: StorageService, private userService: UserService) {
    }

    ngOnInit() {
        this.userService.userObservable.subscribe(u => this.me = u);
    }

    isConsented(){
        return !_.isNull(this.storageService.getCookieConsent());
    }

    hideDisclaimer(){
        this.storageService.setCookieConsent();
    }

}
