import { Injectable } from '@angular/core';
import { UserService } from './user.service';
import { Settings } from '../app.settings';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';


@Injectable()
export class LinkGroupService {
    
    groups: any = [];
    
    constructor(private userService: UserService, private http: HttpClient) {

    }
    
    loadLinkGroups() {
        return this.http.get(Settings.LINK_GROUPS)
            .pipe(
                map((groups: any[]) => this.sortGroups(groups)),
                map(groups => {
                    this.groups = groups;
                    return groups;
                })
            );
    }
    
    saveGroup(group: any) {
        const verb = group.id ? 'put' : 'post';
        const suffix = group.id ? '/' + group.id : '';
        return this.http[verb](Settings.LINK_GROUPS + suffix, group);
    }
    
    deleteLinkGroup(id) {
        return this.http.delete(Settings.LINK_GROUPS + '/' + id);
    }
    
    saveLink(link: any) {
        const verb = link.id ? 'put' : 'post';
        const suffix = link.id ? '/' + link.id : '';
        return this.http[verb](Settings.LINK_GROUPS + '/' + link.linkGroupId + suffix, link);
    }
    
    deleteLink(id) {
        return this.http.delete(Settings.LINK_GROUPS + '/link/' + id);
    }
    
    private sortGroups(groups: any[]) {
        return groups.map(g => ({
            ...g,
            links: g.links.sort((a, b) => a.position < b.position ? -1 : 1)
        })).sort((a: any, b: any) => a.position < b.position ? -1 : 1);
    }
}
