import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import * as $ from 'jquery';
import * as _ from 'lodash';

@Component({
    selector: '[droppable]',
    template: '<ng-content></ng-content><div class="dropzone" [ngClass]="{\'is-dragover\': isDragover}"><i class="icon-icon-download"></i></div>',
    styleUrls: ['./droppable.component.scss']
})
export class DroppableComponent implements OnInit, OnChanges {

    isDragover: boolean = false;
    @Input()
    enabled: boolean = false;
    @Output()
    onFileSelected: EventEmitter<FileList> = new EventEmitter();

    constructor(private el: ElementRef) {
    }

    ngOnInit() {

    }


    ngOnChanges(changes:SimpleChanges):void {
        if(_.get(changes, 'enabled.currentValue')){
            $(this.el.nativeElement)
                .on('drag dragstart dragend dragover dragenter dragleave drop', function(e) {
                    e.preventDefault();
                    e.stopPropagation();
                })
                .on('dragenter dragstart', () => {
                    this.isDragover = true;
                });

            $(this.el.nativeElement).find('.dropzone')
                .on('drag dragstart dragend dragover dragenter dragleave drop', function(e) {
                    e.preventDefault();
                    e.stopPropagation();
                })
                .on('dragleave dragend drop', () => {
                    this.isDragover = false;
                })
                .on('drop', (e: any) => {
                    var droppedFiles = e.originalEvent.dataTransfer.files;
                    this.onFileSelected.emit(droppedFiles);
                });
        }else{
            $(this.el.nativeElement).off();
            $(this.el.nativeElement).find('.dropzone').off();
        }
    }
}
