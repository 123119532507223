import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { SkillService } from '../../service/skill.service';
import * as _ from 'lodash';
import { SelectComponent } from 'ng2-select/index';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-office-function-selector',
    templateUrl: './office-function-selector.component.html',
    styleUrls: ['./office-function-selector.component.scss']
})
export class OfficeFunctionSelectorComponent implements OnInit {

    @Input()
    control: FormControl;
    skills;
    @ViewChild(SelectComponent, {static: false})
    select: SelectComponent;

    constructor(private skillService: SkillService, private ts: TranslateService) {
    }

    ngOnInit() {
        this.skillService.getSkills().subscribe((skills: any) => {
            this.skills = _.map(_.filter(skills, (s:any) => s.skillType == 'FUNCTION'), (skill:any) => {
                var i = {
                    id: skill.id,
                    text: undefined
                };
                this.ts.get(skill.translationKey).subscribe(ts => {
                    i.text = ts;
                });
                return i;
            });

            if(this.control.value){
                this.select.active = _.filter(this.skills, (skill:any) => {
                    return skill.id == this.control.value;
                });
            }
            
            this.select.items = this.skills;
            this.select.ngOnInit();
        });
    }

    refreshValue(value){
        this.control.patchValue(value.id);
    }

}
