import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup } from '@angular/forms';
import { Media } from '../../../domain/media';
import { StorageService } from '../../../service/storage.service';
import * as _ from 'lodash';
import { DocumentService } from '../../../service/document.service';
import { AbstractModal } from '../../../shared/abstract-modal';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { FolderSelectorComponent } from '../../../shared/folder-selector/folder-selector.component';

@Component({
    selector: 'app-document-form',
    templateUrl: './document-form.component.html',
    styleUrls: ['./document-form.component.scss']
})
export class DocumentFormComponent extends AbstractModal implements OnInit {

    form: FormGroup = Media.form();

    loading: boolean = false;

    documentType = 'document';

    @ViewChild(FolderSelectorComponent, { static: false })
    folderSelector: FolderSelectorComponent;

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private storageService: StorageService,
        private documentService: DocumentService,
        private ts: TranslateService,
        private toaster: ToastrService
    ) {
        super();

        this.form.get('attachments').valueChanges.subscribe(att => {
            if (att) {
                this.form.controls.file.setValidators(null);
                this.form.controls.file.updateValueAndValidity();
            }
        });
    }

    ngOnInit() {
        this.route.params.subscribe(params => {
            if ('id' in params) {
                this.form.get('parentId').setValue(params['id'], { emitEvent: false });
            }
        });
    }

    ngAfterViewInit() {
        this.folderSelector.onReady.subscribe(() => {
            this.folderSelector.setValue(this.form.get('parentId').value);
        });
    }

    onFileSelected(e) {
        const files = _.get(e, 'target.files');

        if (!_.isUndefined(files) && files.length > 0) {
            this.form.patchValue({file: files[0]});
        } else {
            this.form.patchValue({file: undefined});
        }
    }

    save() {
        const formValue = _.clone(this.form.value);

        delete formValue.attachments;
        
        this.documentService.save(formValue).subscribe(document => {
            this.router.navigateByUrl(`/${this.router.url}`, { skipLocationChange: true });
            this.ts.get(`toasts.successfullDocumentEdition`).subscribe(ts => this.toaster.success(ts));
            this.close(document);
        });
    }

    removeFile() {
        this.form.patchValue({file: undefined});
    }
}
