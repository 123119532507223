import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Input, ViewChild } from '@angular/core';
import { MessageForm } from '../../forms/message.form';
import { Conversation } from '../../model/conversation';
import { ChatService } from '../../services/chat.service';
import { Message } from '../../model/message';
import { BehaviorSubject, Subject } from 'rxjs';
import { HttpEvent } from '@angular/common/http';

@Component({
    selector: 'app-chat-message-form',
    templateUrl: './chat-message-form.component.html',
    styleUrls: ['./chat-message-form.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChatMessageFormComponent {

    public form = new MessageForm();
    @Input()
    public conversation: Conversation;
    public loading = new Subject<boolean>();
    public progress = new BehaviorSubject<number>(0);
    @ViewChild('messageInput', {static: true})
    public input: ElementRef<HTMLTextAreaElement>;

    constructor(private readonly chatService: ChatService, private readonly cdr: ChangeDetectorRef) { }

    public sendMessage() {
        this.loading.next(false);
        this.progress.next(0);
        this.chatService.sendMessage(this.conversation.id, this.form.toFormData())
            .subscribe((event) => this.handleEvent(event), () => {
                this.loading.next(false);
                this.progress.next(0);
            });
    }

    public onKeydown($event: KeyboardEvent) {
        if (!$event.shiftKey && $event.key.toLowerCase() === 'enter') {
            if (this.form.valid)
                this.sendMessage();
            else
                $event.preventDefault();
        }
    }

    public addAttachment($event: File) {
        this.form.addFile($event);
    }

    public removeAttachment(index) {
        this.form.removeAttachment(index);
    }

    private handleEvent(event: HttpEvent<Message> | any) {
        switch (event.type) {
            case 0:
                this.loading.next(true);
                this.form.disable();
                break;
            case 1:
                const { loaded, total } = event;
                this.progress.next(loaded / total * 100);
                break;
            case 3:
                this.loading.next(false);
                this.form.reset();
                this.form.enable();
                this.input.nativeElement.focus();
                this.progress.next(0);
                this.cdr.markForCheck();
        }
    }
}
