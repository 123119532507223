import { Component, OnInit, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { EventService } from '../../../service/event.service';
import * as moment from 'moment';
import * as _ from 'lodash';
import { TranslateService } from '@ngx-translate/core';
import { Event } from '../../../domain/event';

@Component({
  selector: 'app-calendar-inner-form',
  templateUrl: './calendar-inner-form.component.html',
  styleUrls: ['./calendar-inner-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CalendarInnerFormComponent implements OnInit {

  minDate: Date = moment().add(21000, 'years').toDate();
  form: FormGroup = Event.form();

  isEventOffice = true;
  isOutside: boolean = false;

  @Output()
  add = new EventEmitter<void>();

  isShowContent = true;

  constructor(private eventService: EventService,
    private ts: TranslateService) {
  }

  ngOnInit() {
    this.form.get('start').valueChanges.subscribe(v => {
      this.minDate = moment(v, 'DD/MM/YYYY HH:mm').toDate();
      if (moment(this.form.get('end').value, 'DD/MM/YYYY HH:mm').isBefore(moment(v, 'DD/MM/YYYY HH:mm'))) {
        this.form.get('end').setValue(moment(this.minDate).format('DD/MM/YYYY HH:mm'), { emitEvent: false });
      }
    });
    this.form.get('end').valueChanges.subscribe(v => {
      const start = this.form.get('start').value;
      if (moment(v, 'DD/MM/YYYY HH:mm').isBefore(moment(start, 'DD/MM/YYYY HH:mm'))) {
        this.form.get('end').setValue(moment(this.minDate).format('DD/MM/YYYY HH:mm'), { emitEvent: false });
      }
    });

    this.onShowContent();
    this._setEventOffice();

  }

  private _setEventOffice() {
    this.form.get('groupId').disable();      
    this.form.get('groupId').setValue("");
    this.form.get('officeId').enable();
  }

  private _setEventGroupe() {
    this.form.get('officeId').disable();   
    this.form.get('officeId').setValue("");
    this.form.get('groupId').enable(); 
  }

  onLocationSelected($event) {    
    this.form.get('location').setValue($event.formatted_address);
  }

  onChangeSwitch(event) {
    this.isEventOffice = !this.isEventOffice;
    if (this.isEventOffice)
      this._setEventOffice();
    else
      this._setEventGroupe();    
  }

  onChangeOutside(event) {
    this.isOutside = event.target.checked;

    if (this.isOutside) {
      this.form.get('groupId').disable();      
      this.form.get('groupId').setValue("");
      this.form.get('officeId').disable();   
      this.form.get('officeId').setValue("");
    } else {
      //
    }
  }

  save() {
    let data = this.form.value;
    data.start = moment(data.start, 'DD/MM/YYYY HH:mm').toDate();
    data.end = moment(data.end, 'DD/MM/YYYY HH:mm').toDate();
    this.eventService.saveEvent(data).subscribe(
      () => {
        this.isOutside = false;
        this.form.reset(Event.form().value);
        this.add.emit();
      }
    );
  }
  
  onFileSelected(e) {
    if (_.get(e, 'target.files.length') > 0) {
      this.form.patchValue({ file: e.target.files[0] });
    } else {
      this.form.patchValue({ file: undefined });
    }
  }

  get fileLabel() {
    if (this.form.value.file === undefined || this.form.value.file === null)
      return this.ts.instant("form.file.not");
    else
      return this.form.value.file.name;
  }

  onShowContent() {
    if (window.innerWidth < 960)
      this.isShowContent = !this.isShowContent;
  }
}
