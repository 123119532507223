import { Component, ElementRef, Input, OnChanges, SimpleChanges } from '@angular/core';
import { StorageService } from '../../../service/storage.service';
import * as _ from 'lodash';
import * as $ from 'jquery';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Component({
    selector: '[secured-media]',
    template: '<ng-content></ng-content>',
    styleUrls: ['./secured-media.component.scss']
})
export class SecuredMediaComponent implements OnChanges {
    
    @Input('secured-media')
    path: string;
    
    constructor(private e: ElementRef, private http: HttpClient, private storageService: StorageService) {
    }
    
    ngOnChanges(changes: SimpleChanges): void {
        this.loadMedia().subscribe(data => {
            let d = <any>data;
            this.e.nativeElement.src = `data:${d.type};base64,${d.data}`;
        });
    }
    
    loadMedia() {
        $(this.e.nativeElement).parent().addClass('loading');
        let token = _.get(this.storageService.getToken(), 'token');
        return this.http.get(this.path, {
            headers: new HttpHeaders({
                'Authorization': token
            })
        }).pipe(
            map(res => {
                $(this.e.nativeElement).parent().removeClass('loading');
                return res;
            }),
            catchError(res => {
                $(this.e.nativeElement).parent().removeClass('loading');
                return throwError('');
            })
        );
    }
    
}
