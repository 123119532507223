import { Component, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import { ToastrService } from 'ngx-toastr';
import { AbstractModal } from '../../../shared/abstract-modal';
import { Media } from '../../../domain/media';
import { DocumentService } from '../../../service/document.service';

@Component({
    selector: 'app-folder-create-or-edit-modal',
    templateUrl: './folder-create-or-edit-modal.component.html',
    styleUrls: ['./folder-create-or-edit-modal.component.scss']
})
export class FolderCreateOrEditModalComponent extends AbstractModal implements OnInit 
{
    form: FormGroup = Media.folderForm();

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private ts: TranslateService,
        private toaster: ToastrService,
        private documentService: DocumentService,
    ) {
        super();
    }

    ngOnInit() {
        this.route.params.subscribe(params => {
            if ('id' in params) {
                this.form.get('parentId').setValue(params['id'], { emitEvent: false });
            }
        });
    }

    updateOrStoreFolder() {
        this.documentService.save(this.form.value)
            .subscribe((folder) => {
                this.ts.get('toasts.successfullTranslationEdition').subscribe((ts) => {
                    this.toaster.success(ts);
                    this.onClose.emit(folder);
                    this.modal.hide();
                });
            }, (err) => {
                // TODO
            });
    }
}
