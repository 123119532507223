import { FormGroup, FormControl, Validators } from "@angular/forms";

export class EventComment {

    static form() {
        return new FormGroup({      
            id: new FormControl(null),               
            content: new FormControl(),
            eventId: new FormControl(),
            active: new FormControl(true)
        });
    }
}