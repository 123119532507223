import { Component, Input, OnInit } from '@angular/core';
import { GroupService } from '../../../service/group.service';
import { UserService } from '../../../service/user.service';
import { ToastrService } from 'ngx-toastr';
import * as $ from 'jquery';
import { EventService } from '../../../service/event.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-group-member-list',
    templateUrl: './group-member-list.component.html',
    styleUrls: ['./group-member-list.component.scss']
})
export class GroupMemberListComponent implements OnInit {

    @Input('group')
    group;
    @Input('label')
    label: string = 'group.members.label';
    @Input()
    service = 'groupService';
    members;
    private page: number = 1;
    private loading: boolean = false;
    copyLoading;

    constructor(private groupService: GroupService,
                private userService: UserService,
                private eventService: EventService,
                private ts: TranslateService,
                private toaster: ToastrService) {
    }

    ngOnInit() {
        this.loading = true;
        this[this.service].loadMembers(this.group.id, this.page).subscribe(p => {
            this.members = p;
            this.loading = false;
        });
        if(this.service == 'groupService') {
            this.loadClipboardUsers();
        }
    }

    loadUsers(){
        if(this.loading){
            return;
        }
        this.loading = true;
        this[this.service].loadMembers(this.group.id, ++this.page).subscribe(p => {
            this.members.entities = this.members.entities.concat((<any>p).entities);
            this.loading = false;
        });
    }

    loadClipboardUsers(){
        this.copyLoading = true;
        this[this.service].getEmailForUsersInGroup(this.group.id).subscribe((members: any) => {
            this.copyLoading = false;
            $('.emailAddresses').text(members.join(','));
        }, () => {

        });
    }

    showClipboardToast(){
        this.ts.get('toasts.successfullClipboard').subscribe(ts => this.toaster.success(ts));
    }
}
