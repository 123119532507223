import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Settings } from '../app.settings';

@Injectable({
  providedIn: 'root'
})
export class NewsletterService {
  
  constructor(
    private http: HttpClient
  ) { }

  sendmeNewsletter() {
    return this.http.get(Settings.NEWSLETTER + "/sendme");
  }

  saveNewsletterParams(params: any) {
    return this.http.post(Settings.NEWSLETTER, params);
  }

  getNewsletterParams() {
    return this.http.get(Settings.NEWSLETTER);
  }
}
