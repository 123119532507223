import { FormControl, FormGroup, Validators } from '@angular/forms';

export class Comment {
    static form(){
        return new FormGroup({
            id: new FormControl(),
            content: new FormControl('', [Validators.required]),
            file: new FormControl(),
        });
    }
}
