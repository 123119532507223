import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, Input } from '@angular/core';
import { Conversation } from '../../model/conversation';
import { map } from 'rxjs/operators';
import first from 'lodash-es/first';
import { I_USER_LOCATOR, IUserLocator } from '../../interfaces/user-locator.interface';
import { ChatStoreModel } from '../../store/reducers';
import { Store } from '@ngrx/store';

@Component({
    selector: 'app-app-chat-icon',
    templateUrl: './app-chat-icon.component.html',
    styleUrls: ['./app-chat-icon.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppChatIconComponent {
    
    @Input()
    public conversation: Conversation;
    @Input()
    public displayCount: boolean;
    
    constructor(
        @Inject(I_USER_LOCATOR) private readonly userLocator: IUserLocator,
        private readonly cdr: ChangeDetectorRef,
        private readonly store: Store<ChatStoreModel>
    ) {
        this.store.select('chat', 'conversations', 'entities').subscribe(_ => {
            this.cdr.markForCheck();
        });
    }
    
    public isOneToOneChat() {
        return this.conversation.oneToOne;
    }
    
    public getCounterPart() {
        return this.userLocator.currentUserAsync().pipe(
            map(u => first(this.conversation.occupants.filter(o => o.id !== u.id)))
        )
    }
    
}
