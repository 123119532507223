import { FormControl, FormGroup, Validators } from '@angular/forms';

export class Event {

    static form() {
        return new FormGroup({
            id: new FormControl(),
            title: new FormControl('', Validators.required),
            description: new FormControl('', Validators.required),
            file: new FormControl(),
            groupId: new FormControl(),
            location: new FormControl('', Validators.required),
            outside: new FormControl(false),
            officeId: new FormControl(null, Validators.required),
            active: new FormControl(true),
            start: new FormControl(null, Validators.required),
            end: new FormControl(null, Validators.required),
            attachments: new FormControl()
        });
    }
}
