import * as _ from 'lodash';
import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { ForumService } from '../../service/forum.service';
import { ActivatedRoute } from '@angular/router';
import { FormControl, FormGroup } from '@angular/forms';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-user-forum-list',
  templateUrl: './user-forum-list.component.html',
  styleUrls: ['./user-forum-list.component.scss']
})
export class UserForumListComponent implements OnInit {

  posts: any;

  @Input()
  title;
  
  private userId;

  formPager: FormGroup = new FormGroup({
    page: new FormControl(1),
    sortOrder: new FormControl("{ created: -1 }"),
    itemsPerPage: new FormControl(10)
  });

  public loading = false;

  constructor(private forumService: ForumService, private route: ActivatedRoute) {
  }

  ngOnInit() {
    this.route.params.subscribe(p => {
      if ('id' in p) {
        this.userId = p["id"];

        this.formPager.valueChanges.subscribe(v => {
          this.load()
        });

        this.load();
      }
    });
  }

  load() {
    this.forumService.getPostsForUser(this.userId, this.formPager.value).subscribe((posts: any) => {
      if (this.formPager.get('page').value > 1) {
        this.posts.entities = this.posts.entities.concat(posts.entities);
      } else {
        this.posts = posts;
      }      
    });
  }

  onScroll() {
    if (_.get(this.posts, "entities.length") != _.get(this.posts, "count")) {
      this.formPager.patchValue({ page: ++this.formPager.value.page });
    }
  }

  get envApi() {
    return environment.api;
  }
}
