import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { UserService } from '../../service/user.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-change-email',
    templateUrl: './change-email.component.html',
    styleUrls: ['./change-email.component.scss']
})
export class ChangeEmailComponent implements OnInit {

    constructor(private userService: UserService,
                private router: Router,
                private route: ActivatedRoute,
                private ts: TranslateService,
                private toaster: ToastrService) {
    }

    ngOnInit() {
        this.route.params.subscribe(p => {
            this.userService.validateEmailChangeToken(p["token"])
                .subscribe(() => {
                    this.ts.get('toasts.successfullEmailChange').subscribe(ts => {
                        this.toaster.success(ts);
                        this.router.navigate(['']);
                    });
                }, () => {
                    this.router.navigate(['']);
                });
        });
    }

}
