import { ChangeDetectionStrategy, Component, Inject, Input, OnInit } from '@angular/core';
import { Message } from '../../model/message';
import { I_USER_LOCATOR, IUserLocator } from '../../interfaces/user-locator.interface';
import { Sender } from '../../model/sender';
import * as moment from 'moment';
import { ChatStoreModel } from '../../store/reducers';
import { Store } from '@ngrx/store';
import { updateReadStatus } from '../../store/actions';
import { Occupant } from '../../model/occupant';
import { ChatService } from '../../services/chat.service';

@Component({
    selector: 'app-chat-message-item',
    templateUrl: './chat-message-item.component.html',
    styleUrls: ['./chat-message-item.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChatMessageItemComponent implements OnInit {
    
    @Input()
    public message: Message;
    public isSelf: boolean = false;
    private sender: Sender;
    
    constructor(
        @Inject(I_USER_LOCATOR) private readonly userLocator: IUserLocator,
        private readonly store: Store<ChatStoreModel>,
        private chatService: ChatService
    ) {
        this.sender = this.userLocator.currentUser();
    }
    
    public ngOnInit(): void {
        this.isSelf = this.message.sender && this.sender && this.message.sender.id === this.sender.id;
    }
    
    public renderLabel() {
        return this.isSelf ? 'messages.senders.you' : `${this.message.sender.firstname}`;
    }
    
    public get messageDate() {
        return moment.unix(this.message.created / 1000).calendar().replace('à', '-');
    }
    
    public inView() {
        if (!this.message.read) {
            this.chatService.updateReadStatus(this.message.conversationId, this.userLocator.currentUser().id);
            this.store.dispatch(updateReadStatus({
                conversationId: this.message.conversationId,
                userId: this.userLocator.currentUser().id
            }));
        }
    }
    
    public notInView() {}
    
    public isMe(occupant: Occupant) {
        return this.sender.id === occupant.id;
    }
}
