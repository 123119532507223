import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { PublicDataService } from '../../service/public-data.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-group-type-selector',
    templateUrl: './group-type-selector.component.html',
    styleUrls: ['./group-type-selector.component.scss']
})
export class GroupTypeSelectorComponent implements OnInit {

    items = [];
    @Output()
    onItem: EventEmitter<any> = new EventEmitter();

    constructor(private publicDataService: PublicDataService, private ts: TranslateService) {
    }

    ngOnInit() {
        this.publicDataService.getGroupTypes().subscribe(g => {
            this.items = [];
            g.forEach( (i, index) => {
                let ts = {
                    id: i,
                    text: i
                };
                this.items.push(ts);
                this.loadTranslationFor(ts);
            });
        });
    }

    onItemSelected(item){
        this.onItem.emit(item);
    }

    private loadTranslationFor(ts) {
        this.ts.get(ts.id).subscribe(t => ts.text = t);
    }
}
