import { FormControl, FormGroup, Validators } from '@angular/forms';

export class OccupantForm extends FormGroup {
    constructor({id, firstname, lastname}) {
        super({
            id: new FormControl(id, [Validators.required]),
            firstname: new FormControl(firstname),
            lastname: new FormControl(lastname),
        });
    }
    
    public equals(occupant: OccupantForm) {
        return occupant.id === this.id;
    }
    
    public get id(): string {
        return this.get('id').value;
    }
    
    public get firstname(): string {
        return this.get('firstname').value;
    }
    
    public get lastname(): string {
        return this.get('lastname').value;
    }
}
