import { Component, OnInit } from '@angular/core';
import { FeedService } from '../domain/feed.service';
import { FormControl, FormGroup } from '@angular/forms';
import { environment } from '../../environments/environment';
import * as $ from 'jquery';
import { NewsService } from '../service/news.service';
import * as moment from 'moment';
import { CalendarDate } from '../shared/calendar-widget/calendar-widget.component';
import { Router } from '@angular/router';
import { EventService } from '../service/event.service';

@Component({
    selector: 'app-feed',
    templateUrl: './feed.component.html',
    styleUrls: ['./feed.component.scss']
})
export class FeedComponent implements OnInit {

    feed: any;
    form: FormGroup = new FormGroup({
        feedType: new FormControl(),
        privacy: new FormControl(),
        page: new FormControl(1),
        itemsPerPage: new FormControl(15)
    });

    news = null;

    currentSelectedDate = new Date();
    selectedDates: CalendarDate[] = [];
    events = null;

    constructor(private feedService: FeedService,
        private newsService: NewsService,
        private router: Router,
        private eventService: EventService) {
    }

    ngOnInit() {
        this.feedService.loadFeed(this.form.value).subscribe(f => this.feed = f);
        this.form.valueChanges.subscribe(v => {
            this.feedService.loadFeed(this.form.value).subscribe((f: any) => {
                if (v.page > 1) {
                    this.feed.entities = this.feed.entities.concat(f.entities);
                } else {
                    this.feed = f;
                }
            });
        });
        this.newsService.getLatestNews().subscribe(
            (n) => this.news = n
        );

        this.loadEvents();
    }

    private loadEvents() {
        let start = new Date(this.currentSelectedDate.getFullYear(), this.currentSelectedDate.getMonth(), 1);
        let end = new Date(this.currentSelectedDate.getFullYear(), this.currentSelectedDate.getMonth() + 1, 0);
        
        this.eventService.getEventsBetween(start.getTime(), end.getTime(), this.form.value).subscribe(
            (events) => {
            this.events = events;

            this.selectedDates = [];
            this.events.forEach(e => {
                let date = new CalendarDate();
                date.mDate = moment(e.start);
                this.selectedDates.push(date);
            });
        });
    }

    setFilter(type, privacy) {
        this.form.patchValue({
            feedType: type ? type.toUpperCase() : undefined,
            privacy: privacy ? privacy.toUpperCase() : undefined,
            page: 1
        });
    }

    onScroll() {
        this.form.patchValue({ page: ++this.form.value.page });
    }

    get envApi() {
        return environment.api;
    }

    checkIsImage(file) {
        var validImageTypes = ["image/gif", "image/jpeg", "image/png"];
        return ($.inArray(file["contentType"], validImageTypes) != -1);
    }

    onChangedMonth(date: moment.Moment) {
        this.currentSelectedDate = date.toDate();
        this.loadEvents();
    }

    onDateSelected(date: CalendarDate) {
        const event = this.events.find(e => moment(e.start).isSame(date.mDate, 'day'));
        this.router.navigate(['/social/event/:id', { id: event.id }]);
    }

}
