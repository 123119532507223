import { Component, OnInit, ChangeDetectionStrategy, ViewChild } from '@angular/core';
import { ForumPost } from '../../domain/forum-post';
import { FormGroup } from '@angular/forms';
import { AbstractModal } from '../../shared/abstract-modal';
import { ForumService } from '../../service/forum.service';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { UserService } from '../../service/user.service';
import * as _ from 'lodash';
import { DocumentSelectorComponent } from '../../shared/document-selector/document-selector.component';

@Component({
  selector: 'app-forum-form',
  templateUrl: './forum-form.component.html',
  styleUrls: ['./forum-form.component.scss']
})
export class ForumFormComponent extends AbstractModal {

  form: FormGroup = ForumPost.form();
  user: any;
  documentIds: any;
  @ViewChild(DocumentSelectorComponent, { static: false })
  documentSelector: DocumentSelectorComponent;

  constructor(
    private forumService: ForumService,
    private ts: TranslateService,
    private toaster: ToastrService,
    private userService: UserService
  ) {
    super();
    this.user = this.userService.userObservable;
  }

  ngOnInit() {}

  ngAfterViewInit() {
    this.documentSelector.onReady.subscribe(() => {
      this.documentSelector.setValue(this.form.get('documentIds').value);
    });
  }

  save() {
    this.forumService.savePost(this.form.value).subscribe(post => {
      this.ts.get(`toasts.successfullForumPostEdition`).subscribe(ts => this.toaster.success(ts));
      this.close(post);
    });
  }

  isAdmin() {
    return this.user && _.find(this.user.roles, r => r == 'ADMIN');
  }

  patchDocuments(documents) {  
    this.form.patchValue({ documentIds: documents });
  }
}
