import { Injectable } from '@angular/core';
import { Settings } from '../app.settings';
import { HttpClient } from '@angular/common/http';
import { toHttpParams } from '../shared/utils';

@Injectable()
export class FeedService {

    constructor(private http: HttpClient) {}

    loadFeed(value) {

        let params = toHttpParams(value);

        return this.http.get(Settings.FEED, {
            params
        });
    }
}
