import { Injectable } from '@angular/core';
import { Settings } from '../app.settings';
import * as _ from 'lodash';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Pageable } from '../shared/model/pageable';
import { toHttpParams } from '../shared/utils';

@Injectable({
    providedIn: 'root'
})
export class ForumService {

    constructor(private http: HttpClient) {
    }

    getPosts(pager): Observable<any> {
        let params = toHttpParams(pager);

        return this.http.get(Settings.FORUM_POST, {
            params
        });
    }

    getPost(postId) {
        return this.http.get(Settings.FORUM_POST + "/" + postId);
    }

    loadForumFeed() {
        return this.http.get(Settings.FORUM_POST + "/feed");
    }

    deletePost(id: any) {
        return this.http.delete(Settings.FORUM_POST + "/" + id);
    }

    getPostsForUser(userId: string, pager) {
        let params = toHttpParams(pager);

        return this.http.get(Settings.FORUM_POST + "/of/" + userId, {
            params
        });
    }

    savePost(post) {
        return this.http.post(Settings.FORUM_POST + "/add", post);
    }

    follow(post) {
        return this.http.put(Settings.FORUM_POST + "/follow/" + post.id, {});
    }

    unfollow(post) {
        return this.http.delete(Settings.FORUM_POST + "/follow/" + post.id);
    }

    countUsers(post) {
        return this.http.get(Settings.FORUM_POST + "/users/" + post.id);
    }

    getComments(post) {
        return this.http.get(Settings.FORUM_COMMENT + "/for/" + post.id);
    }

    getComment(commentId: string) {
        return this.http.get(Settings.FORUM_COMMENT + "/" + commentId);
    }

    deleteComment(id: any) {
        return this.http.delete(Settings.FORUM_COMMENT + "/" + id);
    }

    countComments(post) {
        return this.http.get(Settings.FORUM_COMMENT + "/count-for/" + post.id);
    }

    saveComment(postId: any, comment: any) {
        let data = comment;
        let endPoint = '/';
        if (_.get(comment, 'file')) {
            data = new FormData();
            data.append('file', comment.file);
            data.append('content', comment.content);
            endPoint += 'upload/';
        }

        return this.http.post(Settings.FORUM_COMMENT + endPoint + postId, data);
    }

    getDocuments(post) {
        return this.http.get(Settings.FORUM_POST + "/documents/" + post.id);
    }
}
