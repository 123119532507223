import { FormControl, FormGroup, Validators } from "@angular/forms";

export class ForumPost {

    static form() {
        return new FormGroup({
            id: new FormControl(),           
            subject: new FormControl('', [Validators.required]),            
            content: new FormControl(),
            documentIds: new FormControl(),
            active: new FormControl(true)
        });
    }
}