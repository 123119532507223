import { Component, Input, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { UserService } from '../../service/user.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FormControl, FormGroup } from '@angular/forms';
import { SkillSelectorComponent } from '../../shared/skill-selector/skill-selector.component';
import * as _ from 'lodash';
import { ModalService } from '../../service/modal.service';
import { UserCreationModalComponent } from './user-creation-modal/user-creation-modal.component';
import { environment } from '../../../environments/environment';

@Component({
    selector: 'app-user',
    templateUrl: './user.component.html',
    styleUrls: ['./user.component.scss']
})
export class UserComponent implements OnInit {

    users;
    frontend;
    filter: string = 'all';
    display: string = 'name';
    favorites;
    @Input("form")
    form: FormGroup = new FormGroup({
        skillId: new FormControl(),
        officeId: new FormControl(),
        filter: new FormControl('all'),
        display: new FormControl('name'),
        page: new FormControl(1),
        itemsPerPage: new FormControl(30),
    });
    loading = false;
    @ViewChild(SkillSelectorComponent, { static: false })
    skillSelector: SkillSelectorComponent;
    me;

    constructor(private userService: UserService,
        private route: ActivatedRoute,
        private router: Router,
        private vcr: ViewContainerRef,
        private modalService: ModalService) {

    }

    ngOnInit() {
        if (window.innerWidth > 1928) {
            this.form.patchValue({ itemsPerPage: 60 });
        }

        this.route.params.subscribe(p => {
            this.form.patchValue(<any>p);
            if ('skillId' in p) {
                this.skillSelector.setValue(p["skillId"]);
            }
        });

        this.userService.favoritesObservable.subscribe(f => this.favorites = f);
        this.me = this.userService.u;
        this.userService.userObservable.subscribe(u => {
            this.me = u;
        });

        this.loadUsers(this.form.value);

        this.form.get('filter').valueChanges.subscribe(v => {
            delete this.users;
            this.form.get('filter').setValue(v, { emitEvent: false });
            this.form.get('page').setValue(1);
            return v;
        });

        this.form.get('display').valueChanges.subscribe(v => {
            delete this.users;
            this.form.get('display').setValue(v, { emitEvent: false });
            this.form.patchValue({ page: 1 });
        });

        this.form.get('officeId').valueChanges.subscribe(v => {
            delete this.users;
            this.form.get('officeId').setValue(v, { emitEvent: false });
            if (v)
                this.setDisplay('office')
            else
                this.setDisplay('name')            
        });

        this.form.get('skillId').valueChanges.subscribe(v => {
            delete this.users;
            this.form.get('skillId').setValue(v, { emitEvent: false });
            this.form.patchValue({ page: 1 });
        });

        this.form.get('page').valueChanges.subscribe(page => {
            this.form.get('page').setValue(page, { emitEvent: false });
            this.loadUsers(this.form.value);
        });
    }

    setFilter(filter) {
        this.filter = filter;
        this.form.get('filter').setValue(filter);
    }

    setDisplay(display) {
        this.display = display;
        this.form.patchValue({ display: this.display });
    }

    onScroll() {
        if (_.get(this.users, "entities.length") != _.get(this.users, "count")) {
            this.form.patchValue({ page: ++this.form.value.page });
        }
    }

    onScrollUp() { }

    hasRole(role: string) {
        return _.find(<Array<any>>_.get(this, 'me.roles'), r => r == role);
    }

    openUserCreationModal() {
        var c = this.modalService.openModal(UserCreationModalComponent, this.vcr);
        c.instance.onClose.subscribe(user => {
            if (user) {
                this.router.navigate(['/social/directory', user.id]);
            }
            c.destroy();
        });

        c.instance.onDiscard.subscribe(() => {
            c.destroy();
        });
    }

    loadUsers(formValue) {
        this.userService.getUsers(formValue)
            .subscribe((users: any) => {
                if (!this.users) {
                    this.users = users;
                } else {
                    this.users.entities = this.users.entities.concat(users.entities);
                }
            });
    }

    distinctOffices() {
        return _.uniqBy(_.map(_.get(this, 'users.entities'), (u: any) => u.office), 'id');
    }

    pageableForOffice(officeId) {
        return {
            entities: _.filter(_.get(this, 'users.entities'), (u: any) => _.get(u, 'office.id') == officeId)
        };
    }

    get getApi() {
        return environment.api;
    }

    isAdmin() {
        return _.find(<any>(_.get(this, 'userService.u.roles') || []), r => (r == 'ADMIN' || r == 'SUB_ADMIN'));
    }

}
