import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserAvatarComponent } from './user-avatar/user-avatar.component';
import { RouterModule } from '@angular/router';
import { FileUploadModule } from 'ng2-file-upload';
import { DroppableComponent } from './droppable/droppable.component';
import { SecuredComponent } from '../../osadmin/secured.component';
import { SecuredMediaComponent } from './secured-media/secured-media.component';
import { LinkifyPipe } from './pipes/linkify.pipe';

@NgModule({
  declarations: [
    UserAvatarComponent,
    DroppableComponent,
    SecuredMediaComponent,
    LinkifyPipe
  ],
  imports: [
    CommonModule,
    RouterModule,
    FileUploadModule,
  ],
  exports: [
    UserAvatarComponent,
    DroppableComponent,
    SecuredMediaComponent,
    LinkifyPipe,
  ]
})
export class SharedModule { }
