import { Component } from '@angular/core';
import { UserService } from '../../service/user.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-logout-btn',
  templateUrl: './logout-btn.component.html',
  styleUrls: ['./logout-btn.component.scss'],
})
export class LogoutBtnComponent {
    
    constructor(
        private readonly userService: UserService,
        private readonly router: Router,
    ) { }
    
    public logout() {
        this.userService.logout();
        this.router.navigate(['login']);
    }

}
