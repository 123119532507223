import { FormControl, FormGroup, Validators } from '@angular/forms';

export class TranslationKey {
    static form(){
        return new FormGroup({
            id: new FormControl(),
            key: new FormControl('', Validators.required),
            value: new FormControl('', Validators.required)
        });
    }
}
