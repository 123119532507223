import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { PublicDataService } from '../../service/public-data.service';
import { SelectComponent, SelectItem } from 'ng2-select/index';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import { NgSelectComponent } from '@ng-select/ng-select';

@Component({
    selector: 'app-role-selector',
    templateUrl: './role-selector.component.html',
    styleUrls: ['./role-selector.component.scss']
})
export class RoleSelectorComponent implements OnInit {

    @Input()
    control: FormControl;
    roles = [];

    @ViewChild(NgSelectComponent, { static: false })
    select: NgSelectComponent;

    constructor(
        private ds: PublicDataService,
        private ts: TranslateService
    ) {
    }

    ngOnInit() {
        this.ds.getRoles().subscribe((roles: any) => {
            this.roles = roles.map(r => this.asRole(r));
            this.roles = _.orderBy(this.roles, [r => r.text]);            
            this.select.writeValue(this.control.value.map(r => this.asRole(r).id));            
        });
    }

    asRole(v) {
        let r = {
            id: v,
            text: undefined
        };

        this.ts.get(v).subscribe(ts => {
            r.text = ts;
        });
        return r;
    }

    refreshValue(items: SelectItem[]) {
        this.control.patchValue(items.map(i => i.id));
    }

}
