import { FormControl, FormGroup } from '@angular/forms';

export class LoginForm {

    public static form(){
        return new FormGroup({
                username: new FormControl(),
                password: new FormControl()
            });
    }

}
