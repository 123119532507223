import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Pageable } from '../../../shared/model/pageable';
import { Conversation } from '../model/conversation';
import { Message } from '../model/message';

@Injectable()
export class ChatRepository {
    constructor(private readonly http: HttpClient) {}
    
    public loadConversations(page): Observable<Pageable<Conversation>> {
        return this.http.get<Pageable<Conversation>>('/api/chat/groups', {
            params: new HttpParams().append('page', page)
        });
    }
    
    public create(form: any) {
        return this.http.post<Conversation>(`/api/chat/groups`, form);
    }
    
    public update(form: any) {
        return this.http.put<Conversation>(`/api/chat/groups/${form.id}`, form);
    }
    
    public sendMessage(conversationId: string, message: any) {
        return this.http.post<Message>(`/api/chat/groups/message/${conversationId}`, message, {
            observe: 'events',
            reportProgress: true
        });
    }

    public updateReadStatus(conversationId: string, lastRead: string) {
        return this.http.get<Message>(`/api/chat/groups/readstatus/${conversationId}`, {
            params: new HttpParams().append("lastRead", lastRead)
        });
    }
    
    public loadMessages(conversationId: string, before: number) {
        return this.http.get<Message[]>(`/api/chat/groups/messages/${conversationId}`, {
            params: new HttpParams().append('before', '' + before)
        });
    }
    
    public loadConversation(conversationId: string) {
        return this.http.get<Conversation>(`/api/chat/groups/${conversationId}`);
    }
    
    public quitConversation(conversationId: string) {
        return this.http.delete<void>(`/api/chat/groups/${conversationId}`);
    }
}
