import { AgmCoreModule } from '@agm/core';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { NguiAutoCompleteModule } from '@ngui/auto-complete/dist/auto-complete.module';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { OwlDateTimeIntl, OwlDateTimeModule, OwlNativeDateTimeModule, OWL_DATE_TIME_FORMATS, OWL_DATE_TIME_LOCALE } from 'ng-pick-datetime';
import { OwlMomentDateTimeModule } from 'ng-pick-datetime-moment';
import { Ng2DatetimePickerModule } from 'ng2-datetime-picker/dist/index';
import { FileUploadModule } from 'ng2-file-upload/index';
import { SelectModule } from 'ng2-select/index';
import { NgSelectModule } from '@ng-select/ng-select';
import { TooltipModule } from 'ng2-tooltip-directive';
import { BsDropdownModule, ModalModule, TabsModule } from 'ngx-bootstrap';
import { ClipboardModule } from 'ngx-clipboard';
import { ColorPickerModule } from 'ngx-color-picker';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { MomentModule } from 'ngx-moment';
import { PopoverModule } from 'ngx-popover/index';
import { ToastrModule } from 'ngx-toastr';
import { UiSwitchModule } from 'ngx-ui-switch';
import { environment } from '../environments/environment';
import { AdminBarComponent } from './admin-bar/admin-bar.component';
import { AppComponent } from './app.component';
import { appInit } from './app.init';
import { appRoutes } from './app.routes';
import { DefaultIntl, MY_MOMENT_FORMATS, Settings } from './app.settings';
import { FeedService } from './domain/feed.service';
import { NotFoundComponent } from './errors/not-found/not-found.component';
import { CommentFeedItemComponent } from './feed/feed-item/comment-feed-item/comment-feed-item.component';
import { EventFeedItemComponent } from './feed/feed-item/event-feed-item/event-feed-item.component';
import { FeedItemComponent } from './feed/feed-item/feed-item.component';
import { ForumFeedItemComponent } from './feed/feed-item/forum-feed-item/forum-feed-item.component';
import { GroupFeedItemComponent } from './feed/feed-item/group-feed-item/group-feed-item.component';
import { GroupedFeedItemComponent } from './feed/feed-item/grouped-feed-item/grouped-feed-item.component';
import { MediaFeedItemComponent } from './feed/feed-item/media-feed-item/media-feed-item.component';
import { MessageFeedItemComponent } from './message-feed/message-feed-item/message-feed-item.component';
import { TopicFeedItemComponent } from './feed/feed-item/topic-feed-item/topic-feed-item.component';
import { FeedListComponent } from './feed/feed-list/feed-list.component';
import { FeedComponent } from './feed/feed.component';
import { ForumCommentFormComponent } from './forum/forum-comment-form/forum-comment-form.component';
import { ForumFormComponent } from './forum/forum-form/forum-form.component';
import { ForumFullComponent } from './forum/forum-full/forum-full.component';
import { ForumItemComponent } from './forum/forum-item/forum-item.component';
import { ForumComponent } from './forum/forum.component';
import { UserForumListComponent } from './forum/user-forum-list/user-forum-list.component';
import { AuthGuard } from './guards/auth.guard';
import { HomeComponent } from './home.component';
import { ApiCallInterceptor } from './interceptors/api-call.interceptor';
import { FooterComponent } from './layout/footer/footer.component';
import { HeaderComponent } from './layout/header/header.component';
import { MainLoggedComponent } from './layout/main-logged/main-logged.component';
import { MainUnloggedComponent } from './layout/main-unlogged/main-unlogged.component';
import { NavComponent } from './layout/nav/nav.component';
import { LinkFormComponent } from './link-groups/link-form/link-form.component';
import { LinkGroupFormComponent } from './link-groups/link-group-form/link-group-form.component';
import { LinkGroupComponent } from './link-groups/link-group/link-group.component';
import { LinkGroupsComponent } from './link-groups/link-groups.component';
import { LoginComponent } from './login.component';
import { MessageFeedComponent } from './message-feed/message-feed.component';
import { ChatModule } from './modules/chat/chat.module';
import { SharedModule } from './modules/shared/shared.module';
import { CalendarFormComponent } from './osadmin/calendar/calendar-form/calendar-form.component';
import { CalendarFullComponent } from './osadmin/calendar/calendar-full/calendar-full.component';
import { CalendarInnerFormComponent } from './osadmin/calendar/calendar-inner-form/calendar-inner-form.component';
import { CalendarItemComponent } from './osadmin/calendar/calendar-item/calendar-item.component';
import { CalendarListComponent } from './osadmin/calendar/calendar-list/calendar-list.component';
import { CalendarRightGutterComponent } from './osadmin/calendar/calendar-right-gutter/calendar-right-gutter.component';
import { CalendarComponent } from './osadmin/calendar/calendar.component';
import { EventCommentFormComponent } from './osadmin/calendar/event-comment-form/event-comment-form.component';
import { GroupEventsListComponent } from './osadmin/calendar/group-events-list/group-events-list.component';
import { DashboardComponent } from './osadmin/dashboard/dashboard.component';
import { DocumentFormComponent } from './osadmin/document/document-form/document-form.component';
import { DocumentInnerFormComponent } from './osadmin/document/document-inner-form/document-inner-form.component';
import { DocumentItemComponent } from './osadmin/document/document-item/document-item.component';
import { DocumentListComponent } from './osadmin/document/document-list/document-list.component';
import { DocumentComponent } from './osadmin/document/document.component';
import { GroupDocumentListComponent } from './osadmin/document/group-document-list/group-document-list.component';
import { UserDocumentListComponent } from './osadmin/document/user-document-list/user-document-list.component';
import { FileComponent } from './osadmin/file/file.component';
import { GroupFormComponent } from './osadmin/group/group-form/group-form.component';
import { GroupFullComponent } from './osadmin/group/group-full/group-full.component';
import { GroupItemComponent } from './osadmin/group/group-item/group-item.component';
import { GroupListComponent } from './osadmin/group/group-list/group-list.component';
import { GroupMemberListComponent } from './osadmin/group/group-member-list/group-member-list.component';
import { GroupTopicWidgetListComponent } from './osadmin/group/group-topic-widget-list/group-topic-widget-list.component';
import { GroupComponent } from './osadmin/group/group.component';
import { TopicFileListComponent } from './osadmin/group/topic-file-list/topic-file-list.component';
import { TopicFormComponent } from './osadmin/group/topic-form/topic-form.component';
import { TopicFullComponent } from './osadmin/group/topic-full/topic-full.component';
import { TopicItemComponent } from './osadmin/group/topic-item/topic-item.component';
import { TopicListComponent } from './osadmin/group/topic-list/topic-list.component';
import { UserGroupListComponent } from './osadmin/group/user-group-list/user-group-list.component';
import { LanguageFormComponent } from './osadmin/language/language-form/language-form.component';
import { LanguageFullComponent } from './osadmin/language/language-full/language-full.component';
import { LanguageListComponent } from './osadmin/language/language-list/language-list.component';
import { LanguageComponent } from './osadmin/language/language.component';
import { TranslationKeyFormComponent } from './osadmin/language/translation-key-form/translation-key-form.component';
import { TranslationKeyListComponent } from './osadmin/language/translation-key-list/translation-key-list.component';
import { NewsFormComponent } from './osadmin/news/news-form/news-form.component';
import { NewsItemComponent } from './osadmin/news/news-item/news-item.component';
import { NewsComponent } from './osadmin/news/news.component';
import { DelegationFormComponent } from './osadmin/office/delegation-form/delegation-form.component';
import { DelegationListComponent } from './osadmin/office/delegation-list/delegation-list.component';
import { OfficeFormComponent } from './osadmin/office/office-form/office-form.component';
import { OfficeFullComponent } from './osadmin/office/office-full/office-full.component';
import { OfficeListComponent } from './osadmin/office/office-list/office-list.component';
import { OfficeComponent } from './osadmin/office/office.component';
import { SettingsComponent } from './osadmin/settings/settings.component';
import { SkillModalComponent } from './osadmin/skill/skill-modal/skill-modal.component';
import { SkillComponent } from './osadmin/skill/skill.component';
import { ThemeFormComponent } from './osadmin/theming/theme-form/theme-form.component';
import { ThemeFullComponent } from './osadmin/theming/theme-full/theme-full.component';
import { ThemeItemComponent } from './osadmin/theming/theme-item/theme-item.component';
import { ThemeListComponent } from './osadmin/theming/theme-list/theme-list.component';
import { ThemingComponent } from './osadmin/theming/theming.component';
import { VariableFormComponent } from './osadmin/theming/variable-form/variable-form.component';
import { VariableItemComponent } from './osadmin/theming/variable-item/variable-item.component';
import { VariableListComponent } from './osadmin/theming/variable-list/variable-list.component';
import { AddGroupMemberComponent } from './osadmin/user/add-group-member/add-group-member.component';
import { ChangePasswordComponent } from './osadmin/user/change-password/change-password.component';
import { EmailChangeModalComponent } from './osadmin/user/email-change-modal/email-change-modal.component';
import { PasswordFormComponent } from './osadmin/user/password-form/password-form.component';
import { UserCreationModalComponent } from './osadmin/user/user-creation-modal/user-creation-modal.component';
import { UserFavoriteListComponent } from './osadmin/user/user-favorite-list/user-favorite-list.component';
import { UserFormComponent } from './osadmin/user/user-form/user-form.component';
import { UserFullComponent } from './osadmin/user/user-full/user-full.component';
import { UserItemSmallComponent } from './osadmin/user/user-item-small/user-item-small.component';
import { UserListComponent } from './osadmin/user/user-list/user-list.component';
import { UserComponent } from './osadmin/user/user.component';
import { ChangeEmailComponent } from './registration/change-email/change-email.component';
import { ForgotPasswordComponent } from './registration/forgot-password/forgot-password.component';
import { InvalidDomainComponent } from './registration/invalid-domain/invalid-domain.component';
import { RegistrationComponent } from './registration/registration.component';
import { StepFiveComponent } from './registration/step-five/step-five.component';
import { StepFourComponent } from './registration/step-four/step-four.component';
import { StepOneComponent } from './registration/step-one/step-one.component';
import { StepThreeComponent } from './registration/step-three/step-three.component';
import { StepTwoComponent } from './registration/step-two/step-two.component';
import { SuccessComponent } from './registration/success/success.component';
import { ConfigService } from './service/config.service';
import { DocumentService } from './service/document.service';
import { EventService } from './service/event.service';
import { GroupService } from './service/group.service';
import { LanguageService } from './service/language.service';
import { LinkGroupService } from './service/link-group.service';
import { ModalService } from './service/modal.service';
import { NewsService } from './service/news.service';
import { OfficeService } from './service/office.service';
import { PublicDataService } from './service/public-data.service';
import { SearchService } from './service/search.service';
import { SkillService } from './service/skill.service';
import { StorageService } from './service/storage.service';
import { ThemeService } from './service/theme.service';
import { UserService } from './service/user.service';
import { WebsiteService } from './service/website.service';
import { AclComponent } from './shared/acl/acl.component';
import { DeleteButtonComponent } from './shared/buttons/delete-button/delete-button.component';
import { EditButtonComponent } from './shared/buttons/edit-button/edit-button.component';
import { SwitchButtonComponent } from './shared/buttons/switch-button/switch-button.component';
import { ViewButtonComponent } from './shared/buttons/view-button/view-button.component';
import { CalendarWidgetComponent } from './shared/calendar-widget/calendar-widget.component';
import { CookieConsentComponent } from './shared/cookie-consent/cookie-consent.component';
import { DelegationSelectorComponent } from './shared/delegation-selector/delegation-selector.component';
import { ForbiddenComponent } from './shared/forbidden/forbidden.component';
import { FunctionInOfficeSelectorWrapperComponent } from './shared/function-in-office-selector-wrapper/function-in-office-selector-wrapper.component';
import { FunctionInOfficeSelectorComponent } from './shared/function-in-office-selector/function-in-office-selector.component';
import { FunctionInOfficeComponent } from './shared/function-in-office/function-in-office.component';
import { GenderSelectorComponent } from './shared/gender-selector/gender-selector.component';
import { GroupSelectorComponent } from './shared/group-selector/group-selector.component';
import { GroupTypeSelectorComponent } from './shared/group-type-selector/group-type-selector.component';
import { InfiniteScrollLoaderComponent } from './shared/infinite-scroll-loader/infinite-scroll-loader.component';
import { Nl2BrPipe } from './shared/nl2-br.pipe';
import { ObjectFitComponent } from './shared/object-fit/object-fit.component';
import { OfficeFunctionSelectorComponent } from './shared/office-function-selector/office-function-selector.component';
import { OfficeSelectorComponent } from './shared/office-selector/office-selector.component';
import { PrivacyTypeSelectorComponent } from './shared/privacy-type-selector/privacy-type-selector.component';
import { RoleSelectorComponent } from './shared/role-selector/role-selector.component';
import { SearchBarComponent } from './shared/search-bar/search-bar.component';
import { SizeableComponent } from './shared/sizeable/sizeable.component';
import { SkillFormComponent } from './shared/skill-form/skill-form.component';
import { SkillSelectorComponent } from './shared/skill-selector/skill-selector.component';
import { UserSelectorComponent } from './shared/user-selector/user-selector.component';
import { LogoutBtnComponent } from './user/logout-btn/logout-btn.component';
import { MiniMeComponent } from './user/mini-me/mini-me.component';
import { MoreValidators } from './validation/more-validators';
import { MatGoogleMapsAutocompleteModule } from '@angular-material-extensions/google-maps-autocomplete';
import { MessageFeedForumItemComponent } from './message-feed/message-feed-forum-item/message-feed-forum-item.component';
import { MessageFeedEventItemComponent } from './message-feed/message-feed-event-item/message-feed-event-item.component';
import { CommentForumFeedItemComponent } from './feed/feed-item/comment-forum-feed-item/comment-forum-feed-item.component';
import { CommentEventFeedItemComponent } from './feed/feed-item/comment-event-feed-item/comment-event-feed-item.component';
import { ScrollDispatchModule } from '@angular/cdk/scrolling';
import { GroupMemberAvatarWrapperComponent } from './osadmin/group/group-member-avatar-wrapper/group-member-avatar-wrapper.component';
import { ExpandableDirective } from './shared/expandable.directive';
import { DocumentSelectorComponent } from './shared/document-selector/document-selector.component';
import { FolderSelectorComponent } from './shared/folder-selector/folder-selector.component';
// Folders
import { DocumentFullComponent } from './osadmin/document/document-full/document-full.component';
import { FolderCreateOrEditModalComponent } from './osadmin/document/folder-create-or-edit-modal/folder-create-or-edit-modal.component';


@NgModule({
    declarations: [
        AppComponent,
        LoginComponent,
        HomeComponent,
        AdminBarComponent,
        DashboardComponent,
        CalendarComponent,
        DocumentComponent,
        FileComponent,
        OfficeComponent,
        UserComponent,
        GroupComponent,
        SettingsComponent,
        OfficeFormComponent,
        OfficeListComponent,
        OfficeFullComponent,
        DelegationFormComponent,
        DelegationListComponent,
        UserListComponent,
        UserFormComponent,
        RegistrationComponent,
        StepOneComponent,
        StepTwoComponent,
        StepThreeComponent,
        StepFourComponent,
        StepFiveComponent,
        LanguageComponent,
        LanguageFormComponent,
        LanguageListComponent,
        LanguageFullComponent,
        TranslationKeyFormComponent,
        TranslationKeyListComponent,
        ForgotPasswordComponent,
        CalendarFormComponent,
        CalendarListComponent,
        OfficeSelectorComponent,
        ObjectFitComponent,
        SearchBarComponent,
        NavComponent,
        UserFullComponent,
        CookieConsentComponent,
        GroupFormComponent,
        TopicFormComponent,
        GroupListComponent,
        GroupTypeSelectorComponent,
        PrivacyTypeSelectorComponent,
        GroupFullComponent,
        GroupItemComponent,
        TopicFullComponent,
        UserSelectorComponent,
        TopicListComponent,
        TopicItemComponent,
        GroupMemberListComponent,
        DocumentListComponent,
        DocumentItemComponent,
        DocumentFormComponent,
        SkillSelectorComponent,
        CalendarItemComponent,
        CalendarRightGutterComponent,
        PasswordFormComponent,
        FooterComponent,
        GenderSelectorComponent,
        InvalidDomainComponent,
        SuccessComponent,
        SizeableComponent,
        ThemingComponent,
        ThemeListComponent,
        ThemeFullComponent,
        VariableListComponent,
        VariableFormComponent,
        ThemeFormComponent,
        ThemeItemComponent,
        VariableItemComponent,
        ForbiddenComponent,
        InfiniteScrollLoaderComponent,
        FeedComponent,
        FeedListComponent,
        FeedItemComponent,
        GroupFeedItemComponent,
        GroupedFeedItemComponent,
        MessageFeedItemComponent,
        TopicFeedItemComponent,
        NotFoundComponent,
        DelegationSelectorComponent,
        SkillFormComponent,
        UserGroupListComponent,
        UserDocumentListComponent,
        FunctionInOfficeComponent,
        FunctionInOfficeSelectorComponent,
        FunctionInOfficeSelectorWrapperComponent,
        UserFavoriteListComponent,
        ChangePasswordComponent,
        SkillComponent,
        SkillModalComponent,
        MessageFeedComponent,
        UserCreationModalComponent,
        RoleSelectorComponent,
        AclComponent,
        OfficeFunctionSelectorComponent,
        MediaFeedItemComponent,
        EmailChangeModalComponent,
        ChangeEmailComponent,
        LinkGroupsComponent,
        LinkGroupFormComponent,
        LinkGroupComponent,
        LinkFormComponent,
        TopicFileListComponent,
        NewsComponent,
        NewsItemComponent,
        NewsFormComponent,
        CalendarFullComponent,
        CommentFeedItemComponent,
        Nl2BrPipe,
        MainLoggedComponent,
        MainUnloggedComponent,
        HeaderComponent,
        LogoutBtnComponent,
        MiniMeComponent,
        CalendarWidgetComponent,
        CalendarInnerFormComponent,
        GroupSelectorComponent,
        DocumentInnerFormComponent,
        UserItemSmallComponent,
        ForumComponent,
        ForumItemComponent,
        ForumFormComponent,
        ForumCommentFormComponent,
        EditButtonComponent,
        DeleteButtonComponent,
        SwitchButtonComponent,
        UserForumListComponent,
        GroupTopicWidgetListComponent,
        ForumFullComponent,
        GroupEventsListComponent,
        AddGroupMemberComponent,
        GroupDocumentListComponent,
        ViewButtonComponent,
        LanguageFormComponent,
        ForumFeedItemComponent,
        EventFeedItemComponent,
        EventCommentFormComponent,
        MessageFeedForumItemComponent,
        MessageFeedEventItemComponent,
        CommentForumFeedItemComponent,
        CommentEventFeedItemComponent,
        GroupMemberAvatarWrapperComponent,
        ExpandableDirective,
        DocumentSelectorComponent,
        FolderSelectorComponent,
        // Folders
        DocumentFullComponent,
        FolderCreateOrEditModalComponent,
    ],
    entryComponents: [
        ChangePasswordComponent,
        SkillModalComponent,
        UserCreationModalComponent,
        TranslationKeyFormComponent,
        OfficeFormComponent,
        DelegationFormComponent,
        EmailChangeModalComponent,
        LinkGroupFormComponent,
        LinkFormComponent,
        NewsFormComponent,
        DocumentFormComponent,
        CalendarFormComponent,
        ForumFormComponent,
        ForumCommentFormComponent,
        AddGroupMemberComponent,
        LanguageFormComponent,
        EventCommentFormComponent,
        // Folders
        FolderCreateOrEditModalComponent,
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        CalendarModule.forRoot({
            provide: DateAdapter,
            useFactory: adapterFactory
        }),
        FormsModule,
        HttpClientModule,
        RouterModule.forRoot(appRoutes),
        ReactiveFormsModule,
        MomentModule,
        FileUploadModule,
        // SelectModule,
        NgSelectModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: (createTranslateLoader),
                deps: [HttpClient]
            }
        }),
        BsDropdownModule.forRoot(),
        TabsModule.forRoot(),
        ModalModule.forRoot(),
        Ng2DatetimePickerModule,
        ToastrModule.forRoot(),
        InfiniteScrollModule,
        NguiAutoCompleteModule,
        PopoverModule,
        ClipboardModule,
        ColorPickerModule,
        CKEditorModule,
        UiSwitchModule,
        OwlDateTimeModule,
        OwlNativeDateTimeModule,
        OwlMomentDateTimeModule,
        MatSelectModule,
        ScrollDispatchModule,
        TooltipModule,
        AgmCoreModule.forRoot({
            apiKey: environment.googleKey,
            libraries: ['places']
        }),
        MatGoogleMapsAutocompleteModule.forRoot(),
        ChatModule.forRoot({
            provider: StorageService,
            userLocator: UserService,
            url: environment.chatSocketURL,
            staticAssetsBase: environment.mediaUrl,
            messageAttachmentBase: `${environment.api}/chat/messages/attachments`
        }),
        SharedModule
    ],
    providers: [
        UserService,
        StorageService,
        OfficeService,
        PublicDataService,
        LanguageService,
        EventService,
        GroupService,
        DocumentService,
        SkillService,
        ConfigService,
        ThemeService,
        FeedService,
        SearchService,
        WebsiteService,
        MoreValidators,
        ModalService,
        LinkGroupService,
        NewsService,
        { provide: HTTP_INTERCEPTORS, useClass: ApiCallInterceptor, multi: true },
        { provide: APP_INITIALIZER, useFactory: appInit, deps: [WebsiteService, ConfigService], multi: true },
        { provide: OwlDateTimeIntl, useClass: DefaultIntl },
        { provide: OWL_DATE_TIME_LOCALE, useValue: 'fr' },
        { provide: OWL_DATE_TIME_FORMATS, useValue: MY_MOMENT_FORMATS },
        AuthGuard,
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }

export function createTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(http, Settings.MESSAGES, '.json');
}
