import { Component, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { NewsService } from '../../service/news.service';
import { ModalService } from '../../service/modal.service';
import { NewsFormComponent } from './news-form/news-form.component';
import * as _ from 'lodash';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-news',
    templateUrl: './news.component.html',
    styleUrls: ['./news.component.scss']
})
export class NewsComponent implements OnInit {

    news: any[] = [];
    timestamp = new Date().getTime();
    @ViewChild(NewsFormComponent, {static: true})
    public newsForm: NewsFormComponent;

    constructor(private newsService: NewsService) {
    }

    ngOnInit() {
        this.newsService.getAllNews().subscribe((news: any[]) => this.news = news);
    }
    
    newsSaved(news) {
        if (this.news.some(n => n.id === news.id)) {
            this.news = this.news.map(n => {
                if (n.id !== news.id) {
                    return n;
                }
                return news;
            })
        } else {
            this.news = [news].concat(this.news);
        }
    }
    

    onEditNews(news: any) {
        this.newsForm.form.patchValue(news);
    }

    onNewsDeleted(id: any) {
        _.remove(this.news, n => (<any>n).id == id);
    }

    ordered(news: any) {
        return _.reverse(_.sortBy(news, ['updated']));
    }
}
