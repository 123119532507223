import { Component, OnInit } from '@angular/core';
import { ConfigService } from '../../service/config.service';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

    config;

    constructor(private configService: ConfigService) {
    }

    ngOnInit() {
        this.configService.configObs.subscribe(cfg => this.config = cfg);
    }

}
