import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { ChatReducer } from '../../store/reducers';
import { openChatBar } from '../../store/actions';
import { Conversation } from '../../model/conversation';
import { Subject } from 'rxjs';
import { ChatFormComponent } from '../chat-form/chat-form.component';
import { ChatForm } from '../../forms/chat.form';
import { Validators } from '@angular/forms';
import { ModalService } from '../../../../service/modal.service';
import { switchMap } from 'rxjs/operators';
import { ChatService } from '../../services/chat.service';

@Component({
    selector: 'app-chat-options',
    templateUrl: './chat-options.component.html',
    styleUrls: ['./chat-options.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChatOptionsComponent implements OnChanges {
    
    @Input()
    public conversation: Conversation;
    public formOpen = new Subject<boolean>();
    @ViewChild(ChatFormComponent, { static: true })
    public formComponent: ChatFormComponent;
    private form: ChatForm;
    
    constructor(
        private readonly store: Store<ChatReducer>,
        private readonly modalService: ModalService,
        private readonly chatService: ChatService
    ) {
    }
    
    public goToDiscussions() {
        this.store.dispatch(openChatBar())
    }
    
    public openChatForm() {
        if (this.conversation.oneToOne) {
            this.goToDiscussions();
            return;
        }
        this.formOpen.next(true);
    }
    
    public closeChatForm() {
        this.formOpen.next(false);
    }
    
    public ngOnChanges(changes: SimpleChanges): void {
        if (!this.form) {
            this.form = this.formComponent.form;
        }
        if (changes.conversation) {
            const conversation: Conversation = changes.conversation.currentValue;
            if (conversation.oneToOne) {
                this.form.get('occupants').setValidators(Validators.required);
            } else {
                this.form.get('occupants').setValidators(null);
            }
            this.form.patchValue({
                id: conversation.id,
                label: conversation.autoLabel ? null : conversation.label
            });
        }
    }
    
    public quit() {
        this.modalService
            .confirm('chat.actions.quit.title', 'chat.actions.quit.message')
            .pipe(
                switchMap(() => this.chatService.quit(this.conversation.id))
            )
            .subscribe();
    }
}
