import { Component, OnInit, ViewContainerRef } from '@angular/core';
import { OfficeService } from '../../service/office.service';
import { ModalService } from '../../service/modal.service';
import { OfficeFormComponent } from './office-form/office-form.component';
import * as _ from 'lodash';
import { FormGroup, FormControl } from '@angular/forms';

@Component({
    selector: 'app-office',
    templateUrl: './office.component.html',
    styleUrls: ['./office.component.scss']
})
export class OfficeComponent implements OnInit {

    form: FormGroup = new FormGroup({
        page: new FormControl(1)
    });

    constructor(private officeService: OfficeService,
        private modalService: ModalService,
        private vcr: ViewContainerRef) { }

    offices;

    ngOnInit() {
        this.form.valueChanges.subscribe(() => this.load());
        this.load();
    }

    private load() {
        this.officeService.getPageableOffices(this.form.value).subscribe(
            (offices) => {
                if (this.form.get('page').value > 1) {
                    this.offices.entities = this.offices.entities.concat(offices.entities);
                } else {
                    this.offices = offices;
                }
            });
    }

    onOffice(office) {
        var modal = this.modalService.openModal(OfficeFormComponent, this.vcr);
        modal.instance.onClose.subscribe((off) => {
            modal.destroy();
            var offices = _.filter(this.offices.entities, (e: any) => {
                return e.id == off.id;
            });
            if (offices.length > 0) {
                offices.map(o => _.extend(o, off));
            } else {
                this.offices.entities.push(off);
            }
        });

        modal.instance.onHide.subscribe(() => {
            modal.destroy();
        });

        if (office) {
            let d = _.clone(office);
            d.allowedDomains = (d.allowedDomains || []).join("\n");
            modal.instance.form.patchValue(d);
        }
    }

    onScroll() {
        if (_.get(this.offices, "entities.length") != _.get(this.offices, "count"))
        this.form.patchValue({ page: ++this.form.value.page });
    }

}
