import { FormControl, FormGroup } from '@angular/forms';

export class Delegation {
    static form(){
        return new FormGroup({
            id: new FormControl(),
            title: new FormControl(),
            url: new FormControl(),
            uri: new FormControl(),
            active: new FormControl(),
            department: new FormControl()
        });
    }
}
