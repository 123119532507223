import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'computedLabel'
})
export class ComputedLabelPipe implements PipeTransform {

  transform(value: string, args?: any): any {
    return value.split(',')
        .slice(0, 2)
        .map(word => word.charAt(0))
        .join('');
  }

}
