import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { UserService } from '../../service/user.service';
import * as _ from 'lodash';
import { SelectComponent } from 'ng2-select/index';
import { NgSelectComponent } from '@ng-select/ng-select';
import { FormGroup, FormControl } from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, tap, switchMap } from 'rxjs/operators';

@Component({
    selector: 'app-user-selector',
    templateUrl: './user-selector.component.html',
    styleUrls: ['./user-selector.component.scss']
})
export class UserSelectorComponent implements OnInit {

    @Output()
    onDataSelected: EventEmitter<any> = new EventEmitter<any>();

    usersPageable = null;
    isLoading = false;

    @ViewChild(NgSelectComponent, { static: true })
    select: NgSelectComponent;

    formPager: FormGroup = new FormGroup({
        page: new FormControl(1),
        q: new FormControl("")
    });

    constructor(private userService: UserService) {
    }

    ngOnInit() {
        this.load();
    }

    private load() {
        this.isLoading = true;
        this.userService.getUsers(this.formPager.value).subscribe(
            (users) => {
                this.mapUsers(users);
                this.isLoading = false;
            }
        );
    }

    mapUsers(data) {
        if (this.formPager.get('page').value > 1) {
            this.usersPageable.entities = this.usersPageable.entities.concat(data.entities);
        } else {
            this.usersPageable = data;
        }

        this.usersPageable.entities = this.usersPageable.entities.map(u => {
            let user = _.clone(u);
            user.displayValue = user.firstname + " " + user.lastname;
            return user;
        })
    }

    search(search) {
        this.formPager.patchValue({ page: 1 });
        this.formPager.patchValue({ q: search.term });
        this.load();
    }

    searchFn(term, item) {
        return true;
    }

    onAdd() {
        if (this.formPager.get('q').value !== "")
            this.search("");
    }

    onScrollEnd() {
        if (!this.isLastPage()) {
            this.formPager.patchValue({ page: ++this.formPager.value.page });
            this.load();
        }
    }

    private isLastPage(): boolean {
        return (_.get(this.usersPageable, "entities.length") == _.get(this.usersPageable, "count"));
    }

    onData(data) {
        this.onDataSelected.emit(_.transform(data, (r, d) => {
            r.push(d['id']);
            return true;
        }, []));
    }

    clear() {
        this.select.writeValue(null);
    }
}
