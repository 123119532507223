import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Attachment } from '../../model/attachment';

@Component({
    selector: 'app-chat-attachment-list',
    templateUrl: './chat-attachment-list.component.html',
    styleUrls: ['./chat-attachment-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChatAttachmentListComponent {
    
    @Input()
    public attachments: Attachment[];
}
