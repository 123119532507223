import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'app-variable-list',
    templateUrl: './variable-list.component.html',
    styleUrls: ['./variable-list.component.scss']
})
export class VariableListComponent implements OnInit {

    @Input()
    variables;
    @Input()
    theme;
    @Output()
    onEdit: EventEmitter<any> = new EventEmitter<any>();

    constructor() {
    }

    ngOnInit() {
    }

    edit(variable){
        this.onEdit.emit(variable);
    }

}
