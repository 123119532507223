import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { Sender } from '../model/sender';

export const I_USER_LOCATOR = new InjectionToken('IUserLocator');

export interface IUserLocator {
    findUser(term: string): Observable<any[]>;
    currentUser(): Sender;
    currentUserAsync(): Observable<Sender>;
}
