import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { OfficeService } from '../../../service/office.service';
import { environment } from '../../../../environments/environment';
import { Router } from '@angular/router';
import { ModalService } from '../../../service/modal.service';

@Component({
    selector: 'office-list',
    templateUrl: './office-list.component.html',
    styleUrls: ['./office-list.component.scss']
})
export class OfficeListComponent implements OnInit {

    @Input('pageable')
    pageable;
    @Output()
    onOffice: EventEmitter<any> = new EventEmitter();

    constructor(
        private officeService: OfficeService,
        private modalService: ModalService,
        private router: Router) { }

    ngOnInit() { }

    delete(office, index) {
        this.modalService.confirm('office.delete.confirmation.title', 'office.delete.confirmation.content').subscribe(
            () => {
                this.officeService.deleteOffice(office.id).subscribe(res => {
                    this.pageable.entities.splice(index, 1);
                    this.pageable.count--;
                });
            }
        );
    }

    editOffice(office) {
        this.onOffice.emit(office);
    }

    get envApi() {
        return environment.api;
    }

    onViewOffice(office) {
        this.router.navigate(['osadmin/secure/office/view', office.id]);
    }

}
