import { Component, ViewContainerRef } from '@angular/core';
import * as moment from 'moment';
import { TranslateService } from '@ngx-translate/core';
import { ChatService } from './modules/chat/services/chat.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

    constructor(
        private translateService: TranslateService,
        public readonly vcr: ViewContainerRef
    ) {
        translateService.setDefaultLang('fr');
        moment.locale('fr');
    }
}
