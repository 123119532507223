import { Component, OnInit, ViewContainerRef, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from '../../../service/user.service';
import * as _ from 'lodash';
import { User } from '../../../domain/user';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { GroupService } from '../../../service/group.service';
import { PublicDataService } from '../../../service/public-data.service';
import { ModalService } from '../../../service/modal.service';
import { ChangePasswordComponent } from '../change-password/change-password.component';
import { EmailChangeModalComponent } from '../email-change-modal/email-change-modal.component';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../../../environments/environment';
import { ChatService } from '../../../modules/chat/services/chat.service';

@Component({
    selector: 'user-full',
    templateUrl: './user-full.component.html',
    styleUrls: ['./user-full.component.scss']
})
export class UserFullComponent implements OnInit {

    user;
    me;
    editionMode: string = "view";
    form: FormGroup = User.form();
    private favorites;
    socialNetworkTypes;
    phoneMask: any[] = [ /[0]/, /[567]/, ' ', /\d/, /\d/, ' ', /\d/, /\d/, ' ', /\d/, /\d/, ' ', /\d/, /\d/ ];

    constructor(private router: Router,
                private route: ActivatedRoute,
                private userService: UserService,
                private toaster: ToastrService,
                private ts: TranslateService,
                private groupService: GroupService,
                private publicDataService: PublicDataService,
                private modalService: ModalService,
                private vcr: ViewContainerRef,
                private readonly chatService: ChatService
    ) {
    }

    ngOnInit() {

        this.favorites = this.userService.favs;
        this.userService.favoritesObservable.subscribe(u => {
            this.favorites = u;
        });

        this.route.params.subscribe(p => {
            if('id' in p){
                this.userService.loadUser(p['id']).subscribe((u: any) => {
                    this.user = u;
                    this.form.patchValue(u);
                    u.functionInOffices.forEach(fio => {
                        var g = new FormGroup({
                            officeId: new FormControl(),
                            delegationId: new FormControl(),
                            skillId: new FormControl()
                        });
                        g.patchValue(fio);
                        (<FormArray>this.form.get('functionInOffices')).push(g);
                    });
                    (<Array<any>>_.get(u, 'socialNetworkLinks', [])).forEach((link:any) => {
                        var g = new FormGroup({
                            socialNetworkType: new FormControl('', [Validators.required]),
                            link: new FormControl(null, [Validators.required])
                        });
                        g.patchValue(link);
                        (<FormArray>this.form.get('socialNetworkLinks')).push(g);
                    });
                }, err => {
                    this.router.navigate(['/social/directory']);
                });
                this.me = this.userService.u;
                if(('viewMode' in p) && (this.isSelf() || this.isAdmin()) ){
                    this.editionMode = p['viewMode'];
                }else {
                    this.editionMode = 'view';
                }
                this.userService.userObservable.subscribe(u => {
                    this.me = u;
                });
            }
        });
        this.publicDataService.getSocialNewtworkTypes().subscribe(t => this.socialNetworkTypes = t);
    }

    getDisplayName(){
        if(!this.user.firstname && !this.user.lastname){
            return this.user.username;
        }
        return `${this.user.firstname} ${this.user.lastname}`;
    }

    isSelf(){
        return !_.isUndefined(this.me)
            && !_.isUndefined(this.user)
            && this.me.id == this.user.id;
    }

    isAdmin(){
        return _.find(<Array<any>>_.get(this, 'me.roles'), r => r == 'ADMIN');
    }

    isModerator(){
        return _.find(<Array<any>>_.get(this, 'me.roles'), r => r == 'MODERATOR');
    }

    labelFor(him, me){
        return this.isSelf() ? me : him;
    }

    setEditionMode(mode){
        this.editionMode = mode;
    }

    isViewMode(){
        return this.isMode('view');
    }

    isEditionMode(){
        return this.isMode('edition');
    }

    save(){
        this.userService.save(this.form.value).subscribe(u => {
            this.user = u;
            this.form.patchValue(u);
            this.setEditionMode('view');
            var key = this.isSelf() ? "successfullSelfUpdate" : "successfullUserUpdate";
            this.ts.get('toasts.'+key).subscribe(m => {
                this.toaster.success(m);
            });
        });
    }

    get isFavorite() {
        return _.find(this.favorites, f => f['id'] == this.user.id);
    }

    manageFavorite(){
        if(!this.isFavorite){
            this.userService.addToFavorites(this.user.id).subscribe(f => {
                this.ts.get('toasts.addedFavorite', { username: this.getDisplayName() }).subscribe(t => {
                    this.toaster.success(t);
                });
            });
        }else {
            this.userService.removeFromFavorites(this.user.id).subscribe(f => {
                this.ts.get('toasts.removedFavorite', { username: this.getDisplayName() }).subscribe(t => {
                    this.toaster.success(t);
                });
            });
        }
    }

    getStyleLogoOffice(index: number) {
        const top = 20 + (index*90);
        return {
            top: top + 'px'
        };
    }

    get envApi() {
        return environment.api;
    }

    createDiscussion(){
        this.chatService.saveGroup({
            occupants: [this.user]
        }).subscribe();
    }

    addSocialNetwork(){
        (<FormArray>this.form.get('socialNetworkLinks')).push(new FormGroup({
            socialNetworkType: new FormControl('', [Validators.required]),
            link: new FormControl(null, [Validators.required])
        }));
    }

    deleteNetwork(index){
        (<FormArray>this.form.get('socialNetworkLinks')).removeAt(index);
    }

    onFileSelected(e: any){
        let fileList: FileList = e.target.files;
        if(fileList.length > 0 && this.checkIsImage(fileList.item(0))){
            this.userService.uploadUserImage(this.user.id, fileList.item(0)).subscribe((user) => {
                this.user = user;
            });
        }
    }

    checkIsImage(file){
        var validImageTypes = ["image/gif", "image/jpeg", "image/png"];
        return (_.indexOf(validImageTypes, file["type"]) != -1);
    }

    unactivateUser() {
        this.userService.unactivateUser(this.user.id).subscribe(u => {
            _.extend(this.user, u);
            this.ts.get('user.successfullUnactivation').subscribe((ts) => this.toaster.success(ts));
        });
    }

    activateUser() {
        this.userService.activateUser(this.user.id).subscribe(u => {
            _.extend(this.user, u);
            this.ts.get('user.successfullActivation').subscribe((ts) => this.toaster.success(ts));
        });
    }

    changePassword(){
        var modal = this.modalService.openModal(ChangePasswordComponent, this.vcr);
        modal.instance.setForUser(this.user.id);
    }

    sendWelcomeEmail(){
        this.userService.sendWelcomeEmail(this.user.id)
            .subscribe(() => {
                this.ts.get('user.welcomeEmailSuccessfullySent').subscribe((ts) => this.toaster.success(ts));
            });
    }

    private isMode(mode:string) {
        return this.editionMode == mode;
    }

    getSkills(skillType){
        return _.filter(_.get(this, 'user.skills'), (s:any) => s.skillType == skillType);
    }

    changeEmail(){
        var modal = this.modalService.openModal(EmailChangeModalComponent, this.vcr);
        modal.instance.onClose.subscribe((email: any) => {
            if(email.active || !this.user.active){
                this.user.email = email.email;
            }else{
                this.user.pendingAddress = {email: email.email};
            }
        });
        modal.instance["user"] = this.user;
    }

    canEdit(){
        var s = this.isSelf();
        var mea = this.isModeratorEditingAdmin();
        var sea = this.isSubAdminEditingAdmin();
        var meo = this.isModeratorEditingOfficeGuy();
        var sa = this.isSuperAdmin();
        var sba = this.isSubAdmin();
        return (
            s
            || ((sba || sa || meo) && !(mea || sea))
        );
    }

    isModeratorEditingAdmin(){
        return _.find(_.get(this, 'me.roles'), (r:any) => r == 'MODERATOR') && _.find(_.get(this, 'user.roles'), (r:any) => r == 'ADMIN' || r == 'SUB_ADMIN' || r == 'MODERATOR')
    }

    isSubAdminEditingAdmin(){
        return _.find(_.get(this, 'me.roles'), (r:any) => r == 'SUB_ADMIN') && _.find(_.get(this, 'user.roles'), (r:any) => r == 'ADMIN' || r == 'SUB_ADMIN')
    }

    isModeratorEditingOfficeGuy(){
        try {
            var myOfficeIds = (<Array<any>>_.get(this, 'me.functionInOffices')).map((fio:any) => fio.officeId);
            var hisOfficeIds = (<Array<any>>_.get(this, 'user.functionInOffices')).map((fio:any) => fio.officeId);
            return _.intersection(myOfficeIds, hisOfficeIds).length > 0;
        } catch(err) {
            return false;
        }

    }

    deleteUser() {
        this.modalService
            .confirm('user.deletion.title', 'user.deletion.message')
            .subscribe(() => {
                this.userService.deleteUser(this.user.id).subscribe(() => {
                    this.ts.get('user.successfullDeletion').subscribe(ts => {
                       this.toaster.success(ts);
                        this.router.navigate(['/social/directory']);
                    });
                });
            });
    }

    private isSuperAdmin() {
        return _.find(_.get(this, 'me.roles'), (r:any) => r == 'ADMIN');
    }

    private isSubAdmin() {
        return _.find(_.get(this, 'me.roles'), (r:any) => r == 'SUB_ADMIN');
    }
}
