import { Injectable } from '@angular/core';
import { Settings } from '../app.settings';
import * as _ from 'lodash';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Pageable } from '../shared/model/pageable';
import { toHttpParams, Utils } from '../shared/utils';

@Injectable()
export class OfficeService {

    constructor(private http: HttpClient){}

    getOffices(isRoleFiltered = false): Observable<Pageable<any>> {
        let params = toHttpParams({isRoleFiltered: isRoleFiltered, sortOrder: "{title: 1}"});
        return this.http.get<Pageable<any>>(Settings.OFFICES, { params });
    }

    getPageableOffices(value: any): Observable<Pageable<any>> {
        let params = toHttpParams(value);
        
        return this.http.get<Pageable<any>>(Settings.OFFICES, {params});
    }

    save(data:any) {
        let method = data.id ? "post" : "post";
        let suffix = data.id ? `/${data.id}` : "";
        let formData = new FormData();

        for(var key in data){
            if(!_.isNull(data[key]) && !_.isUndefined(data[key])){
                formData.append(key, data[key]);
            }
        }

        return this.http[method](Settings.OFFICES + suffix, formData);
    }

    getOffice(id:any): Observable<any> {
        return this.http.get(Settings.OFFICES + "/" +id);
    }

    saveDelegation(officeId:any, delegation:any) {
        let method = delegation.id ? "put" : "post";
        let suffix = delegation.id ? `/${delegation.id}` : "";
        return this.http[method](Settings.DELEGATION + `/${officeId}` + suffix, delegation);
    }

    deleteDelegation(officeId:string, delegation:any) {
        return this.http.delete(Settings.DELEGATION + `/${officeId}/${delegation.id}`);
    }

    getDelegation(officeId:any, delegationId:any) {
        return this.http.get(Settings.DELEGATION + `/${officeId}/${delegationId}`);
    }

    deleteOffice(officeId:any) {
        return this.http.delete(Settings.OFFICES + `/${officeId}`);
    }
}
