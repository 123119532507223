import { Component, Input, OnInit } from '@angular/core';
import { GroupService } from '../../../service/group.service';
import { Router } from '@angular/router';

@Component({
    selector: 'app-comment-feed-item',
    templateUrl: './comment-feed-item.component.html',
    styleUrls: ['./comment-feed-item.component.scss']
})
export class CommentFeedItemComponent implements OnInit {

    @Input()
    item;

    topic = null;

    constructor(
        private groupService: GroupService,
        private router: Router) {

    }

    ngOnInit() {
        this.groupService.loadTopic(this.item.data.comment.topicId).subscribe(t => this.topic = t);
    }

    manageRedirect() {        
        this.router.navigateByUrl(
            '/social/' + this.topic.group.groupType.toLowerCase() + '/topic/' + this.topic.id,
            { state: { showComment: true } });
    }

}
