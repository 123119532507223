import { Injectable } from '@angular/core';
import { Settings } from '../app.settings';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class SkillService {
    
    constructor(private http: HttpClient) {
    }
    
    getSkills() {
        return this.http.get(Settings.SKILL);
    }
    
    save(value: any) {
        let verb = value.id ? 'put' : 'post';
        let prefix = value.id ? '/' + value.id : '';
        return this.http[verb](Settings.SKILL + prefix, value);
    }
    
    remove(id: any) {
        return this.http.delete(Settings.SKILL + '/' + id);
    }
}
