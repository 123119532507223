import { WebsiteService } from './service/website.service';
import { catchError, map, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { ConfigService } from './service/config.service';

export function appInit(websiteService: WebsiteService, configService: ConfigService) {
    return (): Promise<any> => {
        return websiteService.getWebsite().pipe(
            switchMap(_ => configService.loadConfig()),
            map(() => true),
            catchError(() => of(false))
        ).toPromise();
    };
}
