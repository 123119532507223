import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { AbstractModal } from '../../shared/abstract-modal';
import { ForumComment } from '../../domain/forum-comment';
import { ForumService } from '../../service/forum.service';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { UserService } from '../../service/user.service';
import { FormGroup } from '@angular/forms';
import * as _ from 'lodash';

@Component({
  selector: 'app-forum-comment-form',
  templateUrl: './forum-comment-form.component.html',
  styleUrls: ['./forum-comment-form.component.scss']
})
export class ForumCommentFormComponent extends AbstractModal {

  form: FormGroup = ForumComment.form();
  user: any;

  constructor(
    private forumService: ForumService,
    private ts: TranslateService,
    private toaster: ToastrService,
    private userService: UserService
  ) {
    super();
    this.user = this.userService.userObservable;
  }

  ngOnInit() {
  }

  save() {
    this.forumService.saveComment(this.form.value.postId, this.form.value).subscribe(post => {
      this.ts.get(`toasts.successfullForumPostEdition`).subscribe(ts => this.toaster.success(ts));
      this.close(post);
    });
  }

  isAdmin() {
    return this.user && _.find(this.user.roles, r => r == 'ADMIN');
  }

}
