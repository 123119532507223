import { Component, ElementRef, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as _ from 'lodash';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { GroupService } from '../../../service/group.service';
import { ModalService } from '../../../service/modal.service';
import { UserService } from '../../../service/user.service';
import { GroupDocumentListComponent } from '../../document/group-document-list/group-document-list.component';
import { AddGroupMemberComponent } from '../../user/add-group-member/add-group-member.component';
import { GroupMemberListComponent } from '../group-member-list/group-member-list.component';
import { GroupTopicWidgetListComponent } from '../group-topic-widget-list/group-topic-widget-list.component';

@Component({
    selector: 'app-group-full',
    templateUrl: './group-full.component.html',
    styleUrls: ['./group-full.component.scss']
})
export class GroupFullComponent implements OnInit {

    groupId;
    topics;
    group;
    private page: number = 1;

    @ViewChild(GroupMemberListComponent, { static: false })
    private memberList;

    @ViewChild('fileInput', { static: false })
    fileInput: ElementRef;

    @ViewChild(GroupTopicWidgetListComponent, { static: false })
    groupTopics: GroupTopicWidgetListComponent;

    @ViewChild(GroupDocumentListComponent, { static: false })
    groupDocs: GroupDocumentListComponent;

    private user;

    private state$: Observable<object>;

    private _isEdition = false;

    showUsers = "all";
    usersToShow: any[] = [];
    favorites: any[] = [];

    constructor(private route: ActivatedRoute,
        private groupService: GroupService,
        private router: Router,
        private userService: UserService,
        private modalService: ModalService,
        private vcr: ViewContainerRef) {
    }

    ngOnInit() {
        this.page = 1;
        this.route.params.subscribe(p => {
            this.groupId = p['id'];
            this.groupService.loadGroup(this.groupId).subscribe(g => {
                this.group = g;
                this._reloadShowUsers();

            }, e => {
                this.router.navigate(["/"]);
            });
            this.groupService.getTopics(this.groupId, this.page).subscribe(t => {
                this.topics = t;
            });
        });

        this.state$ = this.route.paramMap
            .pipe(map(() => window.history.state));


        this.state$.subscribe(
            (state: any) => {
                if (state.edit)
                    this._isEdition = state.edit;
            }
        );

        this.favorites = this.userService.favs;
        this.userService.favoritesObservable.subscribe(f => {
            this.favorites = f;
        });
    }

    onAddGroupFile() {
        this.groupDocs.forceReload();
    }

    get envApi() {
        return environment.api;
    }

    get isEdition() {
        return (this.isAdmin() || this.isSelf()) && this._isEdition;
    }

    toggleEdition() {
        this._isEdition = !this._isEdition;
    }

    showAllUsers() {
        this.showUsers = "all";
        this._reloadShowUsers();
    }

    get isShowAll() {
        return this.showUsers === "all";
    }

    showFavoritesUsers() {
        this.showUsers = "favs"
        this._reloadShowUsers();
    }

    get isShowFavorites() {
        return this.showUsers === "favs";
    }

    get favoritesCount() {
        if (this.group)
            return this.group.members.filter(id => this.favorites.find(fav => fav['id'] === id)).length;
        else
            return "";
    }

    private _reloadShowUsers() {
        if (this.isShowAll) {
            this.usersToShow = this.group.members;
        }
        else
            this.usersToShow = this.group.members.filter(id => this.favorites.find(fav => fav['id'] === id));
    }

    onTopic(topic) {
        this.topics.entities.push(topic);
        this.groupTopics.load();
    }

    isMember() {
        return this.group && this.group.joined;
    }

    isAdmin() {
        return _.find(<any>(_.get(this, 'userService.u.roles') || []), r => (r == 'ADMIN' || r == 'MODERATOR' || r == 'SUB_ADMIN'));
    }

    isSelf() {
        return _.get(this.userService, 'u.id') == _.get(this.group, 'creator');
    }

    joinGroup(groupId) {
        if (!this.isMember()) {
            this.groupService.addToGroup(groupId).subscribe(subscription => {
                this.group.joined = true;
                this.memberList.members.entities.push(this.userService.u);
                this.memberList.members.count += 1;
                this.group.members.push(this.userService.u.id);
            });
        } else {
            this.groupService.removeFromGroup(groupId).subscribe(subscription => {
                this.group.joined = false;
                _.remove(this.memberList.members.entities, (e: any) => {
                    if (e.id == this.userService.u.id) {
                        this.memberList.members.count -= 1;
                        return true;
                    }
                    return false;
                });
                _.remove(this.group.members, (m) => {
                    return m == this.userService.u.id;
                });
            });
        }
    }

    onScroll() {
        this.groupService.getTopics(this.groupId, ++this.page).subscribe(t => {
            this.topics.entities = this.topics.entities.concat((<any>t).entities);
        });
    }


    onFileSelected(e) {
        if (_.get(e, 'target.files.length') > 0) {
            this.groupService.uploadGroupImage(this.group.id, e.target.files[0]).subscribe((g: any) => {
                this.group.picture = g.picture;
            });
        }
    }

    fireClickFile() {
        if (this.isEdition) {
            this.fileInput.nativeElement.click();
        }
    }

    onDeleteTopics(ids: string[]) {
        ids.forEach(id => {
            const topics = _.remove(this.topics.entities, (t: any) => t.id === id);
            this.topics.count -= topics.length;
        });
    }

    showAddUser() {
        var modal = this.modalService.openModal(AddGroupMemberComponent, this.vcr);
        modal.instance.onDiscard.subscribe(() => modal.destroy());

        modal.instance.changeMembers.subscribe(
            (members) => {
                this.group.members = members;
                this._reloadShowUsers();
            }
        );

        modal.instance.group = this.group;
    }
}
