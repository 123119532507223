import { Component, OnInit, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';
import { GroupService } from '../../../service/group.service';
import { ActivatedRoute } from '@angular/router';
import { environment } from '../../../../environments/environment';
import { ModalService } from '../../../service/modal.service';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'app-group-topic-widget-list',
  templateUrl: './group-topic-widget-list.component.html',
  styleUrls: ['./group-topic-widget-list.component.scss']
})
export class GroupTopicWidgetListComponent implements OnInit {

  @Input()
  title;

  @Input()
  isEdit: boolean = false;

  @Output()
  delete = new EventEmitter<string[]>();

  private groupId;
  pageable;
  page: number = 1;
  public loading = false;

  private mapChecked = new Map<string, boolean>();

  constructor(private groupService: GroupService,
    private route: ActivatedRoute,
    private modalService: ModalService) {
  }

  ngOnInit() {
    this.route.params.subscribe(p => {
      if ('id' in p) {
        this.groupId = p["id"];
        this.load();
      }
    });
  }

  load() {
    this.groupService.getTopics(this.groupId, this.page).subscribe((d: any) => {
      this.pageable = d;
      this.pageable.entities = d.entities.slice(0, 5);
      this.pageable.entities.forEach(t => this.mapChecked.set(t.id, false));
    });
  }

  get envApi() {
    return environment.api;
  }

  onTopicChecked(topic) {
    this.mapChecked.set(topic.id, !this.mapChecked.get(topic.id));
  }

  onDelete() {
    this.modalService
      .confirm('forumcomment.deletion.title', 'forumcomment.deletion.message')
      .subscribe(() => {

        let ids = [];
        let obs = [];
        this.mapChecked.forEach((value, key) => {
          if (value) {
            ids.push(key);
            obs.push(this.groupService.deleteTopic(key));
          }
        });

        if (ids.length > 0) {

          forkJoin(obs).subscribe(
            () => {
              this.delete.emit(ids);
              this.load();
            }
          )
        }
      });

  }

}
