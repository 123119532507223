import { Component, ElementRef, OnInit } from '@angular/core';
import * as $ from 'jquery';

@Component({
    selector: '[sizeable]',
    template: '<ng-content></ng-content>',
    styleUrls: ['./sizeable.component.scss']
})
export class SizeableComponent implements OnInit {

    constructor(private el: ElementRef) {
    }

    ngOnInit() {
        this.resizeInput();
        $(this.el.nativeElement).on({
            keyup: () => this.resizeInput()
        });
        $(this.el).on({ keyup: this.resizeInput});
    }

    resizeInput() {
        $(this.el.nativeElement)
            .attr('size', $(this.el.nativeElement).val().length + 2);
    }

}
