import * as _ from 'lodash';
import { HttpParams } from '@angular/common/http';

export class Utils {

    static isImage(file: File) : boolean {
        var validImageTypes = ["image/gif", "image/jpeg", "image/png"];
        return (_.indexOf(validImageTypes, file["type"]) != -1);
    }

}
//export var alertify: any = window["alertify"];

export const toHttpParams = (value: any) => Object.keys(value)
    .filter(key => !_.isNull(value[key]) && !_.isUndefined(value[key]))
    .reduce((params, key) => params.append(key, value[key]), new HttpParams());


export function getPasswordStrength(pwd: string) {
    let st = 0;    

    if (pwd.length > 5)
        st++;

    if (new RegExp("[0-9]").test(pwd))
        st++;

    if (new RegExp("[A-Z]").test(pwd))
        st++;

    if (new RegExp("[a-z]").test(pwd))
        st++;

    return st;
}
