import { Component, Input, OnInit, ViewContainerRef, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { ModalService } from '../../../service/modal.service';
import { LanguageFormComponent } from '../language-form/language-form.component';

@Component({
    selector: 'app-language-list',
    templateUrl: './language-list.component.html',
    styleUrls: ['./language-list.component.scss']
})
export class LanguageListComponent implements OnInit {

    @Input('languages')
    languages;

    @Output()
    language = new EventEmitter<any>();

    constructor(
        private router: Router,
        private modalService: ModalService,
        private vcr: ViewContainerRef
    ) {
    }

    ngOnInit() {
    }

    editLg(language) {
        var modal = this.modalService.openModal(LanguageFormComponent, this.vcr);
        
        modal.instance.form.patchValue(language);
        
        modal.instance.onClose.subscribe(l => {  
            this.language.emit(l);
            modal.destroy();
        });

        modal.instance.onDiscard.subscribe(() => {
            modal.destroy();
        });        
    }

    viewLg(language) {
        this.router.navigate(['/osadmin/secure/language/view/', language.id]);
    }

}
