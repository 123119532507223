import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { Conversation } from '../../model/conversation';
import { ChatService } from '../../services/chat.service';
import { ChatReducer } from '../../store/reducers';
import { Store } from '@ngrx/store';

@Component({
    selector: 'app-chat-list-item',
    templateUrl: './chat-list-item.component.html',
    styleUrls: ['./chat-list-item.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChatListItemComponent implements OnInit {
    @Input()
    public conversation: Conversation;
    
    constructor(
        private readonly chatService: ChatService,
        private readonly store: Store<ChatReducer>,
        private readonly cdr: ChangeDetectorRef
    ) {}
    
    public ngOnInit(): void {
        this.chatService.loadMessages(this.conversation.id).subscribe();
        
        this.store.select('chat', 'conversations', 'entities').subscribe(entities => {
            // this.cdr.markForCheck();
        });
    }
}

