import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-infinite-scroll-loader',
    templateUrl: './infinite-scroll-loader.component.html',
    styleUrls: ['./infinite-scroll-loader.component.scss']
})
export class InfiniteScrollLoaderComponent {

    @Input()
    pageable;
}
