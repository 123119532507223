import { Component, Input, OnInit } from '@angular/core';
import { environment } from '../../../environments/environment';

@Component({
    selector: 'app-message-feed-item',
    templateUrl: './message-feed-item.component.html',
    styleUrls: ['./message-feed-item.component.scss']
})
export class MessageFeedItemComponent implements OnInit {

    @Input()
    item;

    constructor() {
    }

    ngOnInit() {

    }

    get envApi() {
        return environment.api;
    }

}
