import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Skill } from '../../domain/skill';
import { FormGroup } from '@angular/forms';
import { SkillService } from '../../service/skill.service';

@Component({
    selector: 'app-skill-form',
    templateUrl: './skill-form.component.html',
    styleUrls: ['./skill-form.component.scss']
})
export class SkillFormComponent implements OnInit {

    @Input("type")
    type;
    @Input()
    placeholder;

    form: FormGroup = Skill.form();
    @Output()
    onSkill: EventEmitter<any> = new EventEmitter();

    constructor(private skillService: SkillService) {
    }

    ngOnInit() {
        this.form.patchValue({
            skillType: this.type
        });
    }

    saveSkill(){
        if(this.form.valid){
            this.skillService.save(this.form.value).subscribe((skill) => {
                this.form = Skill.form();
                this.form.patchValue({
                    skillType: this.type
                });
                this.onSkill.emit(skill);
            });
        }
    }
}
