import { Occupant } from './occupant';
import { Attachment } from './attachment';

export enum MessageType {
    message = 'message',
    chatLeft = 'chatLeft',
    chatEntered = 'chatEntered',
}

export interface Message {
    creator: string;
    updated: number;
    created: number;
    content: string;
    conversationId: string;
    id: string;
    sender: Occupant;
    messageType: MessageType;
    read: boolean;
    attachments: Attachment;
    occupants: Occupant[];
}
