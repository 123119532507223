import { Component, EventEmitter, OnInit, ViewChild, ViewContainerRef, ElementRef } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap';
import { UserService } from '../../../service/user.service';
import { User } from '../../../domain/user';
import { FormGroup } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { Utils, getPasswordStrength } from '../../../shared/utils';

@Component({
    selector: 'app-change-password',
    templateUrl: './change-password.component.html',
    styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {

    @ViewChild(ModalDirective, { static: false })
    modal;
    onClose: EventEmitter<any> = new EventEmitter();
    form: FormGroup = User.changePasswordForm();

    currentStrength = 0;

    @ViewChild('pwdMetter', { static: false })
    metter: ElementRef;

    constructor(private userService: UserService,
        private vcr: ViewContainerRef,
        private ts: TranslateService,
        private toaster: ToastrService) {
    }

    ngOnInit() {
        this.form.get('newPassword').valueChanges.subscribe(
            () => this.currentStrength = getPasswordStrength(this.form.get('newPassword').value)
        );
    }

    hideModal() {
        this.modal.hide();
        this.onClose.emit(null);
    }

    get classes() {
        return "strength-" + this.currentStrength;
    }

    show() {
        this.modal.show();
    }

    onHidden() { }

    changePassword() {
        this.userService.changePassword(this.form.value).subscribe(() => {
            this.ts.get('toasts.successfullPasswordChange').subscribe(ts => {
                this.toaster.success(ts);
                this.modal.hide();
            });
        }, () => { });
    }

    setForUser(userId: string) {
        this.form.get('oldPassword').clearValidators();
        this.form.patchValue({ userId: userId });
    }

}
