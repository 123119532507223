import { Component, OnInit } from '@angular/core';
import { Office } from '../../../domain/office';
import { OfficeService } from '../../../service/office.service';
import { AbstractModal } from '../../../shared/abstract-modal';
import { ToastrService } from 'ngx-toastr';
import { Utils } from '../../../shared/utils';
import * as $ from 'jquery';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-office-form',
  templateUrl: './office-form.component.html',
  styleUrls: ['./office-form.component.scss']
})
export class OfficeFormComponent extends AbstractModal implements OnInit {

    form = Office.form();

    constructor(private officeService: OfficeService,
                private ts: TranslateService,
                private toaster: ToastrService) {
        super();
    }

    ngOnInit() {}

    save(){
        let data = this.form.value;
        data.allowedDomains = data.allowedDomains.split('\n');
        this.officeService.save(this.form.value).subscribe(o => {
            this.ts.get('toasts.successfullOfficeEdition').subscribe(ts => {
                this.toaster.success(ts);
                this.close(o);
            });
        });

    }

    onFileSelected($event){
        let fileList: FileList = $event.target.files;
        if(fileList.length > 0){
            if(Utils.isImage(fileList.item(0))){
                this.form.patchValue({ image: fileList.item(0) });
                this.loadPreview(fileList.item(0));
            }
        }else{
            this.form.patchValue({ image: undefined });
            $('#preview').attr('src', '');
            $('#preview').parent().removeClass("dropped");
        }
    }

    loadPreview(file){
        var reader = new FileReader();

        reader.onload = (e) => {
            $('#preview').parent().addClass("dropped");
            $('#preview').attr('src', e.target["result"]);
        };

        reader.readAsDataURL(file);
    }

    get envApi() {
        return environment.api;
    }

}
