import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { LanguageService } from '../../../service/language.service';
import * as _ from 'lodash';
import { ModalService } from '../../../service/modal.service';

@Component({
    selector: 'app-translation-key-list',
    templateUrl: './translation-key-list.component.html',
    styleUrls: ['./translation-key-list.component.scss']
})
export class TranslationKeyListComponent implements OnInit {

    @Input('items')
    keys;
    @Input('language')
    language;
    @Output()
    onEdit: EventEmitter<any> = new EventEmitter();

    constructor(
        private languageService: LanguageService,
        private modalService: ModalService) {
    }

    ngOnInit() {
    }

    edit(key) {
        this.onEdit.emit(_.clone(key));
    }

    delete(key, index) {
        this.modalService
            .confirm('language.deletekey.title', 'deletekey.deletekey.message')
            .subscribe(() => {
                this.languageService.deleteKey(this.language.id, key.id).subscribe(res => {
                    this.keys.splice(index, 1);
                });
            });
    }

    add(key: any) {
        this.keys.push(key);
    }
}
