import { ChangeDetectionStrategy, Component, ElementRef, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Message } from '../../model/message';
import { timer } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Conversation } from '../../model/conversation';

@Component({
  selector: 'app-chat-message-list',
  templateUrl: './chat-message-list.component.html',
  styleUrls: ['./chat-message-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChatMessageListComponent implements OnChanges {
    
    @Input()
    public messages: Message[];
    
    @Input()
    public conversation: Conversation;
    
    constructor(
        private elementRef: ElementRef
    ) {}
    
    public byId(index, item) {
        return item.id;
    };
    
    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.conversation) {
            timer(300)
                .subscribe(() => this.backToBottom());
            
        }
        timer(300)
            .pipe(
                tap(_ => this.determineIfShouldScroll(changes))
            )
            .subscribe();
    }
    
    public backToBottom() {
        this.elementRef.nativeElement.scrollTop = this.elementRef.nativeElement.scrollHeight;
    }
    
    private determineIfShouldScroll(changes: SimpleChanges) {
        if (changes.conversation) {
            this.backToBottom();
            return;
        }
        const {offsetHeight, scrollHeight, scrollTop} = this.elementRef.nativeElement;
        if (scrollTop + (offsetHeight + offsetHeight / 2) > scrollHeight) {
            this.backToBottom();
        }
    }
}

