import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfigService } from '../../service/config.service';
import { UserService } from '../../service/user.service';
import { ToastrService } from 'ngx-toastr';
import * as $ from 'jquery';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-step-two',
  templateUrl: './step-two.component.html',
  styleUrls: ['./step-two.component.scss']
})
export class StepTwoComponent implements OnInit, AfterViewInit {

    @Input('form')
    form: FormGroup;
    private step;
    config;
    @Input('loading')
    loading;

    constructor(private route: ActivatedRoute,
                private configService: ConfigService,
                private userService: UserService,
                private router: Router,
                private toaster: ToastrService,
                private ts: TranslateService) {
    }

    ngOnInit() {

        this.route.params.subscribe(p => {
            this.step = parseInt(p["step"]);

            if(this.step != 2){
                return;
            }

            this.route.queryParams.subscribe(qp => {
                if('token' in qp){
                    this.checkToken(qp['token']).subscribe(res => this.form.patchValue({token: qp['token']}), err => {
                        this.ts.get('toasts.noSuchToken').subscribe(ts => {
                            this.toaster.error(ts);
                            this.router.navigate(['/login']);
                        });
                    });
                }else{
                    this.router.navigate(['/login']);
                }
            });
        });

        this.config = this.configService.config;
        this.configService.configObs.subscribe(cfg => this.config = cfg);
    }

    ngAfterViewInit():void {
        var that = this;
        if($('#disclaimer').get(0).scrollHeight - 20 > $('#disclaimer').height()){
            $('#disclaimer').on('scroll', function() {
                if($(this).scrollTop() + $(this).innerHeight() >= $(this)[0].scrollHeight) {
                    that.form.patchValue({ acceptConditions: true });
                }
            });
        }else{
            that.form.patchValue({ acceptConditions: true });
        }
    }

    beautified(disclaimer){
        if(disclaimer){
            return disclaimer.replace(new RegExp("\n", 'ig'), '<br/>');
        }
    }

    private checkToken(token:any) {
        return this.userService.checkToken(token);
    }
}
