import { FormControl, FormGroup, Validators } from '@angular/forms';

export class News {
    static form(){
        return new FormGroup({
            id: new FormControl(),
            title: new FormControl('', [Validators.required]),
            content: new FormControl('', [Validators.required]),
            file: new FormControl(null, [Validators.required]),
            active: new FormControl()
        });
    }
}
