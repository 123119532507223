import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { MoreValidators } from '../validation/more-validators';

export class Registration {

    static form(){
        return new FormGroup({
            stepOne: new FormGroup({
                email: new FormControl('', [Validators.required]),
                officeId: new FormControl('', [Validators.required]),
                password: new FormControl('', Validators.compose([Validators.required, MoreValidators.strongPassword])),
                firstname: new FormControl('', [Validators.required]),
                lastname: new FormControl('', [Validators.required]),
                passwordAgain: new FormControl('', [Validators.required])
            }, MoreValidators.areEqual('password', 'passwordAgain')),
            stepTwo: new FormGroup({
                acceptConditions: new FormControl('', [Validators.requiredTrue]),
                token: new FormControl('', [Validators.required])
            }),
            stepThree: new FormGroup({
                acceptConditions: new FormControl('', [Validators.required]),
                token: new FormControl('', [Validators.required]),
                firstname: new FormControl('', [Validators.required]),
                lastname: new FormControl('', [Validators.required]),
                gender: new FormControl('', [Validators.required]),
                image: new FormControl()
            }),
            stepFour: new FormGroup({
                functionInOffices: new FormArray([new FormGroup({
                    officeId: new FormControl(),
                    office: new FormControl(),
                    delegationId: new FormControl(),
                    delegation: new FormControl(),
                    skillId: new FormControl(),
                    skill: new FormControl()
                })]),
                phone: new FormControl(),
                secondaryPhone: new FormControl()
            }),
            stepFive: new FormGroup({
                skillIds: new FormControl()
            }),
            userCreation: new FormGroup({
                email: new FormControl('', [Validators.required]),
                officeId: new FormControl('', [Validators.required]),
                firstname: new FormControl('', [Validators.required]),
                lastname: new FormControl('', [Validators.required])
            })
        });
    }

}
