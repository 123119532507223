import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'app-chat-attachment-item',
    templateUrl: './chat-attachment-item.component.html',
    styleUrls: ['./chat-attachment-item.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChatAttachmentItemComponent implements OnInit {
    
    @Output()
    public removeClicked = new EventEmitter<void>();
    @Input()
    public attachment: File;
    
    constructor() {
    }
    
    ngOnInit() {
    }
    
}
