import { Component, Input, OnInit } from '@angular/core';
import { UserService } from '../../../service/user.service';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'app-user-favorite-list',
    templateUrl: './user-favorite-list.component.html',
    styleUrls: ['./user-favorite-list.component.scss']
})
export class UserFavoriteListComponent implements OnInit {

    @Input()
    title;
    private userId;
    pageable;
    page: number = 0;

    constructor(private userService: UserService, private route: ActivatedRoute) {
    }

    ngOnInit() {
        this.route.params.subscribe(p => {
            if('id' in p){
                this.userId = p["id"];
                this.loadFavorites();
            }
        });
    }

    loadFavorites() {
        this.page++;
        this.userService.loadFavoritesForUser(this.userId, this.page).subscribe((d: any) => {
            if(!this.pageable){
                this.pageable = d;
            }else{
                this.pageable.entities = this.pageable.entities.concat(d.entities);
            }
        });
    }

}
