import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';

export class MessageForm extends FormGroup {
    constructor() {
        super({
            content: new FormControl(null, [Validators.required]),
            attachments: new FormArray([])
        });
    }
    
    public addFile(file: File) {
        this.attachmentsControl.push(new FormControl(file));
    }
    
    public get attachments(): File[] {
        return this.attachmentsControl.value;
    }
    
    
    public get attachmentsControl(): FormArray {
        return (this.get('attachments') as FormArray);
    }
    
    public removeAttachment(index: any) {
        this.attachmentsControl.removeAt(index);
    }
    
    public toFormData() {
        const data = new FormData();
        data.append('content', this.value.content);
        this.value.attachments.forEach(attachment => data.append('attachment', attachment));
        return data;
    }
    
    
    reset(value?: any, options?: { onlySelf?: boolean; emitEvent?: boolean }): void {
        super.reset(value, options);
        this.attachmentsControl.clear();
    }
}
