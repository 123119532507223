import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { EventService } from '../../../service/event.service';
import { ActivatedRoute, Router } from '@angular/router';
import * as _ from 'lodash';
import { ModalService } from '../../../service/modal.service';
import { forkJoin } from 'rxjs';
import { stringify } from '@angular/compiler/src/util';


@Component({
  selector: 'app-group-events-list',
  templateUrl: './group-events-list.component.html',
  styleUrls: ['./group-events-list.component.scss']
})
export class GroupEventsListComponent implements OnInit {

  pageable: any;
  groupId: string;
  page = 1;

  @Input()
  title: string;

  @Input()
  isEdit: boolean = false;

  private mapChecked = new Map<string, boolean>();

  constructor(
    private route: ActivatedRoute,
    private eventService: EventService,
    private router: Router,
    private modalService: ModalService
  ) { }

  ngOnInit() {
    this.route.params.subscribe(p => {
      if ('id' in p) {
        this.groupId = p["id"];
        this.load();
      }
    });
  }

  load() {
    this.eventService.getEventsForGroup(this.groupId, this.page).subscribe(
      (e: any) => {
        if (this.page > 1) {
          this.pageable.entities = this.pageable.entities.concat(e.entities);
        } else {
          this.pageable = e;
        }

        this.mapChecked = new Map<string, boolean>();
        this.pageable.entities.forEach(t => this.mapChecked.set(t.id, false));
      }
    );
  }

  onChecked(event) {
    this.mapChecked.set(event.id, !this.mapChecked.get(event.id));
  }

  onScroll() {
    if (_.get(this.pageable, "entities.length") != _.get(this.pageable, "count")) {
      this.page++;
      this.load();
    }
  }

  onAdd() {
    this.router.navigate(['/social/event']);
  }

  onDelete() {
    this.modalService
      .confirm('groupevent.deletion.title', 'groupevent.deletion.message')
      .subscribe(() => {

        let ids = [];
        let obs = [];
        this.mapChecked.forEach((value, key) => {
          if (value) {
            ids.push(key);
            obs.push(this.eventService.deleteEvent(key));
          }
        });

        if (ids.length > 0) {

          forkJoin(obs).subscribe(
            () => {
              this.page = 1;
              this.load();
            }
          )
        }
      });

  }

}
