import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { EventService } from '../../../service/event.service';
import { Event } from '../../../domain/event';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup } from '@angular/forms';
import * as moment from 'moment';
import * as _ from 'lodash';
import { OfficeSelectorComponent } from '../../../shared/office-selector/office-selector.component';
import { GroupService } from '../../../service/group.service';
import { SelectComponent } from 'ng2-select';
import { AbstractModal } from '../../../shared/abstract-modal';
import { AfterViewInit } from '@angular/core';
import { GroupSelectorComponent } from '../../../shared/group-selector/group-selector.component';

@Component({
    selector: 'app-calendar-form',
    templateUrl: './calendar-form.component.html',
    styleUrls: ['./calendar-form.component.scss']
})
export class CalendarFormComponent extends AbstractModal implements OnInit, AfterViewInit {

    @Input('entity')
    entity;
    form: FormGroup = Event.form();
    @Output()
    onEvent: EventEmitter<any> = new EventEmitter<any>();
    @ViewChild(SelectComponent, { static: false })
    select: SelectComponent;
    @ViewChild(OfficeSelectorComponent, { static: false })
    officeSelector: OfficeSelectorComponent;

    @ViewChild(GroupSelectorComponent, { static: false })
    groupSelector: GroupSelectorComponent;

    groups: any;
    minDate: Date = moment().add(21000, 'years').toDate();

    officeId: any;
    groupId: any;
    hideOffice: boolean = false;
    hideGroup: boolean = false;
    isOutside: boolean = false;

    constructor(private eventService: EventService,
        private route: ActivatedRoute,
        private router: Router,
        private groupService: GroupService) {
        super();
        this.form.get('start').valueChanges.subscribe(v => {
            this.minDate = moment(v, 'DD/MM/YYYY HH:mm').toDate();
            if (moment(this.form.get('end').value, 'DD/MM/YYYY HH:mm').isBefore(moment(v, 'DD/MM/YYYY HH:mm'))) {
                this.form.get('end').setValue(moment(this.minDate).format('DD/MM/YYYY HH:mm'), { emitEvent: false });
            }
        });
        this.form.get('end').valueChanges.subscribe(v => {
            const start = this.form.get('start').value;
            if (moment(v, 'DD/MM/YYYY HH:mm').isBefore(moment(start, 'DD/MM/YYYY HH:mm'))) {
                this.form.get('end').setValue(moment(this.minDate).format('DD/MM/YYYY HH:mm'), { emitEvent: false });
            }
        });
    }

    ngOnInit() {
        if (this.form.get('outside').value) {
            this.isOutside = true;
        }

        this.groupService.loadAllPublicTopics().subscribe(topics => {
            this.groups = topics.map(group => {
                group.text = group.title;
                return group;
            });
            if (this.select) {
                this.select.items = topics;
                this.select.ngOnInit();
            }
        });
    }

    ngAfterViewInit() {
        if (!this.isOutside) {
            if (!this.hideOffice)
            this.officeSelector.onReady.subscribe(() => {
                this.officeSelector.setValue(this.officeId);
            });

            if (!this.hideGroup)
                this.groupSelector.onReady.subscribe(() => {
                    this.groupSelector.setValue(this.groupId);
                });
        }
    }

    save() {
        let data = this.form.value;
        if (this.hideGroup)
            data.groupId = null;
        if (this.hideOffice)
            data.officeId = null;

        data.start = moment(data.start, 'DD/MM/YYYY HH:mm').toDate();
        data.end = moment(data.end, 'DD/MM/YYYY HH:mm').toDate();
        this.eventService.saveEvent(data).subscribe(v => {
            this.close(v);
        });
    }

    onFileSelected(e) {
        if (_.get(e, 'target.files.length') > 0) {
            this.form.patchValue({ file: e.target.files[0] });
        } else {
            this.form.patchValue({ file: undefined });
        }
    }

    onLocationSelected($event) {
        this.form.get('location').setValue($event.formatted_address);
    }

    onTopicSelected(topic) {
        this.form.patchValue({ groupId: topic.id });
    }

    removeFile() {
        this.form.patchValue({ file: undefined });
    }
}
