import { Component, OnInit, ChangeDetectionStrategy, Input, SimpleChanges, OnChanges } from '@angular/core';
import { UserService } from '../../../service/user.service';

@Component({
  selector: 'app-group-member-avatar-wrapper',
  templateUrl: './group-member-avatar-wrapper.component.html',
  styleUrls: ['./group-member-avatar-wrapper.component.scss']
})
export class GroupMemberAvatarWrapperComponent implements OnInit, OnChanges {

  @Input()
  memberId;
  
  member = null;

  constructor(
    private userService: UserService
  ) { }

  ngOnInit() {
   this._load();
  }

  private _load() {
    this.userService.loadUser(this.memberId).subscribe(
      (u) => this.member = u
    );
  }

  public ngOnChanges(changes: SimpleChanges): void {
  if (changes.memberId) {
    this._load();
  }
    
}

}
