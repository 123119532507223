import { Component, EventEmitter, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { GroupService } from '../../../service/group.service';
import { UserService } from '../../../service/user.service';
import { AbstractModal } from '../../../shared/abstract-modal';
import * as _ from 'lodash';


@Component({
  selector: 'app-add-group-member',
  templateUrl: './add-group-member.component.html',
  styleUrls: ['./add-group-member.component.scss']
})
export class AddGroupMemberComponent extends AbstractModal implements OnInit {

  group: any = null;
  users: any;

  changeMembers = new EventEmitter<string[]>();

  mapAvatars: Map<string, string> = new Map<string, string>();

  formPager: FormGroup = new FormGroup({
    page: new FormControl(1),
    q: new FormControl(""),
    officeIds: new FormControl(null)
  });


  constructor(
    private groupService: GroupService,
    private userService: UserService
  ) {
    super();
  }

  ngOnInit() {
    this.formPager.valueChanges.subscribe(
      () => this._loadUsers()
    );

    this._loadUsers();
  }

  onSearch(event) {
    this.formPager.get("q").setValue(event.srcElement.value, { emitEvent: false });
    this.formPager.get("page").setValue(1);
  }

  private _loadUsers() {
    if (this.isModerator())
      this.formPager.get('officeIds').setValue(this.userService.u.functionInOffices.map(f => f.officeId), { emitEvent: false});

    this.userService.getUsers(this.formPager.value).subscribe(
      (users) => {
        if (this.formPager.get('page').value > 1) {
          this.users.entities = this.users.entities.concat(users.entities);
        } else {
          this.users = users;
        }
      }
    );
  }

  isModerator(): boolean {
    return (_.find(this.userService.u.roles, r => r == 'MODERATOR') !== undefined);
  }

  onToggleUser(user) {
    let obs;
    if (this.isMember(user))
      obs = this.groupService.removeUserGroup(this.group.id, user.id);
    else
      obs = this.groupService.addUserToGroup(this.group.id, user.id);

    obs.subscribe(
      (group) => {
        this.group = group;
        this.changeMembers.emit(this.group.members);
      }
    );

  }

  isMember(user) {
    return this.group.members.find(id => user.id === id);
  }


  onScroll() {
    if (_.get(this.users, "entities.length") != _.get(this.users, "count"))
      this.formPager.patchValue({ page: ++this.formPager.value.page });
  }

  userFunction(user) {
    if (user) {
      if (user.functionInOffices.length > 0 && user.functionInOffices[0].skill)
        return user.functionInOffices[0].skill.translationKey;
    }
    return "";

  }
}
