import { Component, ElementRef, OnInit } from '@angular/core';

@Component({
    selector: '[object-fit]',
    template: '<ng-content></ng-content>',
    styleUrls: ['./object-fit.component.scss']
})
export class ObjectFitComponent implements OnInit {

  constructor(private e: ElementRef) {
  }

  ngOnInit() {
      if(!('object-fit' in document.body.style)){
          /*let image = this.e.nativeElement.children[0];
          this.e.nativeElement.className += ' compat-object-fit';
          this.e.nativeElement.style.backgroundImage = `url('${image.getAttribute('src')}')`;*/
      }
  }
}
