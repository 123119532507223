import { Injectable } from '@angular/core';
import { Settings } from '../app.settings';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class PublicDataService {

    constructor(private http: HttpClient) {}

    getGenders(){
        return this.http.get(Settings.PUBLIC_GENDER);
    }

    getGroupTypes(): Observable<any[]> {
        return this.http.get<any[]>(Settings.PUBLIC_GROUP_TYPES);
    }

    getPrivacyTypes(): Observable<any[]> {
        return this.http.get<any[]>(Settings.PUBLIC_PRIVACY_TYPES);
    }

    getSocialNewtworkTypes() {
        return this.http.get(Settings.PUBLIC_SOCIAL_NETWORK_TYPES);
    }

    getSkillTypes() {
        return this.http.get(Settings.PUBLIC_SKILL_TYPES);
    }

    getRoles() {
        return this.http.get(Settings.PUBLIC_ROLES);
    }
}
