import {
    AfterViewInit,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChild,
    ViewContainerRef
} from '@angular/core';
import * as _ from 'lodash';
import { ModalService } from '../../service/modal.service';
import { LinkFormComponent } from '../link-form/link-form.component';
import { LinkGroupService } from '../../service/link-group.service';
import { OfficeSelectorComponent } from '../../shared/office-selector/office-selector.component';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';

@Component({
    selector: 'app-link-group',
    templateUrl: './link-group.component.html',
    styleUrls: ['./link-group.component.scss']
})
export class LinkGroupComponent implements OnInit {

    @Input()
    user;
    @Input()
    group;
    @Output()
    onGroupEdit: EventEmitter<any> = new EventEmitter();
    @Output()
    onGroupDelete: EventEmitter<any> = new EventEmitter();

    constructor(private modalService: ModalService,
                private vcr: ViewContainerRef,
                private linkGroupService: LinkGroupService,
                private toaster: ToastrService,
                private ts: TranslateService) {
    }

    ngOnInit() {}

    isAdmin(){
        try {
            return _.find(<Array<any>>_.get(this, 'user.roles'), r => (r == 'ADMIN' || r == 'SUB_ADMIN'));
        }catch (err) {
            return false;
        }
    }

    addOrEditLink(link) {
        const modal = this.modalService.openModal(LinkFormComponent, this.vcr);
        modal.instance.form.patchValue({ linkGroupId: this.group.id });
        if(link) {
            modal.instance.form.patchValue(link);
        }
        modal.instance.onClose.subscribe(res => {
            modal.destroy();
            if(_.find(this.group.links, l => l.id === res.id)) {
                this.group.links = this.group.links
                    .map(l => {
                        if (l.id === link.id) {
                            return _.extend(l, res);
                        }
                        return l;
                    })
                    .sort((a, b) => a.position < b.position ? -1 : 1);
            } else {
                this.group.links = [res].concat(this.group.links).sort((a, b) => a.position < b.position ? -1 : 1);
            }
        });

        modal.instance.onDiscard.subscribe(() => {
            modal.destroy();
        });
    }

    editGroup() {
        this.onGroupEdit.emit(_.clone(this.group));
    }

    deleteGroup() {
        this.modalService
            .confirm('link-group.deletion.title', 'link-group.deletion.message')
            .subscribe(() => {
                this.linkGroupService.deleteLinkGroup(this.group.id)
                    .subscribe();

                this.onGroupDelete.emit(this.group.id);
            });
    }

    save() {
        this.linkGroupService.saveGroup(this.group).subscribe(group => {
            this.ts.get('toasts.successfullLinkGroupEdition').subscribe(ts => {
                this.toaster.success(ts);
            });
        });
    }

    onLinkDeleted(id) {
        _.remove(this.group.links, (link: any) => link.id === id);
    }

    editLink(link) {
        this.addOrEditLink(_.clone(link));
    }

    deleteLink(link) {
        this.modalService.confirm('link.deletion.title', 'link.deletion.message')
            .subscribe(() => {
                this.linkGroupService.deleteLink(link.id)
                    .subscribe(() => {
                        this.onLinkDeleted(link.id);
                    });
            });
    }

}
