import { EventEmitter, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { StorageService } from './storage.service';
import { Settings } from '../app.settings';
import * as _ from 'lodash';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, of, Subscriber } from 'rxjs';
import { map, share, switchMap, tap } from 'rxjs/operators';
import { Pageable } from '../shared/model/pageable';
import { toHttpParams } from '../shared/utils';
import { IUserLocator } from '../modules/chat/interfaces/user-locator.interface';
import { Sender } from '../modules/chat/model/sender';

@Injectable()
export class UserService implements IUserLocator {

    public userObservable = new BehaviorSubject(null);
    public favoritesObservable: Observable<any> = new Observable(observer => {
        this.favorites = observer;
    }).pipe(
        share()
    );
    public favorites: Subscriber<any>;
    private websocket: WebSocket;
    favs = [];
    u;
    onBuildRequest: EventEmitter<any> = new EventEmitter<any>();
    onPreviewRequest: EventEmitter<any> = new EventEmitter<any>();
    onUpload: EventEmitter<any> = new EventEmitter();
    onSocketEvent: EventEmitter<any> = new EventEmitter<any>();
    onReadStatus: EventEmitter<any> = new EventEmitter<any>();
    onReadStatusForum: EventEmitter<any> = new EventEmitter<any>();
    onReadStatusEvent: EventEmitter<any> = new EventEmitter<any>();

    constructor(private http: HttpClient, private router: Router, private storage: StorageService) {
        this.userObservable.subscribe(u => this.u = u);
    }

    redirectToLogin() {
        this.storage.removeToken();
        this.router.navigate(['/login'], { skipLocationChange: false });
    }

    login(data: any): Observable<any> {
        return this.http.post(Settings.LOGIN, data)
            .pipe(
                map(token => {
                    this.storage.removeToken();
                    this.storage.storeToken(token);
                    this.getUser();
                    return token;
                })
            );
    }

    logout() {
        if (this.storage.hasToken()) {
            this.http.post(Settings.LOGOUT, this.storage.getToken())
                .pipe(
                    map(res => {
                        this.userObservable.next(undefined);
                        this.storage.removeToken();
                        this.redirectToLogin();
                        return res;
                    })
                ).subscribe();
        }
    }

    getUser(): Observable<any> {
        return this.userObservable.pipe(
            switchMap(u => {
                if (u) {
                    return of(u);
                }
                return this.http.get(Settings.SESSION)
                    .pipe(
                        tap(u => this.setUser(u))
                    )
            })
        );
    }

    setUser(user) {
        if (!this.websocket || this.websocket.readyState != WebSocket.OPEN) {
            this.websocket = new WebSocket(Settings.MESSAGING_URL, this.storage.getToken().token);
            this.websocket.addEventListener("message", (ev: MessageEvent) => {
                try {
                    this.onMessage(JSON.parse(ev.data));
                } catch (e) {
                    if (ev.data == 'pong') {
                        this.sendPing();
                    }
                }
            });
            this.websocket.addEventListener("open", (ev: MessageEvent) => {
                this.sendPing();
            });
        }
        this.loadFavorites().subscribe((data: any[]) => {
            this.favs = data;
            this.favorites.next(this.favs);
        });

        this.u = user;
        this.userObservable.next(user);
    }

    getUsers(value: any = {}): Observable<Pageable<any>> {

        let params = toHttpParams(value);

        return this.http.get<Pageable<any>>(Settings.USERS, {
            params
        });
    }

    loadUser(userId) {
        return this.http.get(Settings.USERS + "/" + userId);
    }

    search(predicate) {
        return this.http.get<any[]>(Settings.USERS + "/search?predicate=" + predicate);
    }

    save(user) {
        let method = user.id ? "put" : "post";
        let suffix = user.id ? `/${user.id}` : "";
        return this.http[method](Settings.USERS + suffix, user);
    }

    deleteUser(userId) {
        return this.http.delete(Settings.USERS + "/" + userId);
    }

    requestNewPassword(data: any) {
        return this.http.post(Settings.REQUEST_PASSWORD, data);
    }

    checkPasswordToken(token: any) {
        return this.http.get(Settings.REQUEST_PASSWORD + "/" + token);
    }

    resetPassword(token: any, data: any) {
        return this.http.post(Settings.REQUEST_PASSWORD + "/" + token, data);
    }

    loadFavorites() {
        return this.http.get(Settings.FAVORITES);
    }

    addToFavorites(userId: any) {
        return this.http.put(Settings.FAVORITES + "/" + userId, {})
            .pipe(
                map(data => {
                    this.favs.push(data);
                    this.favorites.next(this.favs);
                    return data;
                })
            );
    }

    removeFromFavorites(userId: any) {
        return this.http.delete(Settings.FAVORITES + "/" + userId, {})
            .pipe(
                map(data => {
                    _.remove(this.favs, f => f['id'] == userId);
                    this.favorites.next(this.favs);
                    return data;
                })
            );
    }

    createRegistration(value: any) {
        return this.http.post(Settings.REGISTRATION, value);
    }

    checkToken(token: any): Observable<any> {
        return this.http.put(Settings.REGISTRATION + "/" + token, {});
    }

    finishRegistration(data) {
        let formData = new FormData();
        for (var key in data) {
            if (!_.isNull(data[key]) && !_.isUndefined(data[key])) {
                formData.append(key, data[key]);
            }
        }

        return this.http.post(Settings.REGISTRATION + "/submit", formData)
            .pipe(
                map(res => {
                    this.storage.storeToken(res);
                    return res;
                })
            );
    }

    private onMessage(data: any) {
        switch (data.type) {
            case 'BuildRequest':
                this.onBuildRequest.emit(data);
                break;
            case 'PreviewRequest':
                this.onPreviewRequest.emit(data);
                break;
        }
        if (data.messageType) {
            this.onSocketEvent.emit(data);
        }
    }

    updateReadStatus(topicId: any) {
        return this.http.head(Settings.USERS + "/read-status/" + topicId)
            .pipe(
                map((res: any) => {
                    this.onReadStatus.emit(topicId);
                })
            );
    }

    updateReadStatusForum(forumId: any) {
        return this.http.head(Settings.USERS + "/read-status-forum/" + forumId)
            .pipe(
                map((res: any) => {
                    this.onReadStatusForum.emit(forumId);
                })
            );
    }

    updateReadStatusEvent(eventId: any) {
        return this.http.head(Settings.USERS + "/read-status-event/" + eventId)
            .pipe(
                map((res: any) => {
                    this.onReadStatusEvent.emit(eventId);
                })
            );
    }

    checkEmail(value: any) {
        return this.http.post(Settings.REGISTRATION + "/checkemail", {
            email: value
        });
    }

    loadFavoritesForUser(userId: any, page: number = 1) {
        return this.http.get(Settings.FAVORITES + "/" + userId + "?page=" + page);
    }

    uploadUserImage(userId: string, file: File) {
        let formData = new FormData();
        formData.append("file", file);
        return this.http.post(Settings.UPLOAD + "/user/" + userId, formData).pipe(
            map((data) => {
                this.onUpload.emit(data);
                return data;
            })
        );
    }

    changePassword(value: any) {
        let endpoint = value.userId || 'me';
        return this.http.put(Settings.REQUEST_PASSWORD + "/change/" + endpoint, value);
    }

    private sendPing() {
        setTimeout(() => {
            this.websocket.send("ping");
        }, 58000);
    }

    createUser(user: any) {
        return this.http.post(Settings.USERS, user);
    }

    activateUser(userId: any) {
        return this.http.put(Settings.USERS + "/activate/" + userId, {});
    }

    unactivateUser(userId: any) {
        return this.http.delete(Settings.USERS + "/activate/" + userId, {});
    }

    sendWelcomeEmail(userId: any) {
        return this.http.get(Settings.USERS + "/welcome/email/" + userId, {});
    }

    changeEmail(userId: any, value: any) {
        return this.http.post(Settings.USERS + "/change/email/" + userId, value);
    }

    validateEmailChangeToken(token: any) {
        return this.http.head(Settings.REGISTRATION + "/change/email/" + token);
    }

    loadUsersForGroup(groupId: any) {
        return this.http.get(Settings.USERS + "/group/" + groupId);
    }

    public findUser(term: string): Observable<any[]> {
        return this.search(term);
    }

    public currentUser(): Sender {
        return this.u;
    }

    public currentUserAsync(): Observable<Sender> {
        return this.userObservable;
    }
}
