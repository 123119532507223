import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { OccupantForm } from './occupant.form';

export class ChatForm extends FormGroup {
    constructor() {
        super({
            id: new FormControl(),
            label: new FormControl(),
            occupants: new FormArray([], Validators.required)
        });
    }
    
    public addOccupant(occupantForm: OccupantForm) {
        (this.get('occupants') as FormArray).push(occupantForm);
    }
    
    public get occupants(): OccupantForm[] {
        return (this.get('occupants') as FormArray).controls as OccupantForm[];
    }
    
    public removeOccupant(occupant: OccupantForm) {
        const index = this.occupants.findIndex(c => c.equals(occupant));
        (this.get('occupants') as FormArray).removeAt(index);
    }
    
    
    reset(value?: any, options?: { onlySelf?: boolean; emitEvent?: boolean }): void {
        super.reset(value, options);
        (this.get('occupants') as FormArray).clear();
    }
}
