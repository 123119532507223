import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MoreValidators } from '../validation/more-validators';

export class ResetPassword {
    static form(){
        return new FormGroup({
            username: new FormControl('', Validators.required)
        });
    }
    static resetForm(){
        return new FormGroup({
            password: new FormControl('', [Validators.required, MoreValidators.strongPassword]),
            passwordAgain: new FormControl('', [Validators.required])
        }, MoreValidators.areEqual('password', 'passwordAgain'));
    }
}
