import { createAction, props } from '@ngrx/store';
import { Pageable } from '../../../shared/model/pageable';
import { Conversation } from '../model/conversation';
import { Message } from '../model/message';

export const toggleChatBar = createAction('[ChatBar] Toggle');
export const openChatBar = createAction('[ChatBar] Open', );
export const closeChatBar = createAction('[ChatBar] Close');
export const chatHistoryFetched = createAction('[Chat] History fetched', props<Pageable<Conversation>>());
export const messagesReceived = createAction('[Chat] Messages received', props<MessagesReceivedAction>());
export const openConversation = createAction('[Chat] Open conversation', props<Conversation>());
export const openFormPanel = createAction('[Chat] Open form panel');
export const closeFormPanel = createAction('[Chat] Close form panel');
export const conversationCreated = createAction('[Chat] Conversation created', props<Conversation>());
export const conversationLoaded = createAction('[Chat] Conversation loaded', props<Conversation>());
export const updateReadStatus = createAction('[Chat] Update read status', props<UpdateReadStatusAction>());
export const conversationQuitted = createAction('[Chat] Conversation quitted', props<{conversationId: string}>());
export const cleanUp = createAction('[Chat] Cleanup');

export interface MessagesReceivedAction {
    conversationId: string;
    messages: Message[];
    addUnread: boolean;
}

export interface UpdateReadStatusAction {
    userId: string;
    conversationId: string;
}
