import { Injectable } from '@angular/core';
import { Settings } from '../app.settings';
import * as _ from 'lodash';
import { HttpClient } from '@angular/common/http';
import { toHttpParams } from '../shared/utils';
import { Observable } from 'rxjs';
import { Pageable } from '../shared/model/pageable';

@Injectable()
export class DocumentService {

    constructor(private http: HttpClient) {}

    loadDocument(documentType: any, id: any) {
        return this.http.get(Settings.DOCUMENT + "/" + documentType + "/" + id + "/self");
    }
    
    loadDocuments(documentType: any, value: any) {
        let params = toHttpParams(value);

        return this.http.get(Settings.DOCUMENT + "/" + documentType, { params });
    }

    loadDocumentsForTypeAndParentId(documentType: any, parentId: any, value: any) {
        let params = toHttpParams(value);

        return this.http.get(Settings.DOCUMENT + "/" + documentType + "/" + parentId, { params });
    }

    save(document: any) {
        document = _.omitBy(document, (item) => _.isNull(item) || _.isUndefined(item));
        
        const data = new FormData();
        
        Object.keys(document).forEach(key => {
            data.append(key, document[key]);
        });

        console.log(data);

        return this.http.post(Settings.DOCUMENT, data);
    }

    update(value:any) {
        return this.http.put(Settings.DOCUMENT + "/" + value.id, value);
    }

    remove(id:any) {
        return this.http.delete(Settings.DOCUMENT + "/" + id);
    }

    removeAll(value: any) {
        let params = toHttpParams(value);

        return this.http.get(Settings.DOCUMENT + "/delete-all", { params });
    }

    loadDocumentsForUser(userId:any, page: number = 1) {
        return this.http.get(Settings.DOCUMENT + "/user/" + userId +"?page=" + page);
    }

    loadContributors() {
        return this.http.get(Settings.DOCUMENT + "/contributors");
    }
}
