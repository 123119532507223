import { ChangeDetectionStrategy, Component, Inject, Input } from '@angular/core';
import {
    CHAT_SERVICE_CONFIG,
    IChatServiceConfiguration
} from './../../interfaces/chat-service-configuration.interface';

@Component({
    selector: 'app-chat-user-avatar',
    templateUrl: './chat-user-avatar.component.html',
    styleUrls: ['./chat-user-avatar.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChatUserAvatarComponent {
    
    @Input()
    public id: string;
    private readonly staticAssetsBase: string;
    
    constructor(
        @Inject(CHAT_SERVICE_CONFIG) private readonly config: IChatServiceConfiguration
    ) {
        this.staticAssetsBase = config.staticAssetsBase;
    }
    
    public get source() {
        return this.id ? `${this.staticAssetsBase}thumbnail/${this.id}?ts=${new Date().getTime()}` : null;
    }
}
