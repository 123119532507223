import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup } from '@angular/forms';
import { ResetPassword } from '../../domain/reset-password';
import { UserService } from '../../service/user.service';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { getPasswordStrength } from '../../shared/utils';

@Component({
    selector: 'app-forgot-password',
    templateUrl: './forgot-password.component.html',
    styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {

    context: string;
    form: FormGroup = ResetPassword.form();
    private token;

    currentStrength = 0;

    constructor(private route: ActivatedRoute,
                private router: Router,
                private userService: UserService,
                private toaster: ToastrService,
                private ts: TranslateService) {
    }

    ngOnInit() {
        this.route.params.subscribe(p => {
            if(!('token' in p)){
                this.form = ResetPassword.form();
                this.context = 'request';
            }else{
                this.token = p['token'];
                this.checkTokenAndDisplayForm(p['token']);
            }
        });
    }

    requestPasswordLink(){
        this.userService.requestNewPassword(this.form.value).subscribe(res => {
            this.ts.get('forgotpass.successfullPasswordRequest').subscribe(ts => {
                this.toaster.success(ts);
                this.router.navigate(["/login"]);
            });
        }, err => {
            this.form.get('username').setErrors({
                invalid: true
            });
        });
    }

    resetPassword(){
        this.userService.resetPassword(this.token, this.form.value).subscribe(res => {
            this.context = 'success';
        });
    }

    get classes() {
        return "strength-" + this.currentStrength;
    }

    private checkTokenAndDisplayForm(token:any) {
        this.userService.checkPasswordToken(token).subscribe(res => {
            this.form = ResetPassword.resetForm();
            this.context = 'reset';
            this.form.get('password').valueChanges.subscribe(
                () => this.currentStrength = getPasswordStrength(this.form.get('password').value)
            );
        }, err => {
            //this.form = ResetPassword.resetForm();
            this.context = 'error';
        });
    }
}
