import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { EventService } from '../../../service/event.service';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-calendar-list',
    templateUrl: './calendar-list.component.html',
    styleUrls: ['./calendar-list.component.scss'],
})
export class CalendarListComponent implements OnInit {

    @Input('events')
    events;
    @Input()
    viewDate;
    @Input()
    view;

    @Output()
    onEdit: EventEmitter<any> = new EventEmitter<any>();
    @Output()
    onDayClicked: EventEmitter<any> = new EventEmitter<any>();

    constructor(private eventService:EventService, private ts: TranslateService, private router: Router) {}

    ngOnInit() {
    }

    edit(event){
        this.onEdit.emit(event);
    }

    dayClicked(date: Date) {
        this.onDayClicked.emit(date);
    }

    eventClicked(event) {
        this.router.navigate(['/social/event/:id', {id: event.event.id}]);
    }

}
