import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import * as _ from 'lodash';
import { UserService } from '../../../service/user.service';
import { DocumentService } from '../../../service/document.service';
import { environment } from '../../../../environments/environment';
import { ModalService } from '../../../service/modal.service';

@Component({
    selector: 'app-document-item',
    templateUrl: './document-item.component.html',
    styleUrls: ['./document-item.component.scss']
})
export class DocumentItemComponent implements OnInit {

    @Input('document')
    document;

    @Input('onSkillSelected')
    onSkillSelected: EventEmitter<any>;

    @Input('documents')
    documents;

    @Output()
    onEdit: EventEmitter<any> = new EventEmitter<any>();
    
    me;

    constructor(
        private userService: UserService,
        private documentService: DocumentService,
        private modalService: ModalService
    ) {
    }

    ngOnInit() {
        this.me = this.userService.u;
        this.userService.userObservable.subscribe(u => this.me = u);
    }

    edit() {
        this.onEdit.emit(this.document);
    }

    delete() {
        this.modalService.confirm('document.deletion.title', 'document.deletion.message')
            .subscribe(() => {
                this.documentService.remove(this.document.id).subscribe(res => {
                    const entities = _.remove(this.documents.entities, d => (<any>d).id == this.document.id);
                    this.documents.count -= entities.length;
                });
            });
    }

    isAdmin() {
        return this.me && _.find(this.me.roles, r => r == 'ADMIN');
    }

    canEdit() {
        return (this.me && _.find(this.me.roles, r => (r == 'ADMIN' || r == 'SUB_ADMIN')) || this.isSelf());
    }

    isSelf() {
        return _.get(this, 'me.id') == _.get(this, 'document.creator');
    }

    isFolder() {
        return 'folder' == _.get(this, 'document.mediaType');
    }

    isFile() {
        return 'document' == _.get(this, 'document.mediaType');
    }

    get downloadLink() {
        return environment.api + "/media/download/" + this.document.id;
    }
}
