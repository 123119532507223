import { Occupant } from '../model/occupant';
import { Message } from '../model/message';
import { Conversation } from '../model/conversation';
import uniqBy from 'lodash-es/uniqBy';
import clone from 'lodash-es/clone';

export function orderedAndMapped(occupants: Occupant[], messages: Message[]) {
    return uniqBy(messages.map(message => ({
        ...message,
        sender: occupants.find(o => o.id === message.creator)
    } as Message)).sort((m1, m2) =>  m1.created > m2.created ? 1 : -1), 'id');
}

export function byModifiedDesc(items: Conversation[]): Conversation[] {
    return clone(items.sort((m1, m2) =>  m1.updated > m2.updated ? -1 : 1));
}
