import { Component, Input, OnInit, ViewChild, SimpleChanges, AfterViewInit } from '@angular/core';
import { OfficeService } from '../../service/office.service';
import * as _ from 'lodash';
import { SelectComponent } from 'ng2-select/index';
import { NgSelectComponent } from '@ng-select/ng-select';

@Component({
    selector: 'app-delegation-selector',
    templateUrl: './delegation-selector.component.html',
    styleUrls: ['./delegation-selector.component.scss']
})
export class DelegationSelectorComponent implements OnInit, AfterViewInit {

    @Input("officeId")
    officeId;

    multiple = false;

    delegations = [];

    @Input("form")
    form;

    @ViewChild(NgSelectComponent, { static: false })
    select: NgSelectComponent;

    constructor(private officeService: OfficeService) {
    }

    ngOnInit() {

    }

    ngAfterViewInit() {
        this.load();
    }


    ngOnChanges(changes: SimpleChanges): void {
        if ('officeId' in changes) {
            this.load();
        }
    }

    private load() {
        this.officeService.getOffice(this.officeId).subscribe(o => {
            let newDelegations = [];
            o.delegations.map(d => {
                newDelegations.push({
                    id: d.id,
                    text: d.title
                });
            });

            this.delegations = newDelegations;

            if (this.select && this.form.value.delegation && this.delegations.length > 0) {
                this.select.writeValue(_.find(this.delegations, d => d.id == this.form.value.delegation.id).id);
            }

            if (this.select && this.form.value.delegationId && this.delegations.length > 0) {
                this.select.writeValue(_.find(this.delegations, d => d.id == this.form.value.delegationId).id);
            }
        });
    }


    refreshValue(item) {
        this.form.patchValue({ delegationId: item.id });
    }

}
