import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'formatted'
})
export class FormattedPipe implements PipeTransform {

  public transform(value: any, args?: any): any {
    return moment.unix(value / 1000).calendar();
  }

}

