import { Component, Input } from '@angular/core';
import * as _ from 'lodash';

@Component({
    selector: 'app-grouped-feed-item',
    templateUrl: './grouped-feed-item.component.html',
    styleUrls: ['./grouped-feed-item.component.scss']
})
export class GroupedFeedItemComponent {

    @Input()
    item;
    @Input('ngClass')
    ngClass;

    constructor() {
    }

    getLatest(){
        return _.first(_.map(this.item.items, (item: any) => item.updated).sort().reverse());
    }

    isJoinEvent(){
        return this.item.eventTranslationKey.match(/join/);
    }

}
