import { FormControl, FormGroup, Validators } from '@angular/forms';

export class Media {
    static form() {
        return new FormGroup({
            id: new FormControl(),
            title: new FormControl(null, [Validators.required]),
            mediaType: new FormControl('document', []),
            file: new FormControl(null, [Validators.required]),
            attachments: new FormControl(null),
            groupId: new FormControl(''),
            active: new FormControl(true),
            parentId: new FormControl(null, [Validators.required]),
        });
    }

    static folderForm() {
        return new FormGroup({
            id: new FormControl(),
            title: new FormControl(null, [Validators.required]),
            mediaType: new FormControl('folder', []),
            parentId: new FormControl(),
        });
    }

    static pressbookForm() {
        return new FormGroup({
            id: new FormControl('', []),
            title: new FormControl('', [Validators.required]),
            skillIds: new FormControl('', [Validators.required]),
            officeIds: new FormControl('', []),
            file: new FormControl('', [Validators.required]),
            parentId: new FormControl(),
        });
    }

    static observatoryForm() {
        return new FormGroup({
            id: new FormControl('', []),
            title: new FormControl('', [Validators.required]),
            skillIds: new FormControl('', [Validators.required]),
            officeIds: new FormControl('', [Validators.required]),
            file: new FormControl('', [Validators.required]),
            parentId: new FormControl(),
        });
    }
}
