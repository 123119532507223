import { ChangeDetectionStrategy, Component, EventEmitter, Inject, OnInit, Output, ViewChild } from '@angular/core';
import { ChatForm } from '../../forms/chat.form';
import { concat, Observable, of, Subject, throwError } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, switchMap, tap } from 'rxjs/operators';
import { I_USER_LOCATOR, IUserLocator } from '../../interfaces/user-locator.interface';
import { NgSelectComponent } from '@ng-select/ng-select';
import { OccupantForm } from '../../forms/occupant.form';
import { ChatService } from '../../services/chat.service';
import { Conversation } from '../../model/conversation';

@Component({
    selector: 'app-chat-form',
    templateUrl: './chat-form.component.html',
    styleUrls: ['./chat-form.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChatFormComponent implements OnInit {
    
    public people: Observable<any[]>;
    public peopleLoading = false;
    public readonly peopleInput = new Subject<string>();
    
    public readonly form = new ChatForm();
    @ViewChild(NgSelectComponent, {static: true})
    public select: NgSelectComponent;
    public loading: Subject<boolean> = new Subject<boolean>();
    @Output()
    public conversationSaved = new EventEmitter<Conversation>();
    @Output()
    public cancel = new EventEmitter<void>();
    
    constructor(
        @Inject(I_USER_LOCATOR) private readonly userLocator: IUserLocator,
        private readonly chatService: ChatService,
    ) {}
    
    public ngOnInit(): void {
        this.people = concat(
            of([]),
            this.loadPeople()
        );
    }
    
    public trackById(index, item) {
        return item.id;
    }
    
    public onUserAdded(user) {
        if (user) {
            // this.select.clearItem(user);
            this.form.addOccupant(new OccupantForm(user));
        }
    }
    
    public removeOccupant(occupant: OccupantForm) {
        this.form.removeOccupant(occupant);
    }
    
    public saveGroup() {
        this.loading.next(true);
        this.chatService.saveGroup(this.form.value)
            .pipe(
                tap(() => {
                    this.loading.next(false);
                    this.cancel.emit();
                    this.form.reset();
                }),
                catchError(e => {
                    this.loading.next(false);
                    return throwError(e);
                })
            )
            .subscribe();
    }
    
    private loadPeople(): Observable<any[]> {
        return this.peopleInput.pipe(
            debounceTime(200),
            distinctUntilChanged(),
            tap(_ => this.peopleLoading = true),
            switchMap(term => this.userLocator.findUser(term)
                .pipe(
                    catchError(_ => of([])),
                    tap(_ => this.peopleLoading = false)
                )
            )
        );
    }

    
}
