import { CommonModule } from '@angular/common';
import { InjectionToken, ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgSelectModule } from '@ng-select/ng-select';
import { ActionReducerMap, StoreModule } from '@ngrx/store';
import { NguiInviewModule, NguiListModule, NguiUtilsModule } from '@ngui/common';
import { TranslateModule } from '@ngx-translate/core';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { MomentModule } from 'ngx-moment';
import { SharedModule } from '../shared/shared.module';
import { AppChatIconComponent } from './components/app-chat-icon/app-chat-icon.component';
import { ChatAttachmentItemComponent } from './components/chat-attachment-item/chat-attachment-item.component';
import { ChatAttachmentListComponent } from './components/chat-attachment-list/chat-attachment-list.component';
import { ChatAttachmentPickerComponent } from './components/chat-attachment-picker/chat-attachment-picker.component';
import { ChatBarComponent } from './components/chat-bar/chat-bar.component';
import { ChatFormSearchUserComponent } from './components/chat-form-search-user/chat-form-search-user.component';
import { ChatFormWrapperComponent } from './components/chat-form-wrapper/chat-form-wrapper.component';
import { ChatFormComponent } from './components/chat-form/chat-form.component';
import { ChatListItemComponent } from './components/chat-list-item/chat-list-item.component';
import { ChatListComponent } from './components/chat-list/chat-list.component';
import { ChatMessageAttachmentComponent } from './components/chat-message-attachment/chat-message-attachment.component';
import { ChatMessageFormComponent } from './components/chat-message-form/chat-message-form.component';
import { ChatMessageItemComponent } from './components/chat-message-item/chat-message-item.component';
import { ChatMessageListComponent } from './components/chat-message-list/chat-message-list.component';
import { ChatOptionsComponent } from './components/chat-options/chat-options.component';
import { ChatPanelComponent } from './components/chat-panel/chat-panel.component';
import { ChatUserAvatarComponent } from './components/chat-user-avatar/chat-user-avatar.component';
import { OccupantFormItemComponent } from './components/occupant-form-item/occupant-form-item.component';
import {
    CHAT_SERVICE_CONFIG,
    IChatServiceConfiguration,
    SERVICE_URL
} from './interfaces/chat-service-configuration.interface';
import { I_TOKEN_PROVIDER } from './interfaces/token-provider.interface';
import { I_USER_LOCATOR } from './interfaces/user-locator.interface';
import { ComputedLabelPipe } from './pipes/computed-label.pipe';
import { FormattedPipe } from './pipes/formatted.pipe';
import { ChatRepository } from './repositories/chat.repository';
import { ChatService } from './services/chat.service';
import { ChatStoreModel, reducers } from './store/reducers';
import { ModalService } from '../../service/modal.service';


export const REDUCER_TOKEN = new InjectionToken<ActionReducerMap<{ chat: ChatStoreModel }>>('root reducer');

@NgModule({
    declarations: [
        ChatListComponent,
        ChatListItemComponent,
        ChatFormComponent,
        OccupantFormItemComponent,
        ChatUserAvatarComponent,
        ChatPanelComponent,
        ChatMessageFormComponent,
        ChatMessageListComponent,
        ChatMessageItemComponent,
        ChatOptionsComponent,
        ChatBarComponent,
        FormattedPipe,
        ComputedLabelPipe,
        AppChatIconComponent,
        ChatAttachmentPickerComponent,
        ChatAttachmentItemComponent,
        ChatAttachmentListComponent,
        ChatMessageAttachmentComponent,
        ChatFormWrapperComponent,
        ChatFormSearchUserComponent],
    exports: [
        ChatListComponent,
        ChatFormComponent,
        ChatPanelComponent,
        ChatBarComponent
    ],
    imports: [
        TranslateModule,
        CommonModule,
        ReactiveFormsModule,
        FormsModule,
        NgSelectModule,
        InfiniteScrollModule,
        NguiInviewModule,
        NguiUtilsModule,
        NguiListModule,
        MomentModule,
        RouterModule,
        SharedModule,
        StoreModule.forRoot(REDUCER_TOKEN)
    ],
    providers: [
        ModalService
    ],
})
export class ChatModule {
    public static forRoot(configuration: IChatServiceConfiguration): ModuleWithProviders {
        return {
            ngModule: ChatModule,
            providers: [
                ChatService,
                ChatRepository,
                { provide: I_TOKEN_PROVIDER, useClass: configuration.provider },
                { provide: SERVICE_URL, useValue: configuration.url },
                { provide: I_USER_LOCATOR, useExisting: configuration.userLocator },
                { provide: CHAT_SERVICE_CONFIG, useValue: configuration },
                { provide: REDUCER_TOKEN, useValue: reducers }
            ]
        }
    }
}
