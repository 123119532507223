import { Component, OnInit, ChangeDetectionStrategy, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { Media } from '../../../domain/media';
import { FormGroup } from '@angular/forms';
import * as _ from 'lodash';
import { TranslateService } from '@ngx-translate/core';
import { DocumentService } from '../../../service/document.service';
import { SkillSelectorComponent } from '../../../shared/skill-selector/skill-selector.component';

@Component({
  selector: 'app-document-inner-form',
  templateUrl: './document-inner-form.component.html',
  styleUrls: ['./document-inner-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DocumentInnerFormComponent implements OnInit {

  form: FormGroup = Media.form();

  @Output()
  add = new EventEmitter<void>();

  @ViewChild(SkillSelectorComponent, { static: false })
  skillSeletor: SkillSelectorComponent;

  isShowContent = true;

  constructor(private ts: TranslateService,
    private documentService: DocumentService) { }

  ngOnInit() {
    this.onShowContent();
  }

  save() {
    const formValue = _.clone(this.form.value);
    delete formValue.attachments;
    this.documentService.save(formValue).subscribe((document) => {
      this.form.reset(Media.form().value);
      this.skillSeletor.setValue(null);
      this.add.emit();
    });
  }

  onFileSelected(e) {
    if (_.get(e, 'target.files.length') > 0) {
      this.form.patchValue({ file: e.target.files[0] });
    } else {
      this.form.patchValue({ file: undefined });
    }
  }

  get fileLabel() {
    if (this.form.value.file === undefined || this.form.value.file === null)
      return this.ts.instant("form.file.not");
    else
      return this.form.value.file.name;
  }

  onShowContent() {
    if (window.innerWidth < 960)
      this.isShowContent = !this.isShowContent;
  }
}
