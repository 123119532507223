import { FormControl, FormGroup, Validators } from '@angular/forms';

export class Link {
    static form () {
        return new FormGroup({
            id: new FormControl(),
            description: new FormControl(null, [Validators.required]),
            buttonText: new FormControl(null, [Validators.required]),
            href: new FormControl(null, [Validators.required]),
            linkGroupId: new FormControl(null, [Validators.required]),
            position: new FormControl(0)
        });
    }
}
