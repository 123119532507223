import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ChatStoreModel } from '../../store/reducers';
import { Store } from '@ngrx/store';
import { closeFormPanel } from '../../store/actions';

@Component({
    selector: 'app-chat-form-wrapper',
    templateUrl: './chat-form-wrapper.component.html',
    styleUrls: ['./chat-form-wrapper.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChatFormWrapperComponent {
    
    constructor(
        private readonly store: Store<ChatStoreModel>
    ) {
    }
    
    public goToMyDiscussions() {
        this.store.dispatch(closeFormPanel());
    }
}
