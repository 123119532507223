import { Component, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { EventService } from '../../service/event.service';
import * as _ from 'lodash';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { CalendarListComponent } from './calendar-list/calendar-list.component';
import { ModalService } from '../../service/modal.service';
import { CalendarFormComponent } from './calendar-form/calendar-form.component';
import { CalendarMonthViewComponent } from 'angular-calendar';
import { TranslateService } from '@ngx-translate/core';
import { forkJoin } from 'rxjs';
import { CalendarDate } from '../../shared/calendar-widget/calendar-widget.component';

@Component({
    selector: 'app-calendar',
    templateUrl: './calendar.component.html',
    styleUrls: ['./calendar.component.scss']
})
export class CalendarComponent implements OnInit {

    events;

    eventsMonth: any[] = [];

    @ViewChild(CalendarListComponent, { static: false })
    public list: CalendarListComponent;

    @ViewChild(CalendarMonthViewComponent, { static: false })
    cmvc: CalendarMonthViewComponent;

    public viewDate: Date = new Date();
    public view: string = 'month';
    public fullView: string = 'table';

    currentSelectedDate = new Date();
    selectedDates: CalendarDate[] = [];

    form: FormGroup = new FormGroup({
        page: new FormControl(1),
        q: new FormControl(),
        officeId: new FormControl()
    });

    constructor(private eventService: EventService,
        private router: Router,
        private ms: ModalService,
        private ts: TranslateService,
        private vcr: ViewContainerRef) {

    }

    ngOnInit() {
        this.form.get('q').valueChanges.subscribe(v => {
            this.form.get('page').setValue(1, { emitEvent: false });
            this.events = undefined;
            this.loadEvents();
        });

        this.form.get('officeId').valueChanges.subscribe(v => {
            this.form.get('page').setValue(1, { emitEvent: false });
            this.events = undefined;
            this.loadEvents();
        });

        this.loadEvents();
    }

    onEvent(event) {
        if (_.find(this.events.entities, e => (<any>e).id == event.id)) {
            _.forEach(this.events.entities, e => {
                if (e.id == event.id) {
                    event.start = moment(event.start).toDate();
                    event.end = moment(event.end).toDate();
                    e = _.extend(e, event);
                }
            });
            this.view = '';
            setTimeout(() => this.view = 'month', 0);
        } else {
            this.events.entities.push(event);
            this.router.navigate(['/social/event', event.id]);
        }
    }

    onAdd() {
        this.form.get('page').setValue(1);
        this.events = undefined;
        this.loadEvents();
    }

    editOrCreate(event) {
        const modal = this.ms.openModal(CalendarFormComponent, this.vcr);
        if (event) {
            const cloned = _.clone(event);
            cloned.start = moment(cloned.start).format('DD/MM/YYYY HH:mm');
            cloned.end = moment(cloned.end).format('DD/MM/YYYY HH:mm');
            modal.instance.form.patchValue(cloned);            
            if (cloned.officeId !== null) {
                modal.instance.officeId = cloned.officeId;
                modal.instance.hideGroup = true;
            }
            else {
                modal.instance.groupId = cloned.groupId;
                modal.instance.hideOffice = true;
            }
        }
        modal.instance.onClose.subscribe(e => {
            this.onEvent(e);
            modal.destroy();
        });
        modal.instance.onDiscard.subscribe(() => modal.destroy());
    }

    private loadEvents() {
        let start = new Date(this.currentSelectedDate.getFullYear(), this.currentSelectedDate.getMonth(), 1);
        let end = new Date(this.currentSelectedDate.getFullYear(), this.currentSelectedDate.getMonth() + 1, 0);

        forkJoin(
            this.eventService.getEventsBetween(start.getTime(), end.getTime(), this.form.value),
            this.eventService.getEvents(this.form.value)
        ).subscribe(([eventsMonth, events]) => {
            this.eventsMonth = eventsMonth;

            if (this.form.get('page').value > 1) {
                this.events.entities = this.events.entities.concat(events.entities);
            } else {
                this.events = events;
            }
            this.events.entities = this.events.entities.filter(event => this.eventsMonth.findIndex(e => e.id === event.id) === -1);

            this.selectedDates = [];
            this.eventsMonth.forEach(e => {
                let date = new CalendarDate();
                date.mDate = moment(e.start);
                this.selectedDates.push(date);
            });
        });
    }

    onScroll() {
        if ((this.events.entities.length + this.eventsMonth.length) !== this.events.count) {
            this.form.patchValue({ page: ++this.form.value.page }, { emitEvent: false });
            this.loadEvents();
        }
    }

    get isAdmin() {
        return _.find(<Array<any>>_.get(this, 'user.roles'), r => (r == 'ADMIN' || r == 'SUB_ADMIN'));
    }

    onChangedMonth(date: moment.Moment) {
        this.currentSelectedDate = date.toDate();
        this.loadEvents();
    }

    onDateSelected(date: CalendarDate) {
        const event = this.eventsMonth.find(e => moment(e.start).isSame(date.mDate, 'day'));
        this.router.navigate(['/social/event/:id', { id: event.id }]);
    }
}
