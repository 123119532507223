import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { OfficeService } from '../../service/office.service';
import { SkillService } from '../../service/skill.service';
import { User } from '../../domain/user';
import * as _ from 'lodash';

@Component({
    selector: 'app-function-in-office-selector-wrapper',
    templateUrl: './function-in-office-selector-wrapper.component.html',
    styleUrls: ['./function-in-office-selector-wrapper.component.scss']
})
export class FunctionInOfficeSelectorWrapperComponent implements OnInit {

    @Input()
    formArray: FormArray;    
    skills;
    @Input()
    editor: User;

    constructor(private officeService: OfficeService, private skillService: SkillService) {}

    ngOnInit() {
        this.skillService.getSkills().subscribe(skills => {
            this.skills = _.filter(skills, (s:any) => s.skillType == 'FUNCTION');
            this.skills = _.orderBy(this.skills, [item => item.translationKey]); 
        });
    }

    addFunctionInOffice(){
        this.formArray.push(new FormGroup({
            officeId: new FormControl('', [Validators.required]),
            delegationId: new FormControl(),
            skillId: new FormControl(),
        }));
    }

    isAdmin(){
        return _.find(<Array<any>>_.get(this, 'editor.roles'), r => r == 'ADMIN');
    }

}
