import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import { ToastrService } from 'ngx-toastr';
import { NewsletterParams } from '../../domain/newsletter-params';
import { NewsletterService } from '../../service/newsletter.service';
import * as moment from 'moment';
import { ConfigService } from '../../service/config.service';
import { SiteConfiguration } from '../../domain/site-configuration';
import { SendEmailParams } from '../../domain/send-email-params';
import { SendEmailService } from '../../service/send-email.service';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';

@Component({
    selector: 'app-settings',
    templateUrl: './settings.component.html',
    styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {

    public Editor = ClassicEditor;

    form: FormGroup =  NewsletterParams.form();
    siteForm: FormGroup =  SiteConfiguration.form();
    sendEmailForm: FormGroup =  SendEmailParams.form();
    activeTab = 'newsletter';
    selectedSendEmailFor = null;
    fileName = '';
    ckeditorConfig: any = {
        toolbarGroups: [
            { name: 'basicstyles' },
            { name: 'links' }
        ],
        toolbar: {
            items: [
                'heading',
                '|',
                'bold',
                'italic',
                'link',
                'bulletedList',
                'numberedList',
                '|',
                'undo',
			    'redo'
            ]
        },
    };

    constructor(
        private newsletterService: NewsletterService,
        private sendEmailService: SendEmailService,
        private ts: TranslateService,
        private toaster: ToastrService,
        private configService: ConfigService
    ) {
    }

    ngOnInit() {
        this.newsletterService.getNewsletterParams().subscribe(
            (params: any) => {
                this._rebuildForm(params);
            }
        );
        
        this.siteForm.patchValue(this.configService.config);
    }

    private _rebuildForm(params) {
        this.form.patchValue(params);
        this.form.get('firstExecutiontime').setValue(moment(params.firstExecutiontime, 'HH:mm').toDate());
        this.form.get('firstExecutiondayOfWeek').setValue(String(params.firstExecutiondayOfWeek));

        this.form.get('secondExecutiontime').setValue(moment(params.secondExecutiontime, 'HH:mm').toDate());
        this.form.get('secondExecutiondayOfWeek').setValue(String(params.secondExecutiondayOfWeek));
    }

    saveIntraaLive() {
        let params = _.clone(this.form.value);
        params.firstExecutiontime = moment(params.firstExecutiontime).format('HH:mm');
        params.secondExecutiontime = moment(params.secondExecutiontime).format('HH:mm');
        
        this.newsletterService.saveNewsletterParams(params).subscribe(
            (params) => {
                this.ts.get(`toasts.successfullSaveNewsLetter`).subscribe(ts => this.toaster.success(ts));
                this._rebuildForm(params);
            }
        );
    }

    sendMeIntraaLive() {
        this.newsletterService.sendmeNewsletter().subscribe(
            () => this.ts.get(`toasts.successfullSendNewsLetter`).subscribe(ts => this.toaster.success(ts))
        );
    }

    sendEmail() {
        let params = _.clone(this.sendEmailForm.value);

        this.sendEmailService.sendEmail(params).subscribe(() => {
            this.selectedSendEmailFor = null;
            this.sendEmailForm.reset();
            this.fileName = '';
            this.ts.get(`toasts.successfullSendNewsLetter`).subscribe(ts => this.toaster.success(ts));
        });
    }
    
    save(event: any){
        this.configService.save(this.siteForm.value).subscribe(cfg => {
            this.ts.get('toasts.successfullConfigChange').subscribe(ts => this.toaster.success(ts));
        });
    }
    
    setActiveTab(tab: string) {
        this.activeTab = tab;
    }

    onChangeSendEmailFor(event) {
        if (event.isUserInput) {
            this.selectedSendEmailFor = event.source.value;
            
            this.sendEmailForm.reset({
                userIds: null,
                officeIds: null,
                groupIds: null
            });
        }
    }

    onFileSelected(event) {
        const file = (event.target as HTMLInputElement).files[0];

        if (file) {
            this.fileName = file.name;

            this.sendEmailForm.patchValue({ file: file });
        }
    }

    patchMembers(members) {  
        this.sendEmailForm.patchValue({ userIds: members });
    }
}
