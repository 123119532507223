import { Component, OnInit, ChangeDetectionStrategy, Input, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';
import { UserService } from '../../../service/user.service';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import { ChatService } from '../../../modules/chat/services/chat.service';

@Component({
  selector: 'app-user-item-small',
  templateUrl: './user-item-small.component.html',
  styleUrls: ['./user-item-small.component.scss'],
  //changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserItemSmallComponent implements OnInit {

  @Input()
  user: any;

  me;
  favorites;

  constructor(
    private userService: UserService,
    private toaster: ToastrService,
    private ts: TranslateService,
    private cdr: ChangeDetectorRef,
    private readonly chatService: ChatService
  ) {
  }


  ngOnInit() {
    this.favorites = this.userService.favs;
    this.userService.favoritesObservable.subscribe(u => {
      this.favorites = u;
    });
    this.me = this.userService.u;
    this.userService.userObservable.subscribe(u => {
      this.me = u;
    });
  }

  get getDisplayName() {
    if (this.user) {
      if (!this.user.firstname && !this.user.lastname) {
        return this.user.username;
      }
      return `${this.user.firstname} ${this.user.lastname}`;
    }
    return "";
  }

  get userOffice() {
    if (this.user) {
      if (this.user.functionInOffices.length > 0)
        return this.user.functionInOffices[0].office.title;
    }
    return "";
  }

  get userFunction() {
    if (this.user) {
      if (this.user.functionInOffices.length > 0 && this.user.functionInOffices[0].skill)
        return this.user.functionInOffices[0].skill.translationKey;
    }
    return "";

  }

  manageFavorite() {
    if (!this.isFavorite) {
      this.userService.addToFavorites(this.user.id).subscribe(f => {
        this.ts.get('toasts.addedFavorite', { username: this.getDisplayName }).subscribe(t => {
          this.toaster.success(t);
        });
      });
    } else {
      this.userService.removeFromFavorites(this.user.id).subscribe(f => {
        this.ts.get('toasts.removedFavorite', { username: this.getDisplayName }).subscribe(t => {
          this.toaster.success(t);
        });
      });
    }
  }

  isSelf() {
    return !_.isUndefined(this.me)
      && !_.isUndefined(this.user)
      && this.me.id == this.user.id;
  }

  get isFavorite() {
    return _.find(this.favorites, f => f['id'] == this.user.id);
  }

  getSkills() {
    return _.filter(this.user.skills, skill => (<any>skill).skillType != "FUNCTION").slice(0, 6);
  }

  isAdmin() {
    return _.find(_.get(this, 'me.roles'), (r: any) => r == 'ADMIN' || r == 'SUB_ADMIN');
  }
  
  public createDiscussion() {
      this.chatService.saveGroup({
          occupants: [this.user]
      }).subscribe();
  }

}
