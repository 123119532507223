import { HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest } from '@angular/common/http'
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { StorageService } from '../service/storage.service';
import { environment } from '../../environments/environment';

@Injectable()
export class ApiCallInterceptor implements HttpInterceptor {
    
    constructor(
        private readonly storageService: StorageService
    ) {}

    public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let headers = new HttpHeaders();
        if (this.storageService.hasToken()) {
            headers = headers.append('Authorization', this.storageService.getToken().token);
        }
        req = req.clone({
            url: req.url.replace('/api', environment.api),
            headers
        });
        return next.handle(req);
    }

}
