import { FormControl, FormGroup, Validators } from '@angular/forms';

export class SiteConfiguration {
    static form() {
        return new FormGroup({
            footerText: new FormControl('', []),
            disclaimer: new FormControl('', [Validators.required]),
            siteUrl: new FormControl('', []),
            siteName: new FormControl('', [Validators.required]),
            iosApp: new FormControl(),
            androidApp: new FormControl()
        });
    }
}
