import { FormGroup, FormControl, Validators } from "@angular/forms";
import { MoreValidators } from '../validation/more-validators';

export class SendEmailParams {
    static form(){
        return new FormGroup({
            subject: new FormControl('', [Validators.required]),
            message: new FormControl('', [Validators.required]),
            file: new FormControl(null, Validators.compose([
                MoreValidators.mimes('pdf, jpg, png, doc, docx, xls, xlsx'),
                MoreValidators.size('5242880')
            ])),
            sendEmailFor: new FormControl('', [Validators.required]),
            userIds: new FormControl('', []),
            groupIds: new FormControl('', []),
            officeIds: new FormControl('', []),
        });
    }
}
