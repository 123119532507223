import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild, ChangeDetectorRef } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { NgSelectComponent } from '@ng-select/ng-select';
import * as _ from 'lodash';
import { GroupService } from '../../service/group.service';
import { finalize } from 'rxjs/operators';

@Component({
    selector: 'app-group-selector',
    templateUrl: './group-selector.component.html',
    styleUrls: ['./group-selector.component.scss']
})
export class GroupSelectorComponent implements OnInit {


    groups = null;

    @Input('form')
    form: FormGroup;

    @Input('multiple')
    multiple: boolean = false;

    @Input('customClass')
    class: string;

    @ViewChild(NgSelectComponent, { static: true })
    select: NgSelectComponent;

    @Output()
    onReady: EventEmitter<any> = new EventEmitter<any>();

    @Output()
    onGroupSelected: EventEmitter<any> = new EventEmitter();

    formPager: FormGroup = new FormGroup({
        page: new FormControl(1),
        q: new FormControl(""),
        sortOrder: new FormControl("{title: 1}"),
        groupType: new FormControl("GROUP")
    });

    isLoading = false;

    constructor(
        private groupService: GroupService,
        private cdr: ChangeDetectorRef) {
    }

    ngOnInit() {
        this.load(true);
    }

    get customClasses() {
        return this.class + " ng-select";
    }

    private load(isFirst = false, setValue = null) {
        this.isLoading = true;
        this.groupService.getGroupsForSelector(this.formPager.value)
            .pipe(
                finalize(() => {
                    this.isLoading = false;
                    this.cdr.detectChanges();
                })
            ).subscribe((groups: any) => {
                if (this.formPager.get('page').value > 1) {
                    this.groups.entities = this.groups.entities.concat(groups.entities);
                } else {
                    this.groups = groups;
                }

                if (isFirst)
                    this.onReady.emit({});

                if (setValue) {
                    this.setValue(setValue);
                }
            });
    }

    onAdd() {        
        if (this.formPager.get('q').value !== "")
            this.search("");
    }

    search(search) {
        this.formPager.patchValue({ page: 1 });
        this.formPager.patchValue({ q: search.term });
        this.load();
    }

    searchFn(term, item) {
        return true;
    }

    onScrollEnd() {
        if (!this.isLastPage()) {
            this.formPager.patchValue({ page: ++this.formPager.value.page });
            this.load();
        }
    }

    private isLastPage(): boolean {
        return (_.get(this.groups, "entities.length") == _.get(this.groups, "count"));
    }

    refreshValue(value) {
        if (value) {
            if (this.form) {
                if (this.multiple) {
                    this.form.patchValue({
                        groupIds: _.transform(value, (res, i) => {
                            res.push((<any>i).id);
                            return true;
                        }, [])
                    });
                } else {
                    this.form.patchValue({ groupId: value.id });
                }
            } else {
                this.onGroupSelected.emit(value);
            }
        }
        else {
            this.form.patchValue({ groupId: null });
            if (!this.form)
                this.onGroupSelected.emit(null);
        }
    }


    ngOnChanges(changes: SimpleChanges): void {
        if ('form' in changes) {
            this.clear();
        }
    }

    clear() {
        this.select.writeValue(undefined);
    }

    private loadAndSetValue(value: any) {
        this.formPager.patchValue({ page: ++this.formPager.value.page });
        this.load(false, value);
    }

    setValue(value: any) {

        if (!value) {
            this.clear();
            return;
        }

        if (!this.multiple) {
            let data = _.find(this.groups.entities, o => (<any>o).id == value);
            if (!data && !this.isLastPage())
                return this.loadAndSetValue(value);

            if (this.form) {
                this.form.patchValue({ officeId: data.id });
            }
            this.select.writeValue(data.id);
        } else {
            if (!this.isLastPage())
                return this.loadAndSetValue(value);

            if (_.isArray(value)) {
                let data = _.filter(this.groups.entities, o => {
                    return value.indexOf((<any>o).id) != -1;
                });
                this.select.writeValue(_.map(data, d => d.id));
            } else {
                this.clear();
            }
        }
    }
}
