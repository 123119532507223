import { FormControl, FormGroup, Validators } from '@angular/forms';

export class Skill {
    static form(){
        return new FormGroup({
            skillType: new FormControl('', [Validators.required]),
            translationKey: new FormControl(null, [Validators.required]),
            id: new FormControl()
        });
    }

    static adminForm() {
        return new FormGroup({
            skillType: new FormControl('', [Validators.required]),
            translationKey: new FormControl('', [Validators.required]),
            id: new FormControl(),
            valid: new FormControl(),
            active: new FormControl()
        });
    }
}
