import { Component, Input, OnInit } from '@angular/core';
import { UserService } from '../../../service/user.service';
import { ToastrService } from 'ngx-toastr';
import * as _ from 'lodash';
import { GroupService } from '../../../service/group.service';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-user-avatar',
    templateUrl: './user-avatar.component.html',
    styleUrls: ['./user-avatar.component.scss']
})
export class UserAvatarComponent implements OnInit {

    favorites;
    @Input('user')
    user;
    @Input('width')
    width;
    @Input('height')
    height;
    @Input('extraClass')
    extraClass;
    @Input()
    disabled;
    @Input('disableClick')
    disableClick: boolean;
    me;
    timestamp = new Date().getTime();


    constructor(private userService: UserService,
                private ts: TranslateService,
                private toaster: ToastrService,
                private groupService: GroupService,
                private router: Router) {
    }

    ngOnInit() {
        this.favorites = this.userService.favs;
        this.userService.favoritesObservable.subscribe(f => {
            this.favorites = f;
        });
        this.me = this.userService.u;
        this.userService.userObservable.subscribe(u => {
            this.me = u;
        });

        this.userService.onUpload.subscribe(u => {
            this.timestamp = new Date().getTime();
        });
    }

    isFavorite(){
        return !_.isUndefined(_.find(this.favorites, f => f['id'] == this.user.id));
    }

    manageFavorite(){
        if(!this.isFavorite()){
            this.userService.addToFavorites(this.user.id).subscribe(f => {
                this.ts.get('toasts.addedFavorite', { username: `${this.user.firstname} ${this.user.lastname}` }).subscribe(t => {
                    this.toaster.success(t);
                });
            });
        }else {
            this.userService.removeFromFavorites(this.user.id).subscribe(f => {
                this.ts.get('toasts.removedFavorite', { username: `${this.user.firstname} ${this.user.lastname}` }).subscribe(t => {
                    this.toaster.success(t);
                });
            });
        }
    }

    getStyle(){
        return {
            width: this.width,
            height: this.height
        };
    }

    isSelf(){
        return _.get(this, 'user.id') == _.get(this, 'me.id');
    }

    createDiscussion(){
        this.groupService.save({
            title: `${this.me.firstname} - ${this.user.firstname}`,
            groupType: "DISCUSSION",
            privacy: "PRIVATE",
            members: [this.user.id, this.me.id],
            active: true
        }).subscribe(g => {
            this.router.navigate(['/social/discussion/view', g.id]);
        });
    }

    isAdmin(){
        return _.find(<Array<any>>_.get(this, 'me.roles'), r => r == 'ADMIN');
    }

    onFileDropped(fileList: FileList){
        if(fileList.length > 0 && this.checkIsImage(fileList.item(0))){
            this.userService.uploadUserImage(this.user.id, fileList.item(0)).subscribe((user) => {
                this.user = user;
                this.timestamp = new Date().getTime();
            });
        }
    }

    checkIsImage(file){
        var validImageTypes = ["image/gif", "image/jpeg", "image/png"];
        return (_.indexOf(validImageTypes, file["type"]) != -1);
    }
}
