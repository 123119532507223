import { Component, Input, OnInit } from '@angular/core';
import { DocumentService } from '../../../service/document.service';
import { ActivatedRoute } from '@angular/router';
import { environment } from '../../../../environments/environment';

@Component({
    selector: 'app-user-document-list',
    templateUrl: './user-document-list.component.html',
    styleUrls: ['./user-document-list.component.scss']
})
export class UserDocumentListComponent implements OnInit {

    @Input()
    title;
    private userId;
    pageable;
    page: number = 0;
    public loading = false;

    constructor(private documentService: DocumentService, private route: ActivatedRoute) {
    }

    ngOnInit() {
        this.route.params.subscribe(p => {
            if('id' in p){
                this.userId = p["id"];
                this.loadDocuments();
            }
        });
    }

    loadDocuments() {
        this.page++;
        this.documentService.loadDocumentsForUser(this.userId, this.page).subscribe((d: any) => {
            if(!this.pageable){
                this.pageable = d;
            }else{
                this.pageable.entities = this.pageable.entities.concat(d.entities);
            }
        });
    }

    get envApi() {
        return environment.api;
    }
}
