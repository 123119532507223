import { Component, OnInit, ChangeDetectionStrategy, Input, Output, EventEmitter, HostBinding } from '@angular/core';

@Component({
  selector: 'app-delete-button',
  templateUrl: './delete-button.component.html',
  styleUrls: ['./delete-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DeleteButtonComponent implements OnInit {

  @Input()
  height;

  @Input()
  width;

  @Input()
  colorClass: string;

  @Input()
  text: string = "";

  @Output()
  delete = new EventEmitter<void>();

  @HostBinding('class.text-button')
  isTextButton: boolean = false;

  constructor() { }

  ngOnInit() {
    this.isTextButton = (this.text !== "");
  }
  
  buildStyles() {
    return {
      'height.px': this.height,
      'width.px': this.width
    };
  }

  onDelete() {
    this.delete.emit();
  }
}
