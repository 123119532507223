import { Injectable } from '@angular/core';
import { MapsAPILoader } from '@agm/core';
import { Observable, of, Observer, from } from 'rxjs';
import { tap, switchMap, map } from 'rxjs/operators';
import { Location } from '../domain/Location';

declare var google: any;


@Injectable({
  providedIn: 'root'
})
export class GeocodeService {
  private geocoder: any;
  private mapsApiLoader: any;

  constructor(private _mapsApiLoader: MapsAPILoader) {
    this.mapsApiLoader = _mapsApiLoader;
  }

  private initGeocoder() {
    this.geocoder = new google.maps.Geocoder();
  }

  private waitForMapsToLoad(): Observable<boolean> {
    if (!this.geocoder) {
      return from(this.mapsApiLoader.load())
        .pipe(
          tap(() => this.initGeocoder()),
          map(() => true)
        )
    }
    return of(true);
  }


  codeAddress(address: string): Observable<Location> {

    let geocode$: Observable<Location> = Observable.create((observer: Observer<Location>) => {
      // Invokes geocode method of Google Maps API geocoding.
      this.geocoder.geocode({ address: address }, (
        (results, status) => {
          if (status === google.maps.GeocoderStatus.OK) {
            let loc = new Location();
            loc.lat = results[0].geometry.location.lat();
            loc.lng = results[0].geometry.location.lng();
            observer.next(loc);
            observer.complete();
          } else {
            console.log(
              'Geocoding service: geocode was not successful for the following reason: '
              + status
            );
            observer.error(status);
          }
        })
      );
    });

    return this.waitForMapsToLoad()
      .pipe(
        switchMap(
          () => {
            return geocode$
          })
      );
  }
}