import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { SearchService } from '../../service/search.service';
import * as _ from 'lodash';
import { debounceTime } from 'rxjs/operators';
import { environment } from '../../../environments/environment';

@Component({
    selector: 'app-search-bar',
    templateUrl: './search-bar.component.html',
    styleUrls: ['./search-bar.component.scss']
})
export class SearchBarComponent implements OnInit {

    @Input('placeholder')
    placeholder;

    @Output('onSearch')
    onSearch:EventEmitter<any> = new EventEmitter();
    
    loading: boolean = false;

    form:FormGroup = new FormGroup({
        q: new FormControl('', Validators.required)
    });

    searchResult;
    focus: boolean;
    forceOpen: Boolean = false;
    
    @ViewChild('searchResults', {static: true})
    public searchResults: ElementRef<HTMLElement>;

    constructor(private searchService: SearchService) {
    }

    ngOnInit() {
        this.setSearchResultsMaxHeight();
        window.addEventListener('resize', () => this.setSearchResultsMaxHeight());
        this.form.valueChanges.pipe(
            debounceTime(200)
        ).subscribe(v => {
            if(v.q && v.q.length > 1 && !this.loading){
                this.loading = true;
                this.searchService.search(v).subscribe(res => {
                    this.searchResult = res;
                    this.loading = false;
                }, err => {
                    this.loading = false;
                });
            }else{
                this.searchResult = undefined;
            }
        });
    }

    search() {
        this.onSearch.emit(this.form.value);
    }

    onFocus(){
        this.focus = true;
    }

    onBlur(){
        setTimeout(() => {
            this.focus = false;
            this.forceOpen = false;
        }, 400);
    }

    hasResults(){
        return !_.isUndefined(this.searchResult);
    }

    hasSearchResults(){
        return _.find(this.searchResult, r => (<any>r).count > 0);
    }

    doForceOpen() {
        this.forceOpen = true;
        setTimeout(() => {
            if(!this.focus) {
                this.forceOpen = false
            }
        }, 2000);
    }

    get envApi() {
        return environment.api;
    }
    
    private setSearchResultsMaxHeight() {
        this.searchResults.nativeElement.style.maxHeight = window.innerHeight - 80 + 'px';
    }
}
