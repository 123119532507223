import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, HostBinding, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { ChatReducer, ChatStoreModel, getChatReducer } from '../../store/reducers';
import { closeChatBar, closeFormPanel, openChatBar, openFormPanel, toggleChatBar } from '../../store/actions';
import { ChatService } from '../../services/chat.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { Conversation } from '../../model/conversation';

@Component({
    selector: 'app-chat-bar',
    templateUrl: './chat-bar.component.html',
    styleUrls: ['./chat-bar.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChatBarComponent implements OnInit {
    
    @HostBinding('class.open')
    public open: boolean;
    public baseTitle = 'chat.my.discussions';
    public currentTitle = new BehaviorSubject('chat.my.discussions');
    public formPanelOpen: Observable<boolean>;
    public conversation: Conversation;
    public isConvOpen: boolean = false;
    
    constructor(
        private readonly elementRef: ElementRef,
        private readonly cdr: ChangeDetectorRef,
        private readonly store: Store<ChatReducer>,
        private readonly chatService: ChatService
    ) {
        store.select('chat', 'chatBarOpen')
            .subscribe(open => this.open = open);
    
        this.formPanelOpen = this.store.select(c => c.chat.formPanelOpen);
        
        store.select('chat')
            .subscribe(model => this.handleTitleState(model));
    }
    
    public toggleChatBar() {
        this.store.dispatch(toggleChatBar());
    }
    
    public closeChatBar() {
        this.store.dispatch(closeChatBar());
    }
    
    public openChatBar() {
        this.store.dispatch(openChatBar());
    }
    
    public createDiscussion() {
        this.store.dispatch(openChatBar());
        this.store.dispatch(openFormPanel());
    }
    
    public ngOnInit(): void {
        this.chatService.bootstrap();
    }
    
    private handleTitleState(model: ChatStoreModel) {
        this.conversation = model.currentConversation;
        this.isConvOpen = false;
        if (model.formPanelOpen) {
            this.currentTitle.next('chat.discussion.create');
            return;
        }
        if (model.currentConversation) {
            this.currentTitle.next(model.currentConversation.label);
            this.isConvOpen = true;
            return;
        }
        this.currentTitle.next(this.baseTitle);
    }

    public manageBackLink() {
        if (getChatReducer(this.store).chat.formPanelOpen) {
            this.store.dispatch(closeFormPanel());
        }
        else if (getChatReducer(this.store).chat.currentConversation) {
            this.store.dispatch(openChatBar());
        }
        else {
            this.toggleChatBar();
        }
    }
}
