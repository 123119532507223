import { Component, Input, OnInit } from '@angular/core';
import { UserService } from '../../service/user.service';
import { Router } from '@angular/router';
import { FormArray, FormGroup } from '@angular/forms';

@Component({
    selector: 'app-step-four',
    templateUrl: './step-four.component.html',
    styleUrls: ['./step-four.component.scss']
})
export class StepFourComponent implements OnInit {

    user;
    @Input('form')
    form: FormGroup;
    @Input('loading')
    loading;
    private step = 4;

    constructor(private userService: UserService, private router: Router) {
    }

    ngOnInit() {
        this.user = this.userService.u;
        this.patchUser();
        this.userService.userObservable.subscribe(u => {
            this.user = u;
            this.patchUser();
        });
    }

    patchUser(){
        var group = (<FormArray>this.form.get('functionInOffices')).controls[0];
        group.patchValue(this.user.functionInOffices[0]);
        this.form.patchValue(this.user);
    }
    
    public get functionInOffices() {
        return this.form.get('functionInOffices') as FormArray;
    }

}
