import { Component, Input, OnInit } from '@angular/core';
import { UserService } from '../../../service/user.service';

@Component({
    selector: 'user-list',
    templateUrl: './user-list.component.html',
    styleUrls: ['./user-list.component.scss']
})
export class UserListComponent implements OnInit {


    @Input('pageable')
    pageable;
    @Input('view')
    view;

    constructor(private userService: UserService) {
    }

    ngOnInit() {}

    delete(user, index){
        this.userService.deleteUser(user.id).subscribe(res => {
            this.pageable.entities.splice(index, 1);
        });
    }

}
