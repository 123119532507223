import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from '../../../service/user.service';
import { FormGroup } from '@angular/forms';
import { User } from '../../../domain/user';

@Component({
    selector: 'app-user-form',
    templateUrl: './user-form.component.html',
    styleUrls: ['./user-form.component.css']
})
export class UserFormComponent implements OnInit {


    form: FormGroup = User.form();

    constructor(private route:ActivatedRoute, private userService:UserService, private router: Router) {
    }

    ngOnInit() {
        this.route.params.subscribe(p => {
            if ('id' in p) {
                this.userService.loadUser(p['id']).subscribe(u => this.form.patchValue(u));
            }
        });
    }

    save() {
        this.userService.save(this.form.value).subscribe(u => {
            //this.router.navigate(['/osadmin/secure/user']);
        });
    }

}
