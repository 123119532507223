import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DocumentService } from '../../../service/document.service';

@Component({
    selector: 'app-document-list',
    templateUrl: './document-list.component.html',
    styleUrls: ['./document-list.component.scss']
})
export class DocumentListComponent implements OnInit {
    document;
    
    @Input('documents')
    documents;

    @Input('documentType')
    documentType;

    @Input('parentId')
    parentId;

    @Output()
    onEdit: EventEmitter<any> = new EventEmitter<any>();

    @Output()
    onSkillSelected: EventEmitter<any> = new EventEmitter();

    constructor(
        private documentService: DocumentService
    ) {
    }

    ngOnInit() {
        if (this.parentId) {
            this.documentService.loadDocument(this.documentType, this.parentId)
                .subscribe(d => {
                    this.document = d;
                });
        }
    }

    edit(document) {
        this.onEdit.emit(document);
    }
}
