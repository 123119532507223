import { Component, OnInit } from '@angular/core';
import * as _ from 'lodash';

@Component({
    selector: 'app-invalid-domain',
    templateUrl: './invalid-domain.component.html',
    styleUrls: ['./invalid-domain.component.scss']
})
export class InvalidDomainComponent implements OnInit {

    totalSteps = 5;
    step = 2;

    constructor() {
    }

    ngOnInit() {
    }

    beautified(disclaimer){
        if(disclaimer){
            return disclaimer.replace(new RegExp("\n", 'ig'), '<br/>');
        }
    }

    range(start, end){
        return _.range(start, end);
    }
}
