import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UserService } from '../../../service/user.service';
import * as _ from 'lodash';
import { EventService } from '../../../service/event.service';
import { ModalService } from '../../../service/modal.service';

@Component({
    selector: 'app-calendar-item',
    templateUrl: './calendar-item.component.html',
    styleUrls: ['./calendar-item.component.scss']
})
export class CalendarItemComponent implements OnInit {

    @Input('event')
    event;
    @Input('events')
    events;
    me;
    @Output()
    onEdit: EventEmitter<any> = new EventEmitter<any>();

    constructor(
        private userService: UserService,
        private eventService: EventService,
        private modalService: ModalService) {
    }

    ngOnInit() {
        this.me = this.userService.u;
        this.userService.userObservable.subscribe(u => this.me = u);
    }

    isAdmin() {
        return _.find(<Array<any>>_.get(this, 'me.roles'), r => (r == 'ADMIN' || r == 'SUB_ADMIN'));
    }

    edit() {
        this.onEdit.emit(this.event);
    }

    delete() {
        this.modalService.confirm('event.deletion.title', 'event.deletion.message')
            .subscribe(() => {
                this.eventService.deleteEvent(this.event.id).subscribe(d => {
                    _.remove(this.events, e => (<any>e).id == this.event.id);
                });
            });
    }

    participate() {
        if (this.isParticipating()) {
            this.eventService.unSubscribe(this.event.id).subscribe(p => {
                _.remove(this.event.participations, (p: any) => p.userId = this.me.id);
            });
        } else {
            this.eventService.subscribe(this.event.id).subscribe(p => {
                this.event.participations.push({ userId: this.me.id });
            });
        }
    }

    isParticipating() {
        return this.me && _.find(this.event.participations, (p: any) => {
            return p.userId === this.me.id;
        });
    }

}
