import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AbstractModal } from '../../../shared/abstract-modal';
import { MoreValidators } from '../../../validation/more-validators';
import { UserService } from '../../../service/user.service';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-email-change-modal',
    templateUrl: './email-change-modal.component.html',
    styleUrls: ['./email-change-modal.component.scss']
})
export class EmailChangeModalComponent extends AbstractModal implements OnInit {

    form: FormGroup = new FormGroup({
        email: new FormControl(null, [Validators.required])
    });
    user;

    constructor(moreValidators: MoreValidators,
                private userService: UserService,
                private ts: TranslateService,
                private toaster: ToastrService) {
        super();
        this.form.get('email').asyncValidator = moreValidators.isEmailUsed();
    }

    ngOnInit() {}

    changeEmail() {
        this.userService.changeEmail(this.user.id, this.form.value).subscribe((email: any) => {
            var key = email.active ? 'emailSuccessfullyChanged' : 'emailChangeAcknowledged';
            this.ts.get('toasts.'+key).subscribe(ts => {
                this.toaster.success(ts);
                this.close(email);
            });
        });
    }

}
