import { Inject, Injectable } from '@angular/core';
import { ChatRepository } from '../repositories/chat.repository';
import { I_TOKEN_PROVIDER, ITokenProvider } from '../interfaces/token-provider.interface';
import { SERVICE_URL } from '../interfaces/chat-service-configuration.interface';
import { first, map, switchMap, tap } from 'rxjs/operators';
import { ChatReducer } from '../store/reducers';
import { Store } from '@ngrx/store';
import {
    chatHistoryFetched, cleanUp,
    conversationCreated,
    conversationLoaded,
    conversationQuitted,
    messagesReceived,
    updateReadStatus
} from '../store/actions';
import { HttpResponse } from '@angular/common/http';
import { Message, MessageType } from '../model/message';
import { Conversation } from '../model/conversation';
import { IUserLocator, I_USER_LOCATOR } from '../interfaces/user-locator.interface';

@Injectable()
export class ChatService {

    private webSocket: WebSocket;
    private conversations: Conversation[];

    constructor(
        private readonly chatRepository: ChatRepository,
        @Inject(I_TOKEN_PROVIDER) private readonly tokenProvider: ITokenProvider,
        @Inject(SERVICE_URL) private readonly serviceURL: string,
        @Inject(I_USER_LOCATOR) private readonly userLocator: IUserLocator,
        private readonly store: Store<ChatReducer>
    ) {
        this.store.select('chat', 'conversations', 'entities').subscribe(c => this.conversations = c);
    }

    public bootstrap() {
        this.webSocket = new WebSocket(this.serviceURL, this.tokenProvider.getTokenString());
        this.webSocket.addEventListener('message', (message) => this.onMessage(message));

        this.loadConversations();
    }

    public saveGroup(form: any) {
        if (form.id) {
            return this.chatRepository.update(form)
                .pipe(tap(c => this.store.dispatch(conversationCreated(c))));
        }
        return this.chatRepository.create(form)
            .pipe(tap(c => this.store.dispatch(conversationCreated(c))));
    }

    private onMessage(event: MessageEvent) {
        try {
            const message = JSON.parse(event.data) as Message;
            switch (message.messageType) {
                case MessageType.message:
                case MessageType.chatLeft:
                case MessageType.chatEntered:
                    this.onMessageReceived(message);
                    break;
                default:
                    console.log(message.messageType);
                    break;
            }
        } catch (e) {
            console.error(e);
        }
    }

    public sendMessage(conversationId: string, message: any) {
        return this.chatRepository.sendMessage(conversationId, message)
            .pipe(
                tap(event => {
                    if (event instanceof HttpResponse) {
                        this.store.dispatch(messagesReceived({
                            conversationId,
                            messages: [event.body],
                            addUnread: false
                        }))
                    }
                })
            );
    }

    public updateReadStatus(conversationId: string, userId: string) {
        this.chatRepository.updateReadStatus(conversationId, new Date().getTime().toString()).subscribe(
            () => {
                this.store.dispatch(updateReadStatus({
                    conversationId: conversationId,
                    userId: userId
                }));
            }
        )
    }

    public loadMessages(conversationId: string, before = new Date().getTime()) {
        return this.chatRepository.loadMessages(conversationId, before).pipe(
            tap(messages => {
                this.store.dispatch(messagesReceived({
                    conversationId,
                    messages,
                    addUnread: false
                }));
            })
        );
    }

    public loadConversations() {
        this.store.select('chat', 'currentPage')
            .pipe(
                first(),
                switchMap(page => this.chatRepository.loadConversations(page))
            )
            .subscribe(data => this.store.dispatch(chatHistoryFetched(data)));
    }

    private onMessageReceived(message: Message) {
        if (this.conversations.some(c => c.id === message.conversationId)) {
            if (message.creator === this.userLocator.currentUser().id)
                message.read = true;

            this.store.dispatch(messagesReceived({
                conversationId: message.conversationId,
                messages: [message],
                addUnread: true
            }));
        } else {
            this.chatRepository.loadConversation(message.conversationId)
                .pipe(
                    map(c => ({
                        ...c,
                        messages: [message]
                    }))
                )
                .subscribe(c => this.store.dispatch(conversationLoaded(c)));
        }
    }

    public quit(conversationId: string) {
        return this.chatRepository.quitConversation(conversationId)
            .pipe(
                tap(() => this.store.dispatch(conversationQuitted({ conversationId })))
            );
    }
    
    public cleanUp() {
        this.store.dispatch(cleanUp())
    }
}
