import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-function-in-office',
    templateUrl: './function-in-office.component.html',
    styleUrls: ['./function-in-office.component.scss']
})
export class FunctionInOfficeComponent implements OnInit {

    @Input()
    item;

    constructor() {
    }

    ngOnInit() {
    }

}
