import { ActionReducerMap, createReducer, on, Store } from '@ngrx/store';
import { Pageable } from '../../../shared/model/pageable';
import { Conversation } from '../model/conversation';
import {
    chatHistoryFetched, cleanUp,
    closeChatBar,
    closeFormPanel,
    conversationCreated,
    conversationLoaded,
    conversationQuitted,
    messagesReceived,
    MessagesReceivedAction,
    openChatBar,
    openConversation,
    openFormPanel,
    toggleChatBar,
    updateReadStatus,
    UpdateReadStatusAction
} from './actions';
import { byModifiedDesc, orderedAndMapped } from '../utils/chat-utils';
import first from 'lodash-es/first';
import last from 'lodash-es/last';
import { get } from 'lodash-es';
import { take } from 'rxjs/operators';
import { ReadStatus } from '../model/read-status';

export const initialState: ChatStoreModel = {
    chatBarOpen: false,
    formPanelOpen: false,
    conversations: {
        total: 0,
        entities: []
    },
    currentPage: 1,
};

export const chat = createReducer(
    initialState,
    on(toggleChatBar, state => ({
        ...state,
        chatBarOpen: !state.chatBarOpen,
        currentConversation: state.chatBarOpen ? undefined : state.currentConversation
    })),
    on(openChatBar, state => ({
        ...state,
        chatBarOpen: true,
        formPanelOpen: false,
        currentConversation: undefined
    })),
    on(closeChatBar, state => ({
        ...state,
        chatBarOpen: false,
        formPanelOpen: false,
        currentConversation: undefined
    })),
    on(chatHistoryFetched, (state: ChatStoreModel, action: Pageable<Conversation>) => ({
        ...state,
        currentPage: ++state.currentPage,
        conversations: {
            total: action.total,
            entities: state.conversations.entities.concat(action.entities)
        }
    })),
    on(messagesReceived, onMessagesReceived),
    on(openConversation, (state: ChatStoreModel, action: Conversation) => ({
        ...state,
        chatBarOpen: true,
        currentConversation: action,
        formPanelOpen: false
    })),
    on(openFormPanel, state => ({
        ...state,
        formPanelOpen: true,
        chatBarOpen: true
    })),
    on(closeFormPanel, state => ({
        ...state,
        formPanelOpen: false
    })),
    on(conversationCreated, onConversationCreated),
    on(conversationLoaded, onConversationLoaded),
    on(updateReadStatus, onUpdateReadStatus),
    on(conversationQuitted, onConversationQuitted),
    on(cleanUp, () => ({
        chatBarOpen: false,
        formPanelOpen: false,
        conversations: {
            total: 0,
            entities: []
        },
        currentPage: 1,
    }))
);

function onConversationQuitted(state: ChatStoreModel, action) {
    const {conversationId} = action;
    if (!conversationId) {
        return {
            ...state
        }
    }
    return {
        ...state,
        conversations: {
            total: state.conversations.total - 1,
            entities: state.conversations.entities.filter(c => c.id !== conversationId)
        },
        currentConversation: undefined
    }
}

function onMessagesReceived(state: ChatStoreModel, action: MessagesReceivedAction) {
    const entities = state.conversations.entities.map(conv => {
        if (conv.id === action.conversationId) {
            conv.messages = orderedAndMapped(conv.occupantLog, (conv.messages || []).concat(action.messages));
            conv.latestMessage = last(conv.messages.filter(m => m.messageType === 'message'));
            conv.updated = get(conv.latestMessage, 'updated');
            
            if (action.addUnread)  {
                action.messages.forEach(m => {
                    if (!m.read) {
                        conv.unreadMessageCount += 1;
                    }
                });
            }
        }
        return conv;
    });
    
    return {
        ...state,
        conversations: {
            total: state.conversations.total,
            entities: byModifiedDesc(entities)
        },
        currentConversation: state.currentConversation ? first(entities.filter(e => e.id === state.currentConversation.id)) : undefined
    };
}

function onConversationCreated(state: ChatStoreModel, conversation: Conversation) {
    let { entities, total } = state.conversations;
    
    if (entities.find(c => c.id === conversation.id)) {
        entities = entities.map(c => c.id === conversation.id ?
            Object.assign(c, {
                modified: conversation.updated,
                label: conversation.label
            })
            : c
        );
    } else {
        entities = [conversation].concat(entities);
        total += 1;
    }
    
    return {
        ...state,
        conversations: {
            total,
            entities: byModifiedDesc(entities)
        },
        chatBarOpen: true,
        currentConversation: first(entities.filter(e => e.id === conversation.id)),
        formPanelOpen: false
    }
}

function onConversationLoaded(state: ChatStoreModel, conversation: Conversation) {
    let { entities, total } = state.conversations;

    if (entities.find(c => c.id === conversation.id)) {
        entities = entities.map(c => c.id === conversation.id ?
            Object.assign(c, {
                modified: conversation.updated,
                label: conversation.label
            })
            : c
        );
    } else {
        entities = [conversation].concat(entities);
        total += 1;
    }

    return {
        ...state,
        conversations: {
            total,
            entities: byModifiedDesc(entities)
        }
    }
}

export function getChatReducer(store: Store<ChatReducer>): ChatReducer {
    let state: ChatReducer;

    store.pipe(take(1)).subscribe(s => state = s);

    return state;
}

function onUpdateReadStatus(state: ChatStoreModel, action: UpdateReadStatusAction) {
    const conversation = state.conversations.entities.find(c => c.id === action.conversationId);
    let readStatus = conversation.readStatus.find(r => r.userId === action.userId);
    if (!readStatus) {
        readStatus = {
            userId: action.userId,
            conversationId: action.conversationId,
            lastRead: conversation.created
        } as ReadStatus;
        conversation.readStatus.push(readStatus);
    }

    conversation.messages.forEach(message => {
        if (!message.read) {
            message.read = true;
            if (conversation.unreadMessageCount > 0)
                conversation.unreadMessageCount -= 1;
        }
    });

    return {
        ...state,
        conversations: {
            total: state.conversations.total,
            entities: state.conversations.entities.map(c => c.id === action.conversationId ? conversation : c)
        }
    };
}

export interface ChatReducer {
    chat: ChatStoreModel
}

export interface ChatStoreModel {
    chatBarOpen: boolean;
    formPanelOpen: boolean;
    conversations: Pageable<Conversation>;
    currentPage: number,
    currentConversation?: Conversation
}

export const reducers: ActionReducerMap<{chat: ChatStoreModel}> = {
    chat
};
