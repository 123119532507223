import { Component, OnInit, ChangeDetectionStrategy, Input, EventEmitter, HostBinding, Output } from '@angular/core';

@Component({
  selector: 'app-view-button',
  templateUrl: './view-button.component.html',
  styleUrls: ['./view-button.component.scss']
})
export class ViewButtonComponent implements OnInit {

  @Input()
  height;

  @Input()
  width;

  @Input()
  colorClass: string;

  @Input()
  fontSize: number;

  @Input()
  text: string = "";

  @Output()
  view = new EventEmitter<void>();

  @HostBinding('class.text-button')
  isTextButton: boolean = false;

  constructor() { }

  ngOnInit() {
    this.isTextButton = (this.text !== "");
  }

  buildStyles() {
    return {
      'height.px': this.height,
      'width.px': this.width,
      'font-size.px' : this.fontSize,
      'line-height.px' : this.height
    };
  }

  onView() {
    this.view.emit();
  }

}
