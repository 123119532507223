import { FormControl, FormGroup, Validators } from '@angular/forms';

export class Language {
    static form(){
        return new FormGroup({
            id: new FormControl(),
            code: new FormControl('', Validators.required),
            flag: new FormControl(),
            primary: new FormControl(),
            active: new FormControl()
        });
    }
}
