import { Component, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { FormControl, FormGroup } from '@angular/forms';
import * as _ from 'lodash';
import { ModalService } from '../../../service/modal.service';
import { UserService } from '../../../service/user.service';
import { DocumentService } from '../../../service/document.service';
import { DocumentFormComponent } from '../document-form/document-form.component';
import { FolderCreateOrEditModalComponent } from '../folder-create-or-edit-modal/folder-create-or-edit-modal.component';

@Component({
    selector: 'app-document-full',
    templateUrl: './document-full.component.html',
    styleUrls: ['./document-full.component.scss']
})
export class DocumentFullComponent implements OnInit {
    documentType;

    parentId;

    documents;

    document;

    form: FormGroup = new FormGroup({
        page: new FormControl(1),
        q: new FormControl(),
        property: new FormControl('created'),
        order: new FormControl('-1'),
        creator: new FormControl('')
    });

    public contributors: any[] = [];

    me;

    refresh: any;

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private userService: UserService,
        private documentService: DocumentService,
        private modalService: ModalService,
        private vcr: ViewContainerRef
    ) {
        this.router.routeReuseStrategy.shouldReuseRoute = function () {
            return false;
        };
          
        this.refresh = this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                this.router.navigated = false;
            }
        });
    }

    ngOnInit() {
        this.me = this.userService.u;
        this.userService.userObservable.subscribe(u => this.me = u);

        this.documentService.loadContributors().subscribe((contributors: any[]) => this.contributors = contributors);
        this.route.params.subscribe(p => {
            if ('documentType' in p && 'id' in p) {
                this.documentType = p['documentType'];
                this.parentId = p['id'];

                this.loadDocuments();

                this.form.get('q').valueChanges.subscribe((v:any) => {
                    this.documents = undefined;
                    this.form.get('page').setValue(1, { emitEvent: false });
                    this.loadDocuments();
                });

                this.form.get('page').valueChanges.subscribe(v => {
                    this.loadDocuments();
                });

                this.form.get('creator').valueChanges.subscribe(v => {
                    this.documents = undefined;
                    this.form.get('page').setValue(1, { emitEvent: false });
                    this.loadDocuments();
                });

                this.form.get('property').valueChanges.subscribe(v => {
                    this.documents = undefined;
                    this.form.get('page').setValue(1, { emitEvent: false });
                    this.loadDocuments();
                });

                this.documentService.loadDocument(this.documentType, this.parentId)
                        .subscribe(d => {
                            this.document = d;
                        });
            }
        });
    }

    ngOnDestroy() {
        if (this.refresh) {
            this.refresh.unsubscribe();
        }
    }

    onMedia(media) {
        if (_.find(this.documents.entities, e => (<any>e).id == media.id)) {
            _.forEach(this.documents.entities, e => {
                if(e.id == media.id){
                    e = _.extend(e, media);
                }
            });
        } else {
            this.documents.entities.unshift(media);
        }
    }

    createOrEdit(document) {
        const modal = this.modalService.openModal(DocumentFormComponent, this.vcr);

        if (document) {
            modal.instance.form.patchValue(document);
        }

        modal.instance.onClose.subscribe((result) => {
            if (document) {
                this.documents.entities
                    .filter(item => item.id == result.id)
                    .map(item => _.extend(item, result));
            } else {
                this.documents.entities.unshift(result);
                ++this.documents.count;
            }

            modal.destroy();
        });

        modal.instance.onDiscard.subscribe(() => modal.destroy());
    }

    createOrEditFolder(folder) {
        const modal = this.modalService.openModal(FolderCreateOrEditModalComponent, this.vcr);

        if (folder) {
            modal.instance.form.patchValue(folder);
        }

        modal.instance.onClose.subscribe((result) => {
            if (folder) {
                this.documents.entities
                    .filter(item => item.id == result.id)
                    .map(item => _.extend(item, result));
            } else {
                this.documents.entities.unshift(result);
                ++this.documents.count;
            }

            modal.destroy();
        });

        modal.instance.onDiscard.subscribe(() => modal.destroy());
    }

    onScroll() {
        this.form.patchValue({ page: ++this.form.value.page });
    }

    private loadDocuments() {
        this.documentService.loadDocumentsForTypeAndParentId(this.documentType, this.parentId, this.form.value)
            .subscribe(d => {
                if (! this.documents) {
                    this.documents = d;
                } else {
                    this.documents.entities = this.documents.entities.concat((<any>d).entities);
                }
            });
    }

    onSort(props) { 
        _.extend(props, {page: 1});
        
        this.form.patchValue(props, {emitEvent: false});
        this.documents = undefined;

        this.loadDocuments();
    }

    onAdd() {
        this.documents = undefined;
        this.loadDocuments();
    }

    isAdmin() {
        return this.me && _.find(this.me.roles, r => r == 'ADMIN');
    }

    canCreateFolder() {
        return this.me && _.find(this.me.roles, r => (r == 'ADMIN' || r == 'SUB_ADMIN'));
    }

    canEdit() {
        return (this.me && _.find(this.me.roles, r => (r == 'ADMIN' || r == 'SUB_ADMIN')) || this.isSelf());
    }

    isSelf() {
        return _.get(this, 'me.id') == _.get(this, 'document.creator');
    }
}
