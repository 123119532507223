import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { LoginForm } from './login.form';
import { Router } from '@angular/router';
import { UserService } from './service/user.service';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { ChatService } from './modules/chat/services/chat.service';

@Component({
    selector: "login",
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

    public loginForm: FormGroup = LoginForm.form();
    passwordType = "password";

    constructor(private userService: UserService,
                private router: Router,
                private toaster: ToastrService,
                private ts: TranslateService,
                private readonly chatService: ChatService
    ){}

    login($event){
        let data = this.loginForm.value;
        this.userService.login(data).subscribe(res => {
            this.goToHome();
        }, err => {
            if (err.status === 404) {
                this.ts.get('toasts.userNotFound').subscribe(ts => {
                    this.toaster.warning(ts);
                });
            } else {
                this.ts.get('toasts.loginError').subscribe(ts => {
                    this.toaster.error(ts);
                });
            }
        });
    }

    goToHome() {
        this.router.navigate(['']);
    }

    togglePasswordType() {
        this.passwordType = (this.passwordType === "password") ? "text" : "password";
    }
    
    ngOnInit(): void {
        this.chatService.cleanUp();
    }
}
