import { Injectable } from '@angular/core';
import { Settings } from '../app.settings';
import * as _ from 'lodash';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class NewsService {

    constructor(private http: HttpClient) {}

    getLatestNews() {
        return this.http.get(Settings.NEWS);
    }

    getAllNews() {
        return this.http.get(Settings.NEWS + "/all");
    }

    saveNews(news) {
        news = _.omitBy(news, (item) => _.isNull(item) || _.isUndefined(item));
        const data = new FormData();
        Object.keys(news).forEach(key => {
           data.append(key, news[key]);
        });
        return this.http.post(Settings.NEWS, data);
    }

    deleteNews(newsId: any) {
        return this.http.delete(Settings.NEWS + "/" + newsId);
    }
}
