import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  selector: 'app-comment-event-feed-item',
  templateUrl: './comment-event-feed-item.component.html',
  styleUrls: ['./comment-event-feed-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CommentEventFeedItemComponent implements OnInit {

  @Input()
  item;
  
  constructor() { 
  }

  ngOnInit() {
  }

}
