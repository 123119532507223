import { Component, OnInit } from '@angular/core';
import { AbstractModal } from '../../shared/abstract-modal';
import { FormGroup } from '@angular/forms';
import { LinkGroup } from '../../domain/link-group';
import { LinkGroupService } from '../../service/link-group.service';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';

@Component({
    selector: 'app-link-group-form',
    templateUrl: './link-group-form.component.html',
    styleUrls: ['./link-group-form.component.scss']
})
export class LinkGroupFormComponent extends AbstractModal implements OnInit {

    form: FormGroup = LinkGroup.form();

    constructor(private linkGroupService: LinkGroupService, private ts: TranslateService, private toaster: ToastrService) {
        super();
    }

    ngOnInit() {
    }

    save() {
        this.linkGroupService.saveGroup(this.form.value).subscribe(k => {
            this.onClose.emit(k);
            this.ts.get('toasts.successfullLinkGroupEdition').subscribe(ts => {
                this.toaster.success(ts);
                this.modal.hide();
            });
        });
    }

}
