import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { SelectComponent } from 'ng2-select/index';
import { FormGroup } from '@angular/forms';
import { PublicDataService } from '../../service/public-data.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-gender-selector',
    templateUrl: './gender-selector.component.html',
    styleUrls: ['./gender-selector.component.scss']
})
export class GenderSelectorComponent implements OnInit {

    @Input('form')
    form: FormGroup;

    items = [];

    constructor(
        private publicDataService: PublicDataService, 
        private ts: TranslateService) {
    }

    ngOnInit() {
        this.publicDataService.getGenders().subscribe(g => {
            let newItems = [];
            (<any>g).forEach(gender => {
                var item = {
                    id: gender,
                    text: gender
                };
                this.loadTranslation(item);
                newItems.push(item);
            });
            this.items = newItems;
        });
    }

    onItemSelected(value){
        this.form.patchValue({ gender: value.id });
    }

    private loadTranslation(item) {
        this.ts.get(item.id).subscribe(ts => item.text = ts);
    }
}
