import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import { ToastrService } from 'ngx-toastr';
import { Registration } from '../../../domain/registration';
import { UserService } from '../../../service/user.service';
import { AbstractModal } from '../../../shared/abstract-modal';

@Component({
    selector: 'app-user-creation-modal',
    templateUrl: './user-creation-modal.component.html',
    styleUrls: ['./user-creation-modal.component.scss']
})
export class UserCreationModalComponent extends AbstractModal implements OnInit {

    form: FormGroup = <FormGroup> Registration.form().get('userCreation');
    me;

    constructor(
        private userService:UserService,
        private ts: TranslateService,
        private toaster: ToastrService) {
        super();
    }

    ngOnInit() {}

    createUser(){
        this.userService.createUser(this.form.value)
            .subscribe((user) => {
                this.ts.get('toasts.successfullUserCreation').subscribe((ts) => {
                    this.toaster.success(ts);
                    this.onClose.emit(user);
                    this.modal.hide();
                });
            }, (err) => {
                _.forEach(_.get(err, 'errors'), (e: any) => {
                    if(e.code == "Unique"){
                        this.ts.get('toasts.emailInUse').subscribe((ts) => {
                            this.toaster.error(ts);
                        });
                    }
                });
            });
    }

    isModerator(): boolean {
        return (_.find(this.userService.u.roles, r => r == 'MODERATOR') !== undefined);
    }

}
