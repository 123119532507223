import { Component, OnInit, ChangeDetectionStrategy, Input, ViewContainerRef, Output, EventEmitter } from '@angular/core';
import { UserService } from '../../service/user.service';
import { ForumComment } from '../../domain/forum-comment';
import { ForumService } from '../../service/forum.service';
import { ModalService } from '../../service/modal.service';
import { ForumFormComponent } from '../forum-form/forum-form.component';
import * as _ from 'lodash';
import { environment } from '../../../environments/environment';
import { ForumCommentFormComponent } from '../forum-comment-form/forum-comment-form.component';

const IMAGE_CONTENT_TYPES = ['image/jpg', 'image/jpeg', 'image/png', 'image/gif', 'image/webp'];

@Component({
  selector: 'app-forum-item',
  templateUrl: './forum-item.component.html',
  styleUrls: ['./forum-item.component.scss'],
})
export class ForumItemComponent implements OnInit {

  @Input()
  post: any;

  me: any;
  
  documents: any;

  @Input()
  showCommentDefault: boolean = true;

  isShowComments = false;

  form = ForumComment.form();

  @Output()
  delete = new EventEmitter<void>();

  @Output()
  onForumCommentWithFile: EventEmitter<void> = new EventEmitter();

  constructor(
    private userService: UserService,
    private forumService: ForumService,
    private modalService: ModalService,
    private vcr: ViewContainerRef
  ) {
    this.me = this.userService.userObservable;
    this.documents = [];
  }

  onComment(comment) {    
    if (_.filter(this.post.comments, (e:any) => e.id == comment.id).length == 0) {
      this.post.comments.push(comment);

      if (this.hasFiles(comment)) {
        this.onForumCommentWithFile.emit();
      }
    }
  }

  ngOnInit() {
    this.userService.updateReadStatusForum(this.post.id).subscribe(s => {}); 

    this.userService.onSocketEvent.subscribe(data => {
      if (data.messageType == 'forumComment' && data.postId == this.post.id) {
        this.onComment(data);
      }
    });

    this.isShowComments = this.showCommentDefault;

    this.getDocuments();
  }

  onAddComment() {
    this.form.get('postId').setValue(this.post.id);

    this.forumService.saveComment(this.post.id, this.form.value).subscribe(
      (comment) => {
        this.onComment(comment);
        this.form = ForumComment.form();
        this.post.following = true;        
      }
    );
  }

  onEdit() {
    const modal = this.modalService.openModal(ForumFormComponent, this.vcr);

    modal.instance.form.patchValue(this.post);

    modal.instance.onClose.subscribe((result) => {
      this.post = result;
      this.getDocuments();
      modal.destroy();
    });

    modal.instance.onDiscard.subscribe(() => modal.destroy());
  }

  onEditComment(comment) {
    const modal = this.modalService.openModal(ForumCommentFormComponent, this.vcr);
    
    modal.instance.form.patchValue(comment);

    modal.instance.onClose.subscribe((result) => {
      let c = this.post.comments.find(co => co.id === result.id);
      let i = this.post.comments.indexOf(c);
      this.post.comments[i] = result;
      modal.destroy();
    });

    modal.instance.onDiscard.subscribe(() => modal.destroy());
  }

  onToggleFollow() {
    let obs;
    if (this.post.following)
      obs = this.forumService.unfollow(this.post);
    else
      obs = this.forumService.follow(this.post);

    obs.subscribe(
      (post) => {
        this.post.following = post.following;
        this.post.followers = post.followers;
      }
    );
  }

  onDelete() {
    this.modalService
      .confirm('forum.deletion.title', 'forum.deletion.message')
      .subscribe(() => {
        this.delete.emit();
      });
  }

  onDeleteComment(comment) {
    this.modalService
      .confirm('forumcomment.deletion.title', 'forumcomment.deletion.message')
      .subscribe(() => {
        this.forumService.deleteComment(comment.id).subscribe(
          () => _.remove(this.post.comments, (c: any) => c.id === comment.id)
        );
      });
  }

  onFileSelected(e) {
    const files = _.get(e, 'target.files');
    
    if (!_.isUndefined(files) && files.length > 0) {
      this.form.patchValue({ file: files[0] });
    } else {
      this.form.patchValue({ file: undefined });
    }
  }

  hasFiles(comment) {
    return comment.files.length > 0;
  }

  public isImage(attachment) {
    return IMAGE_CONTENT_TYPES.includes(attachment.contentType);
  }

  isAdmin() {
    return _.find(<Array<any>>_.get(this, 'userService.u.roles'), r => (r === 'ADMIN' || r === 'SUB_ADMIN'));
  }

  isCreatorPost() {
    return _.get(this, 'userService.u.id') == _.get(this.post, 'creator');
  }

  isCreatorComment(comment) {
    return _.get(this, 'userService.u.id') == _.get(comment, 'creator');
  }

  removeAttachment() {
    this.form.patchValue({ file: undefined });
  }

  getDocuments() {
    this.forumService.getDocuments(this.post).subscribe(
      (documents) => {
        this.documents = documents;
      }
    );
  }

  get fileLabel() {
    if (this.form.value.file === undefined || this.form.value.file === null) {
      return "";
    } else {
      return this.form.value.file.name;
    }
  }

  get envApi() {
    return environment.api;
  }
}
