import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Resolve, RouterStateSnapshot } from '@angular/router';
import { Settings } from '../app.settings';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { ConfigService } from './config.service';


@Injectable()
export class WebsiteService implements Resolve<any>, CanActivate {

    private website;

    constructor(private http: HttpClient, private configService: ConfigService) {}

    resolve(route:ActivatedRouteSnapshot, state:RouterStateSnapshot):Observable<any>|Promise<any>|any {
        return this.website;
    }

    canActivate(route:ActivatedRouteSnapshot, state:RouterStateSnapshot):Observable<boolean>|Promise<boolean>|boolean {
        return this.getWebsite()
            .pipe(
                tap(w => this.website = w),
                map(_ => true),
                catchError(_ => of(false))
            )
    }

    getWebsite(): Observable<any> {
        return this.http.get(Settings.CURRENT_WEBSITE);
    }
}
