import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, HostBinding, Inject } from '@angular/core';
import { Conversation } from '../../model/conversation';
import { ChatService } from '../../services/chat.service';
import { Store } from '@ngrx/store';
import { ChatReducer } from '../../store/reducers';
import { Observable } from 'rxjs';
import { map, switchMap, take, tap } from 'rxjs/operators';
import first from 'lodash-es/first';
import get from 'lodash-es/get';
import { I_USER_LOCATOR, IUserLocator } from '../../interfaces/user-locator.interface';

@Component({
    selector: 'app-chat-panel',
    templateUrl: './chat-panel.component.html',
    styleUrls: ['./chat-panel.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChatPanelComponent {
    
    public conversation: Observable<Conversation>;
    private _currentConv : Conversation;
    @HostBinding('hidden') public hidden = true;
    
    constructor(
        private readonly chatService: ChatService,
        private readonly store: Store<ChatReducer>,
        private readonly cdr: ChangeDetectorRef,
        private readonly elementRef: ElementRef<HTMLElement>,
        @Inject(I_USER_LOCATOR) private readonly userLocator: IUserLocator
    ) {
        this.conversation = this.store
            .select(state => state.chat)
            .pipe(
                map(c => c.currentConversation),
                tap((c) => {
                    this.hidden = c === undefined;
                    if (! this.hidden && (!this._currentConv || this._currentConv.id != c.id))
                        this.chatService.updateReadStatus(c.id, this.userLocator.currentUser().id);
                    this._currentConv = c;
                    // this.cdr.markForCheck();
                })
            );
    }
    
    public loadHistory() {
        let latestMessage: HTMLElement;
        this.conversation
            .pipe(
                take(1),
                tap(c => latestMessage = this.elementRef.nativeElement.querySelector('#message_' + first(c.messages).id)),
                switchMap(c => this.chatService.loadMessages(c.id, get(first(c.messages), 'created')))
            )
            .subscribe(messages => {
                setTimeout(() => {
                    this.elementRef.nativeElement.querySelector('app-chat-message-list').scrollTop = latestMessage.offsetTop;
                }, 100);
            });
    }
}
