import { FormControl, FormGroup, Validators } from '@angular/forms';

export class Variable {
    static form(){
        return new FormGroup({
            id: new FormControl(),
            name: new FormControl(null, [Validators.required]),
            value: new FormControl(null, [Validators.required]),
            importStatement: new FormControl(null, []),
            position: new FormControl(null, []),
            description: new FormControl(null, [])
        });
    }
}
