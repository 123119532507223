import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';

@Component({
    selector: 'app-chat-attachment-picker',
    templateUrl: './chat-attachment-picker.component.html',
    styleUrls: ['./chat-attachment-picker.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChatAttachmentPickerComponent implements OnInit {
    
    @ViewChild('attachmentPicker', {static: true})
    public input: ElementRef<HTMLInputElement>;
    @Output()
    public fileSelected = new EventEmitter<File>();
    
    constructor() {
    }
    
    public ngOnInit() {
    }
    
    public onFileSelect($event: Event) {
        const target: HTMLInputElement = $event.target as HTMLInputElement;
        if (target.files.length === 0) {
            return;
        }
        this.fileSelected.emit(target.files[0]);
    }
    
    public openFileSelector() {
        this.input.nativeElement.click();
    }
}
