import { Component, EventEmitter, Output } from '@angular/core';
import { AbstractModal } from '../../../shared/abstract-modal';
import { FormGroup } from '@angular/forms';
import { News } from '../../../domain/news';
import * as _ from 'lodash';
import * as $ from 'jquery';
import { NewsService } from '../../../service/news.service';
import { Utils } from '../../../shared/utils';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { environment } from '../../../../environments/environment';

@Component({
    selector: 'app-news-form',
    templateUrl: './news-form.component.html',
    styleUrls: ['./news-form.component.scss']
})
export class NewsFormComponent {

    public Editor = ClassicEditor;

    form: FormGroup = News.form();
    config: any = {
        toolbarGroups: [
            { name: 'basicstyles' },
            { name: 'links' }
        ]
    };
    @Output()
    public newsSaved: EventEmitter<any> = new EventEmitter();

    constructor(private newsService: NewsService) {
        this.form.controls.id.valueChanges.subscribe(v => {
            if (!_.isUndefined(v)) {
                this.form.controls.file.validator = null;
                this.form.controls.file.setErrors(null);
            }
        });
    }

    ngOnInit() {
    }

    onFileSelected(event) {
        const files: FileList = _.get(event, 'target.files');
        if (!_.isUndefined(files) && files.length > 0
            && Utils.isImage(files[0])) {
            this.form.patchValue({ file: files[0] });
            this.displayPreview();
        } else {
            this.form.patchValue({ file: undefined });
        }
    }

    onRteChange(content) {
        //this.form.patchValue({ content: content });
    }

    save() {
        this.newsService.saveNews(this.form.value)
            .subscribe(news => {
                this.form.reset();
                this.newsSaved.emit(news);
            });
    }

    private displayPreview() {
        const reader = new FileReader();

        reader.onload = (e) => {
            $('.upload-preview').attr('src', (<any>e).target.result);
        };

        reader.readAsDataURL(this.form.controls.file.value);
    }

    get envApi() {
        return environment.api;
    }
}
