import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import * as _ from 'lodash';

@Component({
    selector: 'app-topic-list',
    templateUrl: './topic-list.component.html',
    styleUrls: ['./topic-list.component.scss']
})
export class TopicListComponent implements OnInit {

    @Input('topics')
    topics;

    @Input('group')
    group;

    @Output()
    onTopicCommentWithFile = new EventEmitter<void>();

    constructor() {
    }

    topicCommentWithFile() {
        this.onTopicCommentWithFile.emit();
    }

    ngOnInit() {
    }

    sorted(topics){
        return _.reverse(_.sortBy(_.get(topics,'entities') || [], ['updated']));
    }

    onTopicDeleted(topic){
        const topics = _.remove(this.topics.entities, (t: any) => t.id == topic.id);
        this.topics.count -= topics.length;
    }

}
