import { Component, OnInit, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-switch-button',
  templateUrl: './switch-button.component.html',
  styleUrls: ['./switch-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SwitchButtonComponent implements OnInit {

  @Input()
  size = "small"

  @Input()
  active: boolean;

  @Output()
  toggle = new EventEmitter<void>();

  @Input()
  color: string = "#889C03";

  constructor() { }

  ngOnInit() {
  }

  onToggle() {
    this.toggle.emit();
  }

}
